<template>
  <div ref="wrapper" class="c-tablet-viewer c-frame-wrapper d-flex align-center justify-center">
    <iframe
      ref="iframe"
      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
      :src="src"
      :title="title"
      frameborder="0"
      scrolling="auto"
      :width="`${contentWidth}px`"
      :height="`${contentHeight}px`"
    />
  </div>
</template>

<script>
import playerMixin from '@/mixins/playerMixin'

export default {
  name: 'TabletViewer',

  components: {},

  mixins: [playerMixin],

  props: {
    content: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {
      contentWidth: 1,
      contentHeight: 1,
      src: this.content.contentURL,
      title: this.content.title || 'Untitled'
    }
  },

  computed: {
    orientation() {
      return this.content.params?.find((param) => param.name === 'orientation')?.value
    }
  },

  mounted: function () {
    window.addEventListener('resize', this.onResize)

    if (this.orientation === 'portrait') {
      this.contentWidth = 768
      this.contentHeight = 1024
    } else {
      this.contentWidth = 1024
      this.contentHeight = 768
    }

    this.onResize()
  },

  beforeDestroy: function () {
    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    // for orientation, when it's really the viewport dimentions that matter,
    // focus on innerWidth/innerHeight and resize event
    // (works across desktops, iOS and Android)
    //
    // otherwise, use the resize event on Android and the orientationchange event on iOS
    // (and correspondingly, innerWidth/innerHeight or window.orientation)
    //
    // https://stackoverflow.com/questions/1649086/detect-rotation-of-android-phone-in-the-browser-with-javascript
    //
    getOrientation() {
      const screenOrientation = screen.orientation ? screen.orientation.angle : window.orientation
      return screenOrientation === 90 || screenOrientation === -90 ? 'landscape' : 'portrait'
    },

    onResize() {
      const clientHeight = this.$refs.wrapper.clientHeight
      const clientWidth = this.$refs.wrapper.clientWidth
      const scale = Math.min(clientHeight / this.contentHeight, clientWidth / this.contentWidth)

      this.$refs.wrapper.style.setProperty('--c-scale', scale || 1)
    }
  }
}
</script>

<style lang="css" scoped>
.c-frame-wrapper {
  --c-scale: 1;
  position: relative;
  overflow-y: hidden;
  margin: auto;
}

iframe {
  position: absolute;
  top: 0;
  display: block;
  border: none;
  background-color: #0c0f0e;
  -moz-transform: scale(var(--c-scale));
  -ms-transform: scale(var(--c-scale));
  -webkit-transform: scale(var(--c-scale));
  transform: scale(var(--c-scale), var(--c-scale));
  -moz-transform-origin: top center;
  -ms-transform-origin: top center;
  -webkit-transform-origin: top center;
  transform-origin: top center;
}

/* prevent Chrome from scaling twice */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  iframe {
    zoom: 1;
  }
}
</style>
