function isLocalStorage() {
  try {
    const testKey = 'Lumenii:test'
    window.localStorage.setItem(testKey, 'success')
    window.localStorage.getItem(testKey)
    window.localStorage.removeItem(testKey)
    return true
  } catch (e) {
    console.warn('[store]: Local storage not available.  Using memory instead.')
    return false
  }
}

class Memory {
  constructor() {
    this.cache = {}
  }

  getItem(cacheKey) {
    return this.cache[cacheKey]
  }

  setItem(cacheKey, data) {
    this.cache[cacheKey] = data
  }

  removeItem(cacheKey) {
    this.cache[cacheKey] = undefined
  }
}
// if not support localStorage, fallback to memory
export const storage = isLocalStorage() ? window.localStorage : new Memory()

export function getItem(key, defaultValue) {
  try {
    return storage.getItem(key)
  } catch (e) {
    console.error(
      `[store]: Unable to retrieve storage item with key=${key}.  ${e.message}.  Using default value.`
    )
    return defaultValue
  }
}

export function setItem(key, value) {
  try {
    storage.setItem(key, value)
    return true
  } catch (e) {
    console.error(`[store]: Unable to set storage item with key=${key}.  ${e.message}.`)
    return false
  }
}

export function removeItem(key) {
  try {
    storage.removeItem(key)
    return true
  } catch (e) {
    console.error(`[store]: Unable to remove storage item with key=${key}.  ${e.message}.`)
    return false
  }
}
