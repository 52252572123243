import Vue from 'vue'
import Vuex from 'vuex'

// modules
import modules from './modules'

// storage
import { appName } from '@/config/appConfig'
import createStoragePlugin from '@/store/plugins/storagePlugin'
import * as Storage from '@/store/storage'
import pkg from '@@/package.json'

Vue.use(Vuex)

export const STORAGE_KEY = appName

const getAppOrigin = () => {
  /*
  const protocol = window.location.protocol
  const hostname = window.location.hostname
  const port = window.location.port
  const base = `${protocol}//${hostname}`
  return port ? `${base}:${port}` : `${base}`
  */
  return window.location.origin
}

export function useStore() {
  return store
}

const storagePlugin = createStoragePlugin()

const store = new Vuex.Store({
  modules,

  state: {
    version: pkg.version,
    online: window.navigator.onLine
  },

  getters: {},

  mutations: {
    setOnlineStatus(state, online) {
      state.online = online
    },

    setVersion(state, version) {
      state.version = version
    }
  },

  actions: {
    initStores({ dispatch, commit }, { vm }) {
      const versionKey = `${STORAGE_KEY}:version`
      const sessionVersion = Storage.getItem(versionKey) || pkg.version

      console.info('[store]: Initializing stores...')

      console.info('[store]: Version=', pkg.version)
      console.info('[store]: Origin=', getAppOrigin())

      // detect online status
      console.info('[store]: Initially ' + (window.navigator.onLine ? 'on' : 'off') + 'line.')

      // restore the session state; otherwise rely on other store modules to set the state
      Object.keys(modules).forEach((module) => {
        // console.debug('[store]: Initializing', module)
        const moduleKey = `${STORAGE_KEY}:${module}`
        let moduleState
        try {
          moduleState = JSON.parse(Storage.getItem(moduleKey))
        } catch (error) {
          console.error(`[store]: Unable to parse storage of module "${module}".`)
          moduleState = {}
        }
        // console.debug(`[store]: Session state for ${module}=`, moduleState)

        if (sessionVersion === pkg.version) {
          dispatch(`${module}/initStore`, {
            sessionState: { ...moduleState },
            vm
          })
        } else {
          dispatch(`${module}/migrateStore`, {
            sessionState: { ...moduleState },
            vm
          })
        }
      })

      commit('setVersion', pkg.version)

      console.info('[store]: Initialization complete!')
    },

    reflectOrg({ state, dispatch }, { orgConfig, vm }) {
      const activePortalKey = state.tenantStore.portalKey

      const orgInfo = {
        orgLink: orgConfig?.orgInfo?.orgLink || '',
        orgLogo: orgConfig?.orgInfo?.orgLogo || '/img/logos/default-logo.png',
        orgName: orgConfig?.orgInfo?.orgName || '<Missing>',
        about: orgConfig?.orgInfo?.about || '<Missing>',
        contact: orgConfig?.orgInfo?.contact || '<Missing>',
        disclaimer: orgConfig?.orgInfo?.disclaimer || '<Missing>',
        privacy: orgConfig?.orgInfo?.privacy || '<Missing>',
        tou: orgConfig?.orgInfo?.tou || '<Missing>'
      }

      const indexMenuItem = orgConfig.menuItems.find((menuItem) => menuItem.index)
      const indexRoute = indexMenuItem ? { name: indexMenuItem } : { name: 'home' }

      // set locale (do this first!)
      const locale = orgConfig.locales.includes(state.i18nStore.locale)
        ? state.i18nStore.locale
        : orgConfig.locales[0]
      dispatch('i18nStore/updateLocale', { locale, vm })

      // set portal state
      dispatch('portalStore/updatePortals', {
        portals: orgConfig.portals || [],
        activePortalKey: activePortalKey
      })

      // set content state
      dispatch('contentStore/updateCollections', { collections: orgConfig.collections })
      dispatch('contentStore/updateTaxonomyTitles', { taxonomyTitles: orgConfig.taxonomyTitles })

      // set menu (note: menu depends on collections! )
      dispatch('menuStore/updateMenu', { menuItems: orgConfig.menuItems })
      dispatch('menuStore/updateIndexRoute', { route: indexRoute })

      // set visual elements
      dispatch('orgStore/updateOrgInfo', { orgInfo })
      dispatch('themeStore/updateOrgTheme', { orgTheme: orgConfig.theme, vm })
    },

    reflectUser({ dispatch }, { user }) {
      dispatch('userStore/updateUser', { user })
      // dispatch('i18nStore/updateLocale', { locale: this.user.locale, vm })
    }
  },

  plugins: [storagePlugin]
})

Vue.prototype.$store = store

export default store
