<template>
  <transition name="fade">
    <v-img
      v-if="src"
      :key="src"
      class="c-image"
      :class="{ 'c-cta': hasCTA }"
      :src="src"
      contain
      @click="$emit('select', ad)"
    >
      <v-btn
        v-if="close"
        class="c-btn-close"
        icon
        small
        @click.stop="$emit('close')"
      >
        <v-icon x-small>
          mdi-close
        </v-icon>
      </v-btn>
    </v-img>
  </transition>
</template>

<script>
export default {
  name: 'AdImage',

  components: {},

  props: {
    ad: {
      type: Object,
      required: true
    },

    close: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data: function () {
    return {}
  },

  computed: {
    hasCTA() {
      return this.ad.adItem.cta?.href
    },

    src() {
      return this.ad.adItem.asset || this.ad.adItem.image
    }
  },

  methods: {}
}
</script>

<style lang="css" scoped>
.c-btn-close {
  position: absolute;
  top: 0px;
  right: 0px;
}

.c-cta {
  cursor: pointer;
}

.c-image {
  background-color: var(--v-background-base);
  width: 100%;
}
</style>
