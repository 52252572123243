<template>
  <div
    class="c-progress d-flex flex-column align-center justify-center"
    :style="[ backgroundStyle ]"
  >
    <v-progress-circular
      :color="colours[0]"
      indeterminate
      :rotate="0"
      :size="300"
      :width="7"
    >
      <v-progress-circular
        :color="colours[1]"
        indeterminate
        :rotate="90"
        :size="250"
        :width="7"
      >
        <v-progress-circular
          :color="colours[2]"
          indeterminate
          :rotate="180"
          :size="200"
          :width="7"
        >
          <v-img
            class="c-logo"
            :src="orgLogo"
            contain
            height="120"
            width="120"
          />
        </v-progress-circular>
      </v-progress-circular>
    </v-progress-circular>
    <div v-if="message" class="text-h3 mt-4">
      {{ message }}
    </div>
    <slot name="append" class="mt-4" />
  </div>
</template>

<script>
export default {
  name: 'CircularProgress',

  components: {},

  props: {
    background: {
      type: String,
      required: false,
      default: ''
    },

    colours: {
      type: Array,
      required: false,
      // default: () => ['primary', 'secondary', 'accent']
      default: () => ['purple', 'orange', 'yellow accent-2']
    },

    logo: {
      type: String,
      required: false,
      default: ''
    },

    message: {
      type: String,
      required: false,
      default: ''
    }
  },

  data: function () {
    return {}
  },

  computed: {
    backgroundStyle() {
      return this.background
        ? { backgroundColor: this.background }
        : { backgroundColor: 'var(--v-background-base)' }
    },

    orgLogo() {
      return this.logo || this.$store.state.orgStore.orgLogo
    }
  }
}
</script>

<style lang="css" scoped>
.c-progress {
  height: 100%;
  width: 100%;
}

.c-logo {
  border-radius: 50%;
}
</style>

