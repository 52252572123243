<template>
  <div class="c-stack d-flex flex-column justify-start xalign-center">
    <AdHeader v-if="header" @close="$emit('close')" />
    <div
      v-for="ad in ads"
      :key="ad.id"
      class="c-ad"
      :class="{ 'c-cta': ad.adItem.cta }"
      @click="$emit('select', ad)"
    >
      <AdImage
        :ad="ad"
        :close="false"
      />
      <AdCopy
        v-if="ad.adItem.copy"
        :copy="ad.adItem.copy"
      />
    </div>
  </div>
</template>

<script>
import AdCopy from '@/components/ad/AdCopy'
import AdHeader from '@/components/ad/AdHeader'
import AdImage from '@/components/ad/AdImage'

export default {
  name: 'AdStack',

  components: {
    AdCopy,
    AdHeader,
    AdImage
  },

  props: {
    ads: {
      type: Array,
      required: false,
      default: () => []
    },

    header: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data: function () {
    return {}
  },

  computed: {
    isImage(ad) {
      return !!(ad.adItem.image || ad.adItem)
    }
  },

  methods: {}
}
</script>

<style lang="css" scoped>
.c-ad {
  border: solid 1px;
  margin: 16px;
  margin-top: 0px;
}

.c-cta {
  cursor: pointer;
}
</style>
