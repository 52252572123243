<template>
  <v-card
    class="c-card d-flex flex-column"
    text
  >
    <div class="c-flex-img">
      <v-img
        class="white--text align-end c-clickable c-card-image"
        :aspect-ratio="16 / 9"
        cover
        gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
        :src="thumbnail"
        @click="$emit('view:post')"
      >
        <v-card-title style="text-shadow: 0px 2px 5px #222">
          {{ post.title }}
        </v-card-title>
      </v-img>
    </div>
    <v-card-title class="py-2">
      <div>
        <p v-if="displayName" class="mb-0">
          {{ displayName }}
        </p>
        <p class="grey--text mb-0">
          {{ post.publishedAt | date }}
        </p>
      </div>
    </v-card-title>
    <v-card-text>
      <ClampedText :text="post.excerpt" />
    </v-card-text>
    <v-spacer />
    <v-card-actions class="c-actions px-4">
      <v-btn color="primary" @click="$emit('view:post')">
        {{ $t(`post.action.${cardAction}`) }}
      </v-btn>
      <v-spacer />
      <v-btn color="red" icon large @click.stop="toggleFavourite()">
        <v-icon large>
          {{ isFavourite ? 'mdi-heart' : 'mdi-heart-outline' }}
        </v-icon>
      </v-btn>
      <v-btn color="accent" icon large @click.stop="revealBack = true">
        <v-icon large>
          mdi-information
        </v-icon>
      </v-btn>
    </v-card-actions>
    <v-scroll-x-reverse-transition>
      <PostCardBack v-model="revealBack" :author="post.author" />
    </v-scroll-x-reverse-transition>
  </v-card>
</template>

<script>
import ClampedText from '@/components/base/ClampedText'
import PostCardBack from '@/components/post/PostCardBack'
import { getImage } from '@/config/sanityConfig'
import { mapActions } from 'vuex'

export default {
  name: 'PostCard',

  components: {
    ClampedText,
    PostCardBack
  },

  props: {
    post: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {
      isFavourite: false,
      maxLines: 3,
      revealBack: false,
      action: 'read'
    }
  },

  computed: {
    cardAction() {
      const actions = {
        podcast: 'listen',
        vcast: 'watch',
        blog: 'read'
      }
      return actions[this.post.genre] || 'explore'
    },

    isDark() {
      return this.$store.state.themeStore.isDark
    },

    favourite() {
      return this.$store.state.userStore.posts.indexOf(this.post.id) > -1
    },

    displayName() {
      return this.post.author?.displayName
    },

    thumbnail() {
      const image = this.post.cardImage?.obj || this.post.mainImage?.obj
      // FIXME: why is image sometimes undefined?
      return image ? getImage(image).size(400, 225).url() : ''
    }
  },

  created: function () {
    this.isFavourite = this.favourite
  },

  methods: {
    ...mapActions('userStore', ['addToPosts', 'removeFromPosts']),

    toggleFavourite() {
      this.isFavourite = !this.isFavourite
      this.isFavourite ? this.addToFavourites() : this.removeFromFavourites()
    },

    addToFavourites() {
      this.addToPosts({ id: this.post.id })
      this.$emit('add:post')
    },

    removeFromFavourites() {
      this.removeFromPosts({ id: this.post.id })
      this.$emit('remove:post')
    }
  }
}
</script>

<style lang="css" scoped>
.c-card {
  margin: auto;
  height: 100%;
  max-width: min(500px, 100%);
  min-width: 280px;
  border-radius: 16px;
}

.c-card-image {
  border-radius: inherit;
}

.c-clickable {
  cursor: pointer;
}

.v-card.c-post > .v-card__text {
  font-size: 1.25rem;
  line-height: 2rem;
}
.theme--dark.v-card.c-post > .v-card__text {
  color: white;
}
.theme--light.v-card.c-post > .v-card__text {
  color: black;
}
</style>
