import { InteractionRequiredAuthError } from '@azure/msal-browser'
import { msalAgent, authorities } from '@/config/msalConfig'

const graphConfig = {
  scopes: [`${authorities.b2cSignUpAndSignIn}/User.Read`],
  // scopes: ['https://graph.microsoft.com/User.Read'],
  profileEndpoint: 'https://graph.microsoft.com/v1.0/me',
  photoEndpoint: 'https://graph.microsoft.com/v1.0/me/photo/$value'
}

export default class ProfileService {
  constructor() {
    // initialize MSAL
    this.agent = msalAgent

    // setup class state
    this.profile = null
    this.photo = null
    this.accessToken = null
  }

  async acquireToken() {
    if (this.accessToken) {
      return this.accessToken
    } else {
      try {
        const response = await this.acquireAccessToken()
        this.accessToken = response.accessToken
        return this.accessToken
      } catch (error) {
        console.error('[profileService]: Error acquiring access token.', error)
        throw error
      }
    }
  }

  async fetchUserProfile() {
    try {
      const accessToken = this.acquireToken()
      const response = await this.callMSGraph({
        endpoint: this.graphConfig.profileEndpoint,
        token: accessToken
      })
      const profile = response.json()
      return this.cacheUserProfile(profile)
    } catch (error) {
      console.error('[profileService]: Error retrieving user profile.', error)
      throw error
    }
  }

  async fetchUserPhoto() {
    try {
      const accessToken = this.acquireToken()
      const response = await this.callMSGraph({
        endpoint: this.graphConfig.photoEndpoint,
        token: accessToken
      })
      const photo = await response.blob()
      return photo
    } catch (error) {
      console.error('[profileService]: Error retrieving user photo.', error)
      throw error
    }
  }

  getUserProfile() {
    return this.profile
  }

  cacheUserProfile(graphProfile) {
    this.profile = {
      // picture
      // personal info
      displayName: graphProfile.displayName,
      givenName: graphProfile.givenName,
      familyName: graphProfile.surname,
      preferredLanguage: graphProfile.preferredLanguage,
      // contact info
      email: graphProfile.email,
      businessPhone: graphProfile.businessPhones[0],
      mobilePhone: graphProfile.mobilePhone,
      // address
      streetAddress: graphProfile.streetAddress,
      city: graphProfile.city,
      provinceOrState: graphProfile.state,
      country: graphProfile.country,
      // business info
      companyName: graphProfile.companyName,
      employeeId: graphProfile.employeeId,
      jobTitle: graphProfile.jobTitle,
      // metadata
      userType: graphProfile.userType
    }

    return this.profile
  }

  async acquireAccessToken(account) {
    try {
      // attempt to acquire token silently
      const profileSilentRequest = {
        scopes: graphConfig.scopes,
        account,
        forceRefresh: false
      }
      const response = await this.agent.acquireTokenSilent(profileSilentRequest)
      if (response) {
        return response.accessToken
      }
    } catch (error) {
      // ask the user via a popup
      if (error instanceof InteractionRequiredAuthError) {
        const profilePopupRequest = {
          scopes: graphConfig.scopes
        }
        try {
          const response = await this.agent.acquireTokenPopup(profilePopupRequest)
          return response.accessToken
        } catch {
          console.error('[profileService]: Unable to acquire access token silently.', error)
          throw error
        }
      } else {
        console.error('[profileService]: Unable to acquire access token via popup.', error)
        throw error
      }
    }
  }

  async callMSGraph({ token, endpoint }) {
    const headers = new Headers({ Authorization: `Bearer ${token}` })
    const options = {
      method: 'GET',
      headers
    }
    try {
      const response = await fetch(endpoint, options)
      if (response.ok) {
        return response
      } else {
        throw new Error(response.statusText)
      }
    } catch (error) {
      console.error('[profileService]:', error)
      throw error
    }
  }
}
