<template>
  <v-menu v-model="isOpen" offset-y top transition="slide-y-reverse-transition">
    <template #activator="{ on, attrs }">
      <v-btn
        v-ripple="{ class: 'primary--small text' }"
        class="c-btn"
        active-class="c-btn--active"
        fab
        small
        text
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>
          {{ isOpen ? 'mdi-close' : 'mdi-animation-play' }}
        </v-icon>
      </v-btn>
    </template>
    <v-list rounded>
      <v-subheader>
        {{ $t('model.animations').toUpperCase() }}
      </v-subheader>
      <v-list-item-group
        v-model="selectedAnimationIds"
        active-class="c-list-item--active"
        multiple
        @change="syncAnimations"
      >
        <v-list-item
          v-for="animation in animations"
          :key="animation.id"
          :value="animation.id"
          @click.stop="selectAnimation(animation.id)"
        >
          <v-list-item-content>
            <v-list-item-title>{{ animation.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'AnimationSelector',

  components: {},

  props: {
    animations: {
      type: Array,
      required: true
    }
  },

  data: function () {
    return {
      isOpen: false,
      tabs: null,
      transition: 'slide-y-transition',
      direction: 'top',
      hover: false,
      selectedAnimationIds: []
    }
  },

  mounted: function () {
    // listen to the ContentDetails sidebar
    this.$bus.$on('sync:details', ({ selectedAnimations }) => {
      this.selectedAnimationIds = selectedAnimations.slice().reverse()
    })
  },

  beforeDestroy: function () {
    this.$bus.$off('sync:details')
  },

  methods: {
    selectAnimation(animationId) {
      // notify the parent component
      this.$emit('select:animation', animationId)
    },

    syncAnimations() {
      // notify the ContentDetails animation menu
      this.$bus.$emit('sync:selector', {
        selectedAnimations: this.selectedAnimationIds.slice().reverse()
      })
    }
  }
}
</script>

<style lang="css" scoped>
.v-list-item-group.theme--light {
  background-color: white;
}
.v-list-item-group.theme--dark {
  background-color: black;
}
.c-list-item--active {
  background-color: var(--v-accent-base);
}
</style>
