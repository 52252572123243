/*
 * Relies on consuming component to define:
 *   1. sortOption - key used to select the specific sort function
 *   2. resultSet - array of items to be sorted
 */

export default {
  data: function () {
    return {}
  },

  methods: {
    $_sortMixin_sortItems(sortName) {
      const sortFunction = this.$_sortMixin_getSortFunction(sortName)
      return sortName && sortName !== 'sortByAsIs'
        ? [...this.resultSet].sort(sortFunction)
        : [...this.resultSet]
    },

    $_sortMixin_getSortFunction(sortName) {
      const functions = {
        sortByAsIs: this.sortByAsIs,
        sortByCompanyNameAsc: this.sortByCompanyNameAsc,
        sortByCompanyNameDesc: this.sortByCompanyNameDesc,
        sortByLastNameAsc: this.sortByLastNameAsc,
        sortByLastNameDesc: this.sortByLastNameDesc,
        sortByPosterAsc: this.sortByPosterAsc,
        sortByPosterDesc: this.sortByPosterDesc,
        sortByPrecedenceAsc: this.sortByPrecedenceAsc,
        sortByPrecedenceDesc: this.sortByPrecedenceDesc,
        sortByPublishDateAsc: this.sortByPublishDateAsc, // old to new
        sortByPublishDateDesc: this.sortByPublishDateDesc, // new to old
        sortByRankAsc: this.sortByRankAsc,
        sortByRankDesc: this.sortByRankDesc,
        sortByTitleAsc: this.sortByTitleAsc,
        sortByTitleDesc: this.sortByTitleDesc
      }
      return functions[sortName] || undefined
    },

    sortByAsIs(_stringA, _stringB) {
      return 0
    },

    sortByStringAsc(stringA, stringB) {
      if (stringA < stringB) {
        return -1
      } else if (stringA > stringB) {
        return 1
      } else {
        return 0
      }
    },

    sortByStringDesc(stringA, stringB) {
      return this.sortByStringAsc(stringB, stringA)
    },

    sortByCompanyNameAsc(a, b) {
      const companyA = a.name.toUpperCase()
      const companyB = b.name.toUpperCase()
      return this.sortByStringAsc(companyA, companyB)
    },

    sortByCompanyNameDesc(a, b) {
      return this.sortByCompanyNameAsc(b, a)
    },

    sortByLastNameAsc(a, b) {
      const personA = a.lastName.toUpperCase()
      const personB = b.lastName.toUpperCase()
      return this.sortByStringAsc(personA, personB)
    },

    sortByLastNameDesc(a, b) {
      return this.sortByLastNameAsc(b, a)
    },

    sortByPosterAsc(a, b) {
      const posterA = a.postedBy.toUpperCase()
      const posterB = b.postedBy.toUpperCase()
      return this.sortByStringAsc(posterA, posterB)
    },

    sortByPosterDesc(a, b) {
      return this.sortByPosterAsc(b, a)
    },

    sortByPrecedenceAsc(a, b) {
      const precedenceA = parseInt(a.precedence)
      const precedenceB = parseInt(b.precedence)
      return precedenceA - precedenceB
    },

    sortByPrecedenceDesc(a, b) {
      return this.sortByPrecedenceAsc(b, a)
    },

    sortByPublishDateAsc(a, b) {
      const dateA = new Date(a.publishedAt)
      const dateB = new Date(b.publishedAt)
      return dateA - dateB
    },

    sortByPublishDateDesc(a, b) {
      return this.sortByPublishDateAsc(b, a)
    },

    sortByRankAsc(a, b) {
      const rankA = a.orderRank
      const rankB = b.orderRank
      return this.sortByStringAsc(rankA, rankB)
    },

    sortByRankDesc(a, b) {
      return this.sortByRankAsc(b, a)
    },

    sortByTitleAsc(a, b) {
      const titleA = a.title.toUpperCase()
      const titleB = b.title.toUpperCase()
      return this.sortByStringAsc(titleA, titleB)
    },

    sortByTitleDesc(a, b) {
      return this.sortByTitleAsc(b, a)
    }
  }
}
