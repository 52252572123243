<template>
  <div class="c-model-player c-model-wrapper">
    <model-viewer
      v-if="isReady && src"
      class="c-model"
      :src="src"
      :alt="title"
      :poster="poster"
      ar
      ar-scale="auto"
      ar-modes="quick-look webxr scene-viewer fallback"
      camera-controls
      camera-orbit="45deg 75deg 100%"
      interaction-prompt="none"
      quick-look-browsers="safari chrome"
    />
  </div>
</template>

<script>
export default {
  name: 'ModelPlayer',

  components: {},

  props: {
    content: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {
      isReady: false,
      poster: this.content.thumbnail,
      src: this.content.contentURL,
      title: this.content.title
    }
  },

  created: function () {
    console.debug('[ModelPlayer]: contentURL=', this.content.contentURL)
  },

  mounted: function () {
    if (this.isScriptLoaded('model-viewer')) {
      this.isReady = true
    } else {
      const src = 'https://unpkg.com/@google/model-viewer/dist/model-viewer.min.js'
      this.loadScript(src)
    }
  },

  methods: {
    loadScript(src) {
      const script = document.createElement('script')
      script.setAttribute('src', src)
      script.setAttribute('type', 'module')
      script.setAttribute('async', '')
      script.setAttribute('defer', '')
      document.body.appendChild(script)

      // wait for script to load
      script.onload = () => {
        this.isReady = true
      }

      script.onerror = () => {
        console.error('[ModelViewer]: Unable to load model-viewer script.')
      }
    },

    isScriptLoaded(element) {
      return customElements.get(element)
    }
  }
}
</script>

<style lang="css" scoped>
* {
  --poster-color: black;
}

.c-model-wrapper {
  background-color: red;
  width: 100%;
  height: calc(100vh - var(--c-player-bar-height, 56px));
}

.c-model {
  background-color: black;
  width: 100%;
  height: calc(100vh - var(--c-player-bar-height, 56px));
}
</style>
