<template>
  <v-menu v-model="isOpen" offset-y top transition="slide-y-reverse-transition">
    <template #activator="{ on, attrs }">
      <v-btn
        v-ripple="{ class: 'primary--small text' }"
        class="c-btn"
        active-class="c-btn--active"
        fab
        small
        text
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>
          {{ isOpen ? 'mdi-close' : 'mdi-sitemap' }}
        </v-icon>
      </v-btn>
    </template>
    <v-list rounded>
      <v-subheader>
        {{ $t('model.scenes').toUpperCase() }}
      </v-subheader>
      <v-list-item-group
        v-model="selectedSceneId"
        active-class="c-list-item--active"
        mandatory
        @change="syncScene"
      >
        <v-list-item
          v-for="scene in scenes"
          :key="scene.id"
          :value="scene.id"
          @click.stop="selectScene(scene.id)"
        >
          <v-list-item-content>
            <v-list-item-title>{{ scene[locale].name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'SceneSelector',

  components: {},

  props: {
    selected: {
      type: Object,
      required: true
    },

    scenes: {
      type: Array,
      required: true
    }
  },

  data: function () {
    return {
      isOpen: false,
      tabs: null,
      transition: 'slide-y-transition',
      direction: 'top',
      hover: false,
      selectedSceneId: this.selected.id
    }
  },

  computed: {
    locale() {
      return this.$store.state.i18nStore.locale
    }
  },

  mounted: function () {
    // listen to the ContentDetails sidebar
    this.$bus.$on('sync:details', ({ selectedScene }) => {
      this.selectedSceneId = selectedScene
    })
  },

  beforeDestroy: function () {
    this.$bus.$off('sync:details')
  },

  methods: {
    selectScene(sceneId) {
      // notify the parent component
      this.$emit('select:scene', sceneId)
    },

    syncScene() {
      // notify the ContentDetails scene menu
      this.$bus.$emit('sync:selector', {
        selectedScene: this.selectedSceneId,
        selectedAnimations: []
      })
    }
  }
}
</script>

<style lang="css" scoped>
.v-list-item-group.theme--light {
  background-color: white;
}
.v-list-item-group.theme--dark {
  background-color: black;
}
.c-list-item--active {
  background-color: var(--v-accent-base);
}
</style>
