import { BrowserAuthError, InteractionRequiredAuthError } from '@azure/msal-browser'
import { msalAgent, authStyle } from '@/config/msalConfig'
import crypto from 'crypto'
import uuid from 'uuid-random'
import store from '@/store'
import Vue from 'vue'

import { avatarBaseURL } from '@/config/appConfig'
import TenantService from '@/services/tenantService'
import UserService from '@/services/userService'

/* MSAL Documentation
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/active-directory-v2-protocols
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-oauth2-implicit-grant-flow
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/accounts.md
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/msal-js-avoid-page-reloads
 * https://azuread.github.io/microsoft-authentication-library-for-js/ref/classes/_azure_msal_browser.publicclientapplication.html
 */

// authentication styles
const AUTH_TYPE_POPUP = 'popup'
const AUTH_TYPE_REDIRECT = 'redirect'

// login requests
const loginRequest = {
  // loginHint: preferred_username or 'user@domain.com' -> pre-populates userid (for signIn or signUp)
  prompt: 'select_account',
  scopes: ['openid', 'email', 'offline_access', 'profile'],
  state: '' // returned in accountState
}

// logout requests
const logoutRequest = {
  mainWindowRedirectUri: '/logout'
}

/* exceptions */

// unable to authenticate the user
export class AuthenticationError extends Error {
  constructor(message) {
    super(message)
    this.name = 'AuthenticationError'
  }
}

// user is defintely not authorized or an error prevented verification
export class AuthorizationError extends Error {
  constructor(message) {
    super(message)
    this.name = 'AuthorizationError'
  }
}

// user is definitely not authorized and should be prompted to join a tenant
export class AuthorizationRequiredError extends Error {
  constructor(message) {
    super(message)
    this.name = 'AuthorizationRequiredError'
  }
}

export default class AuthService {
  constructor() {
    // initialize MSAL
    this.agent = msalAgent

    // initialize services
    this.userService = new UserService()
    this.tenantService = new TenantService()
    this.user = {}
    this.tenant = {}
    this.$t = (msg, ...args) => Vue.prototype.$_i18n.t(msg, ...args)

    // setup authentication state
    this.account = null
    this.authenticated = false
    this.authStyle = authStyle
    this.state = null

    // setup authorization state
    this.authorized = false
    this.authorizedDomains = []

    // tokens (all are JWT-based)
    this.accessToken = null
    this.userToken = null

    // asynchronous notification
    this.$bus = new Vue()
    this.ready = false
    this.ok = true
    this.error = ''
  }

  /*
   * Service Management Methods
   */

  async initialize() {
    console.debug('[authService]: Initialization begins...')
    try {
      this.ready = false
      this.ok = true

      // handle redirect promise from MSAL
      if (this.authStyle === AUTH_TYPE_REDIRECT) {
        await this.handleRedirectPromise()
      }

      // retrieve the tenant record
      await this.fetchTenant()
    } catch (error) {
      this.ok = false
      this.error = error
      console.error('[authService]: Unable to initialize.', error)
      throw error
    } finally {
      this.ready = true
      this.$bus.$emit('$auth:ready', this.ok)
      console.debug('[authService]: Initialization complete! ok=', this.ok)
    }
  }

  async fetchTenant() {
    const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

    this.tenantKey = store.state.tenantStore.tenantKey
    // this.tenantKey = 'localhost'

    // FIXME: handle offline scenario (where tenant can't be fetched)
    this.tenant = await this.tenantService.fetchTenant(this.tenantKey)

    if (!this.tenant) {
      // retry once (after waiting 5 seconds)
      // (since this is the *first* function call made,
      // retry to overcome a 500 error due to a cold start delay)
      console.warn(`[authService]: Retrying fetch for tenant '${this.tenantKey}'.`)
      await wait(5000)
      this.tenant = await this.tenantService.fetchTenant(this.tenantKey)

      if (!this.tenant) {
        throw new Error(this.$t('error.TenantError.message', { tenant: this.tenantKey }))
      }
    }
  }

  isReady() {
    return this.ready
  }

  isOK() {
    return this.ok
  }

  getError() {
    return this.error
  }

  whenReady(callback) {
    this.ready ? callback() : this.$bus.$on('$auth:ready', () => callback())
  }

  async waitUntilReady() {
    const timeout = 15000 // 15 seconds
    const eventName = '$auth:ready'

    return new Promise((resolve, reject) => {
      // start a timer (which is cancelled when $auth is ready)
      const timer = setTimeout(() => {
        // if the timeout occurs, then $auth took too long to initialize
        console.error('[authService]: Timed out waiting for $auth to be ready!')
        // remove the event bus listener (stop waiting for $auth to be ready)
        this.$bus.$off(eventName, onAuthReady)
        // reject the promise (because $auth took too long)
        reject(new Error(`[authService]: Timed out waiting for ${eventName}.`))
      }, timeout)

      // declare the event bus listener (that runs if the '$auth:ready' event is received)
      function onAuthReady(event) {
        // clear the timer
        clearTimeout(timer)
        // remove the event bus listener
        this.$bus.$off(eventName, onAuthReady)
        // resolve the promise (because $auth is ready!)
        resolve(event)
      }

      // wait for $auth to be ready (and clear the timer/bus when it is)
      this.$bus.$on(eventName, onAuthReady)
    })
  }

  /*
   * Response Handling Methods
   */

  async handleRedirectPromise() {
    try {
      const redirectResponse = await this.agent.handleRedirectPromise()
      if (redirectResponse) {
        this.parseResponse(redirectResponse)
      } else {
        // rather than forcing a login flow, check for existing sessions in the cache
        const account = this.findAccount()
        if (account) {
          // optionally, acquire access token for Graph API calls
          // const tokenResponse = await this.acquireTokens(account, loginRequest.scopes)
          // this.parseResponse(tokenResponse)
          this.clearCredentials()
          this.setAccount(account)
        } else {
          this.clearCredentials()
        }
      }
      return this.account
    } catch (error) {
      console.error('[authService]: handleRedirectPromise error.', error)
      this.clearCredentials()
      // throw new AuthenticationError(error)
    }
  }

  parseResponse(response) {
    // Response
    // https://azuread.github.io/microsoft-authentication-library-for-js/ref/modules/_azure_msal_common.html#authenticationresult
    console.debug('[authService]: redirectResponse=', response)
    this.setAccount(response?.account)
    this.accessToken = response?.accessToken
    this.userToken = response?.idToken
    this.state = response?.accountState
  }

  /*
   * Primary Methods
   */

  async signIn(params) {
    if (this.authStyle === AUTH_TYPE_POPUP) {
      const loginResponse = await this.loginPopup(params)
      if (loginResponse) {
        await this.authorize()
      } else {
        throw new AuthenticationError()
      }
    } else {
      if (this.isAuthenticated()) {
        await this.authorize()
      } else {
        await this.loginRedirect(params)
      }
    }
  }

  async authorize() {
    this.authorized = await this.checkAndGrantAuthorization()
    if (!this.authorized) {
      throw new AuthorizationRequiredError()
    }
  }

  async signOut() {
    const account = this.account
    this.clearCredentials()
    this.logout(account)
  }

  clearCredentials() {
    this.setAccount(null)
    this.accessToken = null
    this.userToken = null
    this.state = null
  }

  isAuthenticated() {
    return this.authenticated
  }

  isAuthorized() {
    return this.authorized
  }

  /*
   * Login/Logout Methods
   */

  isRedirectLogin() {
    return this.authStyle === AUTH_TYPE_REDIRECT
  }

  isPopupLogin() {
    return this.authStyle !== AUTH_TYPE_REDIRECT
  }

  async loginPopup(params) {
    try {
      const loginResponse = await this.agent.loginPopup({
        ...loginRequest,
        extraQueryParameters: params
      })
      this.parseResponse(loginResponse)
      return loginResponse.account
    } catch (error) {
      this.clearCredentials()
      console.error('[authService]: loginPopup error.', error)
      throw new AuthenticationError(error)
    }
  }

  async loginRedirect(params) {
    try {
      await this.agent.loginRedirect({
        ...loginRequest,
        extraQueryParameters: params
        // override only used if navigateToLoginRequestUrl is true...
        // redirectStartPage: '/'
        // callback passed in the target URL; returning false stops the navigation...
        // onRedirectNavigate: this.redirectNavigation(targetURL)
      })
    } catch (error) {
      console.error('[authService]: loginRedirect error.', error)
      throw new AuthenticationError(error)
    }
  }

  async loginSSO(params) {
    try {
      const ssoRequest = {
        // hint for bypassing login prompt screen...
        // domainHint: 'healthplexus.net'
      }
      const loginResponse = await this.agent.ssoSilent(ssoRequest)
      this.parseResponse(loginResponse)
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        return this.authStyle === AUTH_TYPE_REDIRECT
          ? this.loginRedirect(params)
          : this.loginPopup(params)
      } else {
        console.error('[authService]: SSO login error.', error)
        throw new AuthenticationError(error)
      }
    }
  }

  async logout(account) {
    try {
      return this.authStyle === AUTH_TYPE_POPUP
        ? this.agent.logoutPopup({ ...logoutRequest, account })
        : this.agent.logoutRedirect({ ...logoutRequest, account })
    } catch (error) {
      console.error('[authService]: Logout error.', error)
      throw error
    }
  }

  /*
   * Account Methods
   */

  setAccount(account) {
    this.account = account
    this.authenticated = !!this.account
    this.agent.setActiveAccount(this.account)
  }

  findAccount() {
    const currentAccounts = this.agent.getAllAccounts()
    if (!currentAccounts || currentAccounts.length < 1) {
      return null
    } else if (currentAccounts.length === 1) {
      console.info('[authService]: Active account detected!')
      console.info('[authService]: Selecting', currentAccounts[0].username)
      return currentAccounts[0]
    } else if (currentAccounts.length > 1) {
      // TODO: add account chooser code (only required if one allows multiple logins per session)
      console.info('[authService]: Multiple accounts detected!')
      console.info('[authService]: Selecting', currentAccounts[0].username)
      return currentAccounts[0]
    }
  }

  getAccount() {
    return this.account
  }

  getUserId() {
    // userId is case insensitive (in Lumenii and ActiveDirectory)
    return this.account ? this.account.username.toLowerCase() : 'anonymous'
  }

  getUserEmail() {
    // preserve case as entered by user
    return this.account ? this.account.username : ''
  }

  getUserDisplayName() {
    return this.account ? this.account.name : this.$t('account.guest')
  }

  getUserDomain() {
    return this.account ? this.account.username.split('@').pop().toLowerCase() : ''
  }

  getUserToken() {
    return this.userToken
  }

  getUserAvatar() {
    const email = this.getUserEmail()
    const hash = crypto.createHash('md5').update(email).digest('hex')
    return `${avatarBaseURL}/${hash}?d=robohash`
  }

  getUserUniqueId() {
    return this.account.localAccountId || uuid()
  }

  getUser() {
    return {
      id: this.getUserUniqueId(),
      userId: this.getUserId(),
      name: this.getUserDisplayName(),
      avatar: this.getUserAvatar(),
      email: this.getUserEmail()
    }
  }

  /*
   * Authorization Methods
   */

  getTenant() {
    return this.tenant
  }

  async checkAndGrantAuthorization() {
    const userId = this.getUserId()
    console.debug('[authService]: Check and grant userId=', userId)
    this.authorized = false
    try {
      // authorize user
      const tenantUser = await this.fetchUser(userId)
      if (tenantUser?.userId) {
        // user already authorized
        console.debug(`[authService]: UserId ${userId} is already authorized.`)
        this.user = tenantUser
        this.authorized = true
        return
      } else {
        // user requires authorization
        console.debug(`[authService]: UserId ${userId} requires authorization.`)
        if (this.isPreApproved()) {
          this.user = await this.addUser(userId)
          this.authorized = true
          console.debug(`[authService]: UserId ${userId} has been authorized.`)
          return
        }
      }
    } catch (error) {
      console.error('[authService]: Unable to authorize.', error)
      throw new AuthorizationError(error)
    }

    // throw a special exception when user requires authorization
    if (!this.authorized) {
      const error = this.$t('error.UserAuthorizationError.message', {
        user: userId,
        tenant: this.tenantKey
      })
      throw new AuthorizationRequiredError(error)
    }
  }

  async fetchUser(userId) {
    try {
      const user = await this.userService.fetchUser(userId)
      return user
    } catch (error) {
      console.error('[authService]: Unable to fetch user record.', error)
      throw error
    }
  }

  async addUser(userId) {
    try {
      const user = {
        userId: userId
      }
      const response = await this.userService.addUser(user)
      if (response.added) {
        return user
      } else {
        throw response.message
      }
    } catch (error) {
      console.error('[authService]: Unable to add user.', error)
      throw error
    }
  }

  isDomainAuthorized(domain) {
    return this.authorizedDomains.includes(domain) || this.authorizedDomains.includes('*')
  }

  isPreApproved() {
    // does userId have a valid invitation to join?

    // does userId domain match an authorized domain for this tenant?
    // FIXME: eliminate tenant.domain and just use authorizedDomains
    const authorizedDomains = [...(this.tenant?.authorizedDomains || []), this.tenant?.domain]
    const userDomain = this.getUserDomain()
    const isUserDomainAuthorized =
      authorizedDomains.includes(userDomain) || authorizedDomains.includes('*')

    console.debug('[authService]: user domain=', userDomain)
    console.debug('[authService]: authorized domains=', authorizedDomains)
    if (isUserDomainAuthorized)
      console.info(`[authService]: User ${this.getUserId()} is a member of an authorizedDomain.`)

    return isUserDomainAuthorized
  }

  async acquireTokens(account, scopes) {
    // TODO: intermittent error: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/1156
    const tokenRequest = {
      account,
      scopes,
      forceRefresh: false, // false -> get tokens from cache
      // FIXME: block_iframe_reload error...need a non-MSAL redirect
      redirectUri: `${window.location.origin}/blank.html`
    }
    try {
      const response = await this.agent.acquireTokenSilent(tokenRequest)
      if (response) {
        return response
      } else {
        const errorMessage = '[authService]: acquireTokenSilent returned null response.'
        console.error(errorMessage)
        throw errorMessage
      }
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        try {
          const response = await this.agent.acquireTokenPopup({
            ...loginRequest,
            ...scopes,
            loginHint: account.username
          })
          return response
        } catch (error) {
          console.error(
            `[authService]: Unable to acquire token (code: ${error.errorCode}). ${error}`
          )
          if (
            error instanceof BrowserAuthError ||
            error.errorCode === 'popup_window_error' ||
            error.errorCode === 'empty_window_error'
          ) {
            await this.agent.acquireTokenRedirect({
              ...loginRequest,
              ...scopes,
              loginHint: account.username
            })
          } else {
            console.error('[authService]: acquireTokenPopup error=', error)
            throw new AuthorizationError(error)
          }
        }
      } else {
        console.error('[authService]: acquireTokenSilent error=', error)
        throw new AuthorizationError(error)
      }
    }
  }
}
