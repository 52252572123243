import axios from 'axios'
import { fetchAds } from './ad'
import { fetchChannel, fetchChannels } from './channel'
import { fetchContent, fetchContentItem, getContentHeaders } from './content'
import { fetchOrgConfig, fetchOrgs } from './organization'
import { fetchPersons, fetchPerson, getPersonHeaders } from './person'
import { fetchPost, fetchPosts, postComment } from './post'
import { fetchTenants, fetchTenant } from './tenant'
import { fetchUser, fetchUserTenants, addUser, updateUser } from './user'

export const fetchCalendarEvents = (start, end) => {
  return axios
    .get(`https://myserver.azurewebsites.com/events?start=${start}&end=${end}`)
    .then((response) => response.data)
}

export const fetchCompanies = () => {
  return axios.get('https://myserver.azurewebsites.com/companies').then((response) => response.data)
}

export const fetchContacts = () => {
  return axios.get('https://myserver.azurewebsites.com/contacts').then((response) => response.data)
}

export const fetchNotifications = () => {
  return axios
    .get('https://myserver.azurewebsites.com/notifications')
    .then((response) => response.data)
}

export const fetchSystemMessage = () => {
  return axios
    .get('https://myserver.azurewebsites.com/systemMessage')
    .then((response) => response.data)
}

export default {
  // ads
  fetchAds,
  // calendar
  fetchCalendarEvents,
  // channels
  fetchChannel,
  fetchChannels,
  // clients
  fetchCompanies,
  fetchContacts,
  // content
  fetchContent,
  fetchContentItem,
  getContentHeaders,
  // notifications
  fetchNotifications,
  fetchSystemMessage,
  // orgs
  fetchOrgConfig,
  fetchOrgs,
  // persons
  fetchPersons,
  fetchPerson,
  getPersonHeaders,
  // posts
  fetchPost,
  fetchPosts,
  postComment,
  // tenants
  fetchTenants,
  fetchTenant,
  // users
  fetchUser,
  fetchUserTenants,
  addUser,
  updateUser
}
