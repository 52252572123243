<template>
  <div class="c-fullscreen">
    <CircularProgress :message="$t('message.redirecting')"/>
  </div>
</template>

<script>
import CircularProgress from '@/components/base/CircularProgress'

export default {
  name: 'RouterPage',

  components: {
    CircularProgress
  },

  props: {
    redirect: {
      type: String,
      required: false,
      default: ''
    }
  },

  data: function () {
    return {}
  },

  computed: {
    indexRoute() {
      return this.$store.state.menuStore.indexRoute
    },

    orgLogo() {
      return this.$store.state.orgStore.orgLogo
    }
  },

  created: function () {
    const routeName = this.redirect || this.indexRoute
    this.$auth.whenReady(() => this.routeTo(routeName))
  },

  methods: {
    routeTo(routeName) {
      this.$router
        .replace({
          name: routeName,
          replace: true
        })
        .catch((error) => {
          console.debug(`[Router]: Replace error going to ${routeName}. ${error}`)
        })
    }
  }
}
</script>

<style lang="css" scoped>
.c-fullscreen {
  height: 100vh;
}
</style>
