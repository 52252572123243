import { EventNames } from './ControlConstants'

/* map ModelControls event messages to Unity GameObject method names */
export const MethodNamesPre2020 = {
  // app controls
  [EventNames.EXIT]: ['Utilities', 'Exit'],
  [EventNames.LOAD_SCENE]: ['Utilities', 'LoadScene'],
  [EventNames.RESET]: ['Utilities', 'ResetScene'],

  // model controls
  [EventNames.MODE_MOVE]: ['Move', 'OnMouseDown'],
  [EventNames.MODE_ROTATE]: ['Rotate', 'OnMouseDown'],
  [EventNames.MODE_SCALE]: ['Scale', 'OnMouseDown'],
  [EventNames.ROTATE]: ['PlayPause', 'OnMouseDown'],
  [EventNames.SHOW_ANNOTATIONS]: ['Labels', 'OnMouseDown'],
  [EventNames.TOGGLE_ANIMATION]: ['Animations', 'Start'],
  [EventNames.ZOOM_IN]: ['ScaleObjectAction', 'ZoomIn'],
  [EventNames.ZOOM_OUT]: ['ScaleObjectAction', 'ZoomOut']
}

export const MethodNames2020 = {
  // app controls
  [EventNames.EXIT]: ['Utilities', 'Exit'],
  [EventNames.LOAD_SCENE]: ['Utilities', 'LoadScene'],
  [EventNames.RESET]: ['Utilities', 'ResetScene'],

  // model controls
  [EventNames.MODE_MOVE]: ['Utilities', 'Move'],
  [EventNames.MODE_SCALE]: ['Utilities', 'Scale'],
  [EventNames.MODE_ROTATE]: ['Utilities', 'Rotate'],
  [EventNames.ROTATE]: ['Utilities', 'Spin'],
  [EventNames.SHOW_ANNOTATIONS]: ['Utilities', 'Labels'],
  [EventNames.TOGGLE_ANIMATION]: ['Utilities', 'ToggleAnimation'],
  [EventNames.ZOOM_IN]: ['Utilities', 'ZoomIn'],
  [EventNames.ZOOM_OUT]: ['Utilities', 'ZoomOut']
}
