import adStore from '@/store/modules/adStore'
import calendarStore from '@/store/modules/calendarStore'
import channelStore from '@/store/modules/channelStore'
import contentStore from '@/store/modules/contentStore'
import i18nStore from '@/store/modules/i18nStore'
import menuStore from '@/store/modules/menuStore'
import orgStore from '@/store/modules/orgStore'
import portalStore from '@/store/modules/portalStore'
import postStore from '@/store/modules/postStore'
import notificationStore from '@/store/modules/notificationStore'
import tenantStore from '@/store/modules/tenantStore'
import themeStore from '@/store/modules/themeStore'
import userStore from '@/store/modules/userStore'

export default {
  adStore,
  calendarStore,
  channelStore,
  contentStore,
  i18nStore,
  menuStore,
  notificationStore,
  orgStore,
  portalStore,
  postStore,
  tenantStore,
  themeStore,
  userStore
}
