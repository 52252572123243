<template>
  <v-sheet class="c-container">
    <!-- left column -->
    <div
      class="c-side c-left-side"
      :class="{'c-showAds': showSideAds }"
    >
      <AdBlock
        v-model="showSideAds"
        class="c-ads"
        :ads="sideAds.slice().reverse()"
        stack
      />
    </div>

    <!-- body -->
    <div
      class="c-middle"
      :class="{'c-showAds': showSideAds }"
    >
      <div class="c-post">
        <!-- author (prolog) -->
        <PersonAvatar :person="post.author" />

        <!-- excerpt -->
        <div class="c-excerpt mt-4 mb-6">
          <i>{{ post.excerpt }}</i>
        </div>

        <!-- title -->
        <h1 class="c-title">{{ post.title }}</h1>

        <v-list three-line subheader>
          <!-- post stamp -->
          <v-list-item-content>
            <v-list-item-title v-if="post.author">
              {{ $t('post.postedOn') }} {{ post.publishedAt | date }} by
              {{ post.author.displayName }}
            </v-list-item-title>
          </v-list-item-content>

          <!-- hero (blogs only) -->
          <v-row
            v-if="isBlog"
            class="c-hero text-center ma-0 py-2"
            justify="center"
          >
            <v-img
              :aspect-ratio="16 / 9"
              cover
              max-height="450px"
              :src="hero"
            />
          </v-row>

          <!-- content -->
          <v-list-item-content class="c-content">
            <PortableText
              class-name="c-portable"
              :blocks="blockOptions.postBlocks"
              :serializers="blockOptions.serializers"
              :project-id="blockOptions.projectId"
              :dataset="blockOptions.dataset"
            />
          </v-list-item-content>

          <!-- author (epilog) -->
          <v-list-item-content class="c-author d-flex">
            <PersonAvatar :person="post.author" />
            <PortableText
              class-name="c-portable"
              :blocks="blockOptions.bioBlocks"
              :serializers="blockOptions.serializers"
              :project-id="blockOptions.projectId"
              :dataset="blockOptions.dataset"
            />
          </v-list-item-content>
        </v-list>

        <!-- footer -->
        <v-list v-if="post.tags" three-line subheader>
          <v-subheader>{{ $t('post.tags') }}</v-subheader>
          <Tags :tags="post.tags" />
        </v-list>

        <v-divider />

        <!-- comments -->
        <PostComments :post="post" :comments="post.comments" />

      </div>
    </div>

    <!-- right column -->
    <div
      class="c-side c-right-side"
      :class="{'c-showAds': showSideAds }"
    >
      <AdBlock
        v-model="showSideAds"
        class="c-ads"
        :ads="sideAds"
        stack
      />
    </div>
  </v-sheet>
</template>

<script>
import blocksToHTML from '@sanity/block-content-to-html'
import { getImage } from '@/config/sanityConfig'
import { mapGetters } from 'vuex'

import AdBlock from '@/components/ad/AdBlock'
import PersonAvatar from '@/components/base/PersonAvatar'
import PortableText from 'sanity-blocks-vue-component'
import PostComments from '@/components/post/PostComments'
import Tags from '@/components/base/Tags'

// portableText serializers
import AudioBlock from '@/components/post/AudioBlock'
import VideoBlock from '@/components/post/VideoBlock'
import YouTubeBlock from '@/components/post/YouTubeBlock'

export default {
  name: 'PostBody',

  components: {
    AdBlock,
    PersonAvatar,
    PostComments,
    PortableText,
    Tags
  },

  props: {
    post: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {
      showSideAds: false
    }
  },

  computed: {
    /* ads */
    ...mapGetters('adStore', ['getAds']),

    isAdFree() {
      // return this.post.adFree
      return false
    },

    sideAds() {
      // note: accesses adStore.ads to retain reactivity
      const ads = this.$store.state.adStore.ads
      return ads.length > 0 ? this.getAds('side') : []
    },

    hasSideAds() {
      return !this.isAdFree && this.sideAds.length > 0
    },

    /* blogs */

    isBlog() {
      return this.post.genre === 'blog'
    },

    /* hero */

    hero() {
      return getImage(this.post.mainImage.obj).url()
    },

    /* portable text processing */

    tenant() {
      return this.$auth.tenant
    },

    defaultLocale() {
      return this.$store.state.i18nStore.defaultLocale
    },

    locale() {
      return this.$store.state.i18nStore.locale
    },

    blockOptions() {
      return {
        bioBlocks:
          this.post.author?.bio?.[this.locale] || this.post.author?.bio?.[this.defaultLocale] || [],
        postBlocks: this.post.body || [],
        serializers: this.serializers,
        projectId: this.tenant.cms.projectId,
        dataset: this.tenant.cms.dataset,
        useCdn: true
      }
    },

    serializers() {
      return {
        types: {
          audio: AudioBlock,
          video: VideoBlock,
          youtube: YouTubeBlock
        }
      }
    },

    /* portable text blocks */

    bioHTML() {
      return blocksToHTML({
        blocks: this.blockOptions.bioBlocks,
        projectId: this.blockOptions.projectId,
        dataset: this.blockOptions.dataset,
        useCdn: this.blockOptions.useCdn
      })
    },

    postHTML() {
      return blocksToHTML({
        blocks: this.blockOptions.postBlocks,
        serializers: this.blockOptions.serializers,
        projectId: this.blockOptions.projectId,
        dataset: this.blockOptions.dataset,
        useCdn: this.blockOptions.useCdn
      })
    }
  },

  watch: {
    sideAds: {
      immediate: true,
      handler: function (newSideAds, _oldSideAds) {
        this.showSideAds = !this.isAdFree && newSideAds.length > 0
      }
    }
  }
}
</script>

<style lang="css" scoped>
/* layout */
.c-container {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
}

.c-middle,
.c-side {
  flex: 1 auto;
  min-height: calc(100vh - var(--c-player-bar-height, 56px));
  height: auto;
  overflow-y: auto;
}
.c-middle {
  width: 100%;
}
.c-side {
  display: none;
  background-color: var(--v-background-base);
}
@media only screen and (min-width: 900px) {
  .c-middle.c-showAds {
    width: 75%;
  }
  .c-right-side.c-showAds {
    display: block;
    width: 25%;
  }
}
@media only screen and (min-width: 1500px) {
  .c-middle.c-showAds {
    width: 60%;
  }
  .c-side.c-showAds {
    display: block;
    width: 20%;
  }
}

/* post formatting */
.c-post {
  padding: 24px min(10%, 64px);
  max-width: 1200px;
  margin: 0 auto;
}
.c-post :deep(*) {
  line-height: 1.75 !important;
}
.c-post .c-portable {
  width: 100%;
}
.c-excerpt {
  font-size: 1.25rem;
  line-height: 1.5;
}
.c-title {
  font-size: 2rem;
}

/* content */
.c-content {
  font-size: 1.5rem;
}
.c-content > p:first-of-type::first-letter,
.c-content .c-portable > p:first-of-type::first-letter {
  color: var(--v-primary-base);
  float: left;
  font-family: Georgia;
  font-size: 400%;
  line-height: 85%;
}
.c-content ol,
.c-content ul {
  padding-left: 36px;
}
.c-content img {
  width: 100%;
}

/* epilog */
.c-author .c-portable {
  font-size: 1.25rem;
}
</style>
