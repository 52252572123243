
<template>
  <v-speed-dial
    id="layoutDial"
    v-model="isOpen"
    class="c-layout-dial"
    direction="left"
    transition="slide-y-transition"
    @input="$emit('status', isOpen)"
    @click.native.stop
  >
    <template #activator>
      <v-btn
        v-model="isOpen"
        v-ripple="{ class: 'primary--text' }"
        class="c-btn"
        fab
        small
        text
      >
        <v-icon :class="{ 'accent--text' : isOpen }" large>
          {{ isOpen ? 'mdi-close' : currentIcon }}
        </v-icon>
      </v-btn>
    </template>
    <v-btn
      v-for="option in layoutOptions"
      :key="option.name"
      class="c-btn"
      fab
      small
      @click="selectLayout(option)"
    >
      <v-icon large>
        {{ option.icon }}
      </v-icon>
    </v-btn>
  </v-speed-dial>
</template>

<script>
export default {
  name: 'LayoutSelector',

  components: {},

  model: {
    prop: 'layout',
    event: 'update:layout'
  },

  props: {
    layout: {
      type: String,
      required: false,
      default: 'grid'
    },

    layouts: {
      type: Array,
      required: false,
      default: () => ['carousel', 'grid', 'table', 'tree']
    }
  },

  data: function () {
    return {
      currentLayout: '',
      currentIcon: '',
      isOpen: false,
      layoutList: [
        { name: 'carousel', icon: 'mdi-view-column' },
        { name: 'grid', icon: 'mdi-apps' },
        { name: 'table', icon: 'mdi-format-list-bulleted-square' },
        { name: 'tree', icon: 'mdi-file-tree' }
      ]
    }
  },

  computed: {
    layoutOptions() {
      return this.layoutList.filter((layout) => this.layouts.includes(layout.name))
    },

    currentOption() {
      return this.layoutOptions.find((option) => option.name === this.currentLayout)
    }
  },

  created: function () {
    this.currentLayout = this.layout
    this.currentIcon = this.currentOption.icon
  },

  methods: {
    selectLayout(option) {
      this.currentLayout = option.name
      this.currentIcon = option.icon
      this.$emit('update:layout', option.name)
    }
  }
}
</script>

<style lang="css">
#layoutDial.v-speed-dial--is-active .v-speed-dial__list {
  background-color: var(--v-secondary-base) !important;
}
</style>
<style lang="css" scoped></style>
