import { render, staticRenderFns } from "./ArticleViewer.vue?vue&type=template&id=59ac4c51&scoped=true&"
import script from "./ArticleViewer.vue?vue&type=script&lang=js&"
export * from "./ArticleViewer.vue?vue&type=script&lang=js&"
import style0 from "./ArticleViewer.vue?vue&type=style&index=0&id=59ac4c51&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59ac4c51",
  null
  
)

export default component.exports