<template>
  <div class="c-fullscreen">
    <div v-if="isPopupLogin" class="c-background" />
    <CircularProgress
      v-else
      :message="$t('message.loggingIn')"
    />
  </div>
</template>

<script>
import CircularProgress from '@/components/base/CircularProgress'
import { mapActions } from 'vuex'

export default {
  name: 'LoginPage',

  components: {
    CircularProgress
  },

  props: {
    force: {
      type: Boolean,
      required: false,
      default: false
    },

    redirect: {
      type: String,
      required: false,
      default: ''
    },

    params: {
      type: Object,
      required: false,
      default: () => {}
    }
  },

  data: function () {
    return {}
  },

  computed: {
    isPopupLogin() {
      return this.$auth.isPopupLogin()
    }
  },

  created: async function () {
    // handle redirect login case during create
    if (this.$auth.isRedirectLogin()) {
      this.processAuth()
    }
  },

  mounted: function () {
    // handle popup login case after mounting
    if (this.$auth.isPopupLogin()) {
      this.processAuth()
    }
  },

  methods: {
    ...mapActions('userStore', ['syncUser']),

    async processAuth() {
      try {
        // ensure $auth is initialized (see authPlugin)
        if (!this.$auth.isReady()) {
          await this.$auth.waitUntilReady()
        }

        // ensure $auth initialization succeeded
        if (!this.$auth.isOK()) {
          // note: authPlugin has already reported the error to the user
          const error = this.$auth.getError()
          console.error('[Login]: authService did not initialize.', error)
          return
        }

        // authenticate if necessary
        if (!this.$auth.isAuthenticated() || this.force) {
          await this.signIn(this.params)
        }

        // authorize if necessary
        if (!this.$auth.isAuthorized()) {
          await this.$auth.checkAndGrantAuthorization()
        }

        // update user data in session
        await this.syncUser({ userId: this.$auth.getUserId() })

        // redirect to the original route that required authentication / authorization
        this.redirect ? this.goto({ path: this.redirect }) : this.goto('/')
      } catch (error) {
        console.error('[Login]: Error during authentication processing.', error)
        this.reportError(error)
      }
    },

    async signIn() {
      try {
        await this.$auth.signIn(this.params)
        this.redirect ? this.goto({ path: this.redirect }) : this.goto('/')
      } catch (error) {
        console.error('[Login]: SignIn error.', error)
        throw error
      }
    },

    async signOut() {
      try {
        await this.$auth.signOut()
      } catch (error) {
        console.error('[Login]: SignOut error.', error)
      }
    },

    goto(target) {
      console.debug('[Login]: Replacing /login with', JSON.stringify(target))
      this.$router
        .replace(target || '/')
        .catch((error) => console.debug('[Login]: Push error during redirect.', error))
    },

    async exitOrLogout(errorCode) {
      const result = await this.$alert({
        icon: 'warning',
        title: this.$t(`error.${errorCode}.title`),
        text: this.$t(`error.${errorCode}.message`),
        confirmButtonText: this.$t(`ui.exit`),
        showDenyButton: true,
        denyButtonText: this.$t('ui.logout')
      })
      if (result.isConfirmed) {
        const exited = this.$exit()
        if (!exited) {
          console.debug('[Login]: Show browser close message...')
          this.$router.push({ path: '/splash#notice=exit' })
        }
      } else if (result.isDenied) {
        console.debug('[Login]: Signing out...')
        this.signOut()
        const exited = this.$exit()
        if (!exited) {
          console.debug('[Login]: Show browser close message...')
          this.$router.push({ path: '/splash#notice=exit' })
        }
      }
    },

    reportError(error) {
      switch (error.name) {
        case 'AuthenticationError':
          this.$error(error)
          break
        case 'AuthorizationError':
          this.exitOrLogout(error.name)
          break
        case 'AuthorizationRequiredError':
          this.exitOrLogout(error.name)
          // this.showJoinPage()
          break
        default:
          this.$error(error)
      }
    },

    gotoErrorPage(error) {
      this.$router
        .push({
          name: 'error',
          query: {
            code: error.name,
            message: error.message
          }
        })
        .catch((error) => {
          console.debug('[Login]: Push error routing to error page.', error)
        })
    },

    showJoinPage() {
      this.$router
        .push({
          name: 'join'
        })
        .catch((error) => {
          console.debug('[Login]: Push error routing to join page.', error)
        })
    }
  }
}
</script>

<style lang="css" scoped>
.c-background {
  background-image: url('~@/assets/images/backgrounds/valley-lake.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.c-fullscreen {
  height: 100vh;
}
</style>
