<template>
  <div class="c-image-viewer c-viewer d-flex">
    <slot>
      <v-img
        class="c-img--contained"
        :class="{'c-fullscreen': fullscreen }"
        :src="src"
        contain
      />
    </slot>
  </div>
</template>

<script>
export default {
  name: 'ImageViewer',

  components: {},

  props: {
    content: {
      type: Object,
      required: true
    },

    fullscreen: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: function () {
    return {
      src: this.content.contentURL
    }
  }
}
</script>

<style lang="css" scoped>
.c-viewer {
  width: 100%;
}
.c-img--contained {
  height: calc(100vh - var(--c-player-bar-height, 56px));
}

.c-img--contained.c-fullscreen {
  height: 100vh;
  width: auto;
}
</style>
