<template>
  <div class="c-player" :class="{'c-fullscreen': isFullscreen }">
    <PlayerBar
      v-model="showDetails"
      class="c-player-bar"
      :class="{'c-hide': isFullscreen }"
      :item="item"
      @activate:canvas="isCanvasActivated = $event"
      @close="onClose"
      @fullscreen="isFullscreen = $event"
    />

    <PlayerDrawer
      class="c-player-drawer"
      :context="context"
      :item="item"
      :open="showDetails"
    />

    <v-main
      class="c-player-main"
      :class="{'c-expand': isFullscreen }"
    >
      <v-container fluid class="c-container">
        <CircularProgress
          v-if="isLoading"
          :message="$t('message.fetchingContent')"
        />
        <ContentPlayer
          v-if="!isLoading && isFound"
          :canvas="isCanvasActivated"
          :item="item"
          :fullscreen="isFullscreen"
          @context="context = $event"
        />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import CircularProgress from '@/components/base/CircularProgress'
import ContentPlayer from '@/components/content/ContentPlayer'
import PlayerBar from '@/components/player/PlayerBar'
import PlayerDrawer from '@/components/player/PlayerDrawer'
import shareMixin from '@/mixins/shareMixin'

import ContentService from '@/services/contentService'
import { mapActions, mapGetters } from 'vuex'

class ParameterError extends Error {
  constructor(message) {
    super(message)
    this.name = 'ParameterError'
  }
}

export default {
  name: 'PlayerPage',

  components: {
    CircularProgress,
    ContentPlayer,
    PlayerBar,
    PlayerDrawer
  },

  mixins: [shareMixin],

  beforeRouteEnter: function (_to, from, next) {
    next((vm) => (vm.isFirstPage = from.name === 'login'))
  },

  beforeRouteUpdate: async function (to, from, next) {
    const id = this.$_shareMixin_extractId(to.params.id)
    this.resetData()
    this.fetchAll(id)
    next()
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  data: function () {
    return {
      // content
      item: null,
      isLoading: true,
      isFound: false,
      contentService: null,

      // showDetails: null // null means initially open on mobile, closed on desktop
      showDetails: false,
      isCanvasActivated: false,
      context: null,

      // scrolling
      isFullscreen: false,
      prevScrollPosition: 0,

      // navigation
      isFirstPage: false
    }
  },

  computed: {
    ...mapGetters('adStore', ['getAds']),

    isDrawerOpen() {
      return this.showDetails === null
        ? this.$vuetify.breakpoint.mobile
          ? false
          : this.item.details?.showDetails
        : !!this.showDetails
    },

    locale() {
      return this.$store.state.i18nStore.locale
    },

    portalKey() {
      return this.$store.state.tenantStore.portalKey
    }
  },

  created: async function () {
    this.contentService = new ContentService()
    const id = this.$_shareMixin_extractId(this.id)
    this.resetData()
    this.fetchAll(id)
  },

  mounted: function () {
    window.addEventListener('backbutton', this.onClose)
    window.addEventListener('beforeunload', this.onRefresh)
  },

  beforeDestroy: function () {
    window.removeEventListener('backbutton', this.onClose)
    window.removeEventListener('beforeunload', this.onRefresh)
  },

  methods: {
    ...mapActions('adStore', ['clearAds', 'fetchAds']),

    /* manage data */

    async fetchAll(id) {
      try {
        // fetch item
        await this.fetchItem(id)

        // fetch ads
        this.clearAds()
        const explicitKeywords = this.item.adWords || []
        const topicKeywords = this.item.topicKeywords || []
        const implicitKeywords = [this.portalKey, ...topicKeywords]
        const keywords = [...explicitKeywords, ...implicitKeywords]
        this.fetchAds({
          locale: this.locale,
          keywords
        })
      } catch (error) {
        if (!this.isFound) {
          await this.showAlert()
        } else {
          console.error('[Player]:', error)
          throw error
        }
      } finally {
        this.isLoading = false
      }
    },

    async fetchItem(id) {
      try {
        this.isFound = false
        if (id) {
          this.item = await this.contentService.fetchContentItem(id)
          this.isFound = true
        } else {
          throw new ParameterError()
        }
      } catch (error) {
        console.error('[Player]:', error)
        throw error
      }
    },

    resetData() {
      this.isCanvasActivated = false
      this.item = null
      this.isLoading = true
      this.isFound = false

      // this.showDetails = null // null means initially open on mobile, closed on desktop
      this.showDetails = false
      this.context = null

      // scrolling
      this.isFullscreen = false
      this.prevScrollPosition = 0
    },

    /* actions */

    closePlayer() {
      /*
       * There are three ways to exit players:
       * 1)The Player emits an "error" event.
       * 2)The user selects "close" from PlayerBar
       * 3)The user hits the "back" button.
       *
       * There are two mechanisms to exit:
       * 1)Return to previous "page".
       * 2)Exit the app.
       */
      const isInternalLink = this.$route.name === 'iplayer'
      isInternalLink
        ? this.isFirstPage
          ? this.$router.push({ name: 'home' })
          : this.$router.back()
        : this.$exit()
    },

    async showAlert() {
      const result = await this.$alert({
        icon: 'error',
        title: this.$t(`error.LoadingError.title`),
        text: this.$t(`error.LoadingError.message`),
        confirmButtonText: this.$t(`ui.close`)
      })
      if (result.isConfirmed) {
        return true
      } else {
        return false
      }
    },

    /* event handlers */

    onClose() {
      console.debug('[Player]: Closing the player.')
      this.closePlayer()
    },

    onError() {
      console.debug('[Player]: Closing the player due to an error.')
      this.closePlayer()
    },

    onRefresh(event) {
      event.preventDefault()
      console.debug('[Player]: refreshing the page.')
      // this.$router.go(0)
    }
  }
}
</script>

<style lang="css">
:root {
  --c-player-bar-height: 56px;
}

.c-player {
  height: 100%;
  min-height: 100vh;
  overflow-y: auto;
}
.c-player.c-fullscreen {
  --c-player-bar-height: 0px;
}

.c-player-main {
  background-color: var(--v-sheet-base);
  height: 100%;
  padding-bottom: 0 !important;
}
.c-player-main .c-container {
  height: 100%;
  padding: 0;
}

/* handle minmax */
.c-player-bar.c-hide {
  visibility: hidden;
}
.c-player-main.c-expand {
  padding-top: 0 !important;
  min-height: 100vh;
}
</style>
