<template>
  <v-navigation-drawer
    class="c-menu"
    app
    :clipped="clipped"
    :expand-on-hover="expandOnHover"
    :mini-variant="isMini"
    :mini-variant-width="miniWidth"
    mobile-breakpoint="0"
    :value="show"
    :width="fullWidth"
  >
    <!-- menu background -->
    <template #img>
      <v-img :src="backgroundImage" :gradient="backgroundGradient" height="100%" />
    </template>

    <!-- app and logo -->
    <template #prepend>
      <v-list nav subheader class="pb-0">
        <v-list-item class="c-list-item--prepend c-list-item--jitter-bug">
          <v-list-item-icon class="c-logo">
            <v-img :src="orgLogo" contain max-height="48" max-width="48" />
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="font-weight-bold text-h5">
              {{ appName }}
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-action class="my-1 mr-0">
            <v-btn fab small input-value="true" @click="toggleMiniVariant">
              <v-icon large>
                {{ isMini ? 'mdi-format-list-bulleted-square' : 'mdi-dots-vertical' }}
              </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-divider />
    </template>

    <!-- menu items -->
    <v-list nav rounded>
      <v-list-item
        v-for="item in menuItems"
        :key="item.key"
        active-class="c-list-item--active"
        class="c-list-item--jitter-bug"
        exact
        fixed
        :to="to(item)"
        router
      >
        <v-list-item-icon>
          <v-icon x-large>
            {{ item.icon }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            {{ getMenuItemName(item) }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider />

    <!-- control auto-hover setting (for mini menu) -->
    <template v-if="isMini" #append>
      <v-list>
        <v-list-item class="c-list-item--jitter-bug">
          <v-list-item-icon>
            <v-btn icon @click="toggleHover">
              <v-icon x-large>
                {{ expandOnHover ? 'mdi-chevron-triple-left' : 'mdi-chevron-triple-right' }}
              </v-icon>
            </v-btn>
          </v-list-item-icon>
          <v-list-item-content />
        </v-list-item>
        <v-list />
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AppSideBar',

  components: {},

  props: {
    show: {
      type: Boolean,
      required: true
    },

    backgroundImage: {
      type: String,
      required: false,
      default: require('@/assets/images/sidebars/valley-lake.jpg')
    }
  },

  data: function () {
    return {
      // initially, the sidebar is a mini variant that overlays on hover
      clipped: false, // the side drawer pushes over the top app-bar
      expandOnHover: true, // hover effect for mini variant is enabled
      isMini: true, // use a mini variant (until hover)
      miniWidth: 80, // to accommodate menu item icons = 2rem
      fullWidth: 256 // to accomodate logo plus app name as well as menu buttons (icons and labels)
    }
  },

  computed: {
    ...mapGetters('userStore', ['getSettings']),

    /* menu layout */

    actualWidth() {
      return this.show
        ? this.isScreenSmall
          ? 0
          : this.isMini
          ? this.miniWidth
          : this.fullWidth
        : 0
    },

    isDark() {
      return this.$store.state.themeStore.isDark
    },

    isScreenSmall() {
      return this.$_layoutMixin_isMobile
    },

    paddingLeft() {
      return {
        paddingLeft: this.actualWidth + 'px'
      }
    },

    /* menu content */

    appName() {
      return this.$appConfig.appName
    },

    backgroundGradient() {
      return this.isDark
        ? 'rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)'
        : 'rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)'
    },

    locale() {
      return this.$store.state.i18nStore.locale
    },

    menuItems() {
      return this.$store.state.menuStore.menuItems
    },

    orgLogo() {
      return this.$store.state.orgStore.orgLogo
    }
  },

  watch: {
    show: {
      immediate: false, // initial response handled via created hook
      handler: function (_newValue, _oldValue) {
        this.$appConfig.leftMargin = this.actualWidth
      }
    }
  },

  created: function () {
    const { sidebarMini, sidebarHover } = this.getSettings()
    this.isMini = sidebarMini
    this.expandOnHover = sidebarHover

    this.$appConfig.leftMargin = this.actualWidth
  },

  methods: {
    ...mapActions('userStore', ['updateSettings']),

    getMenuItemName(item) {
      return item.translated ? item.title[this.locale] || item.title.en : this.$t(`${item.title}`)
    },

    toggleMiniVariant() {
      this.isMini = !this.isMini
      this.expandOnHover = this.isMini
      this.updateSettings({
        sidebarMini: this.isMini,
        sidebarHover: this.expandOnHover
      })

      // recalculate width based on settings changes
      this.$appConfig.leftMargin = this.actualWidth
    },

    toggleHover() {
      if (this.isMini) {
        this.expandOnHover = !this.expandOnHover
        this.updateSettings({
          sidebarHover: this.expandOnHover
        })
      }
    },

    to(item) {
      return item.to ? item.to : { name: item.route }
    }
  }
}
</script>

<style lang="css" scoped>
.c-menu :deep(.v-navigation-drawer__content) {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}
.c-list-item--active {
  background-color: var(--v-primary-base);
}

.c-list-item--prepend {
  min-height: 64px;
}

/* fix for icon jittering bug */
.c-list-item--jitter-bug {
  justify-content: flex-start !important;
}

/* fix for icon centering bug */
.v-list-item__icon {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
  margin-left: 4px !important;
}

.v-list-item__icon.c-logo {
  margin-top: 8px !important;
  margin-bottom: 4px !important;
  margin-right: 28px !important;
  margin-left: 0px !important;
}

:deep(::-webkit-scrollbar) {
  width: 0;
  background: transparent;
}
</style>
