import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// parse the URL to get the tenantKey, portalKey and orgKey values
const parseURL = () => {
  const host = window.location.hostname.toLowerCase().replace(/www./g, '')

  // host = host.indexOf(':') > 0 ? host.split(':')[0] : host

  const hostSplit = host.split('.') // convert host into an array of strings
  const hostSplitReversed = hostSplit.slice().reverse()
  const hostParts = hostSplit.length

  const isHosted = (host) => {
    return (
      host.endsWith('lumensify.com') ||
      host.endsWith('medrealms.com') ||
      host.endsWith('netlify.app')
    )
  }

  // parse out the tenantKey
  // (tenant key is defined by the second last string in the host's domain)
  const tenantKey =
    hostParts < 2
      ? hostSplitReversed[0]
      : isHosted(host)
      ? hostSplitReversed[2]
      : hostSplitReversed[1]

  // parse out the orgKey
  // (URL is parsed as 'portal.org' (where portal is a single string with a default value of 'app')
  const portalKey =
    (hostParts <= 2 && !isHosted(host)) || (hostParts <= 3 && isHosted(host))
      ? 'app'
      : hostSplit.shift()

  // parse out the orgKey
  const orgKey = hostSplit.join('.')

  // alternate keys for localhost debugging
  const altTenantKey = process.env.VUE_APP_TENANT_KEY
  const altOrgKey = process.env.VUE_APP_ORG_KEY || 'localhost'
  const altPortalKey = process.env.VUE_APP_PORTAL_KEY || 'app'

  const result = {
    tenantKey: altTenantKey || tenantKey,
    orgKey: altTenantKey ? altOrgKey : orgKey,
    portalKey: altTenantKey ? altPortalKey : portalKey
  }

  return result
}

const displayState = ({ tenantKey, orgKey, portalKey }) => {
  const hostname = window.location.hostname
  if (hostname === 'localhost' && tenantKey !== 'localhost') {
    console.warn('[store]: Using "localhost" env overrides...')
  }

  console.debug('[store]: tenantKey=', tenantKey)
  console.debug('[store]: orgKey=', orgKey)
  console.debug('[store]: portalKey=', portalKey)
}

const initialState = {
  tenantKey: '',
  orgKey: '',
  portalKey: ''
}

export default {
  namespaced: true,

  state: () => {
    const { tenantKey, orgKey, portalKey } = parseURL()
    return { ...initialState, tenantKey, orgKey, portalKey }
  },

  getters: {},

  mutations: {
    /* common mutations */

    syncState(state, newState) {
      // spread operator breaks reactivity: state = {...state, ...newState}
      Object.keys(newState).forEach((key) => {
        // ignore any deprecated keys
        if (key in state) state[key] = newState[key]
      })
    },

    /* specific mutations */

    setTenantKey(state, tenantKey) {
      state.tenantKey = tenantKey.toLowerCase()
    },

    setPortalKey(state, portalKey) {
      state.portalKey = portalKey.toLowerCase() || 'app'
    },

    setOrgKey(state, orgKey) {
      state.orgKey = orgKey.toLowerCase()
    },

    setAuthorizedDomains(state, authorizedDomains) {
      const lowercaseDomains = authorizedDomains.map((domain) => domain.toLowerCase())
      state.authorizedDomains = lowercaseDomains || []
    }
  },

  actions: {
    /* common actions */

    initStore({ state, _commit }, { _sessionState }) {
      displayState(state)
    },

    migrateStore({ state, commit }, { sessionState }) {
      commit('syncState', { ...state, ...sessionState })
    },

    /* specific actions */

    configureTenant({ commit }, { tenantKey, orgKey, portalKey }) {
      // configure elements extracted from URL
      commit('tenantStore/setTenantKey', tenantKey)
      commit('tenantStore/setOrgKey', orgKey)
      commit('tenantStore/setPortalKey', portalKey)
    },

    updateTenant({ state, commit }, { tenant }) {
      // configure elements from tenant database
      if (state.tenantKey === tenant.tenantKey) {
        commit('setAuthorizedDomains', [...tenant.authorizedDomains, tenant.domain])
      }
    }
  }
}
