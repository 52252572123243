<template>
  <div>
    <v-btn fab small @click="showNotifications">
      <div v-if="badgeCount > 0">
        <v-badge color="secondary" :content="badgeCount">
          <v-icon color="accent" large>
            mdi-bell
          </v-icon>
        </v-badge>
      </div>
      <div v-else>
        <v-icon color="accent" large>
          mdi-bell
        </v-icon>
      </div>
    </v-btn>

    <!-- eslint-disable vue/no-v-html -->
    <notifications class="mt-16" group="topbar">
      <template #body="notice">
        <v-alert
          :type="notice.item.type"
          dismissible
          @click.native="removeNotification(notice)"
        >
          <div
            v-if="notice.item.title"
            class="text-xs-caption text-sm-subtitle-1 text-md-subtitle-1 text-lg-h6 text-xl-h6"
          >
            {{ notice.item.title }}
          </div>
          <div
            class="text-xs-body-2 text-sm-body-2 text-md-body-1 text-lg-body-1 text-xl-body-1"
          >
            {{ notice.item.text }}
          </div>
        </v-alert>
      </template>
    </notifications>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'AppNotifications',

  components: {},

  data: function () {
    return {
      notices: [],
      badgeCount: 0,
      isShowing: false
    }
  },

  created: async function () {
    this.notices = await this.fetchNotifications()
    this.badgeCount = this.notices.length
  },

  methods: {
    ...mapActions('notificationStore', ['fetchNotifications']),

    showNotifications() {
      if (this.isShowing) {
        if (this.badgeCount > 0) {
          this.$notify({
            group: 'topbar',
            clean: true
          })
        }
        this.badgeCount = 0
        this.isShowing = false
      } else {
        for (const notice of this.notices) {
          this.$notify({
            type: notice.type,
            group: 'topbar',
            title: notice.title,
            text: notice.text,
            closeOnClick: true,
            duration: -1,
            width: 300
          })
        }
        this.badgeCount = this.notices.length
        this.isShowing = true
      }
    },

    removeNotification(notice) {
      this.badgeCount = this.badgeCount - 1
      notice.close()
      this.$emit('decrement:notification', this.badgeCount)
    },

    clearNotifications(group) {
      this.$notify({ group, clean: true })
    }
  }
}
</script>

<style scoped lang="scss">
$badge-font-size: 18px;

@import '@/styles/variables.scss';

.c-notification {
  display: block;
  box-sizing: border-box;
  text-align: left;
  font-size: 16px;
  padding: 10px;
  margin: 0 5px 5px;
  color: white;
  background: var(--v-info-base);
  border-left: 5px solid #187fe7;
}

.c-notification.warn {
  background: var(--v-warning-base);
  border-left-color: #f48a06;
}

.c-notification.error {
  background: var(--v-error-base);
  border-left-color: #b82e24;
}

.c-notification.success {
  background: var(--v-success-base);
  border-left-color: #42a85f;
}
</style>
