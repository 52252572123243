<template>
  <v-speed-dial
    v-model="isOpen"
    :direction="direction"
    :open-on-hover="hover"
    :transition="transition"
  >
    <template #activator>
      <v-btn
        v-model="isOpen"
        v-ripple="{ class: current.colour ? `${current.colour}--text` : 'primary--text' }"
        active-class="c-btn--active"
        :class="[current.colour ? 'c-btn-simple' : 'c-btn', { 'c-btn--active': active }]"
        :color="current.colour === themeColour ? activatorBackgroundColour : ''"
        fab
        small
        :text="!current.colour"
      >
        <v-icon :class="{ 'accent-text': isOpen, [`${current.colour}--text`]: !isOpen && current.colour }" large>
          {{ isOpen ? 'mdi-close' : (current.icon || icon) }}
        </v-icon>
      </v-btn>
    </template>
    <v-btn
      v-for="option in options"
      :key="option.name"
      :class="[option.colour ? 'c-btn-simple' : 'c-btn']"
      :color="buttonBackgroundColour"
      fab
      small
      @click="setSelection(option)"
    >
      <v-icon :class="{[`${option.colour}--text`]: option.colour}" large>
        {{ option.icon || icon }}
      </v-icon>
    </v-btn>
  </v-speed-dial>
</template>

<script>
export default {
  name: 'DrawTool',

  components: {},

  model: {
    prop: 'current',
    event: 'selected'
  },

  props: {
    icon: {
      type: String,
      required: false,
      default: ''
    },

    current: {
      type: Object,
      required: true
    },

    active: {
      type: Boolean,
      required: false,
      default: false
    },

    options: {
      type: Array,
      required: true
    }
  },

  data: function () {
    return {
      selection: {},
      isOpen: false,
      tabs: null,
      transition: 'slide-y-transition',
      direction: 'top',
      hover: false
    }
  },

  computed: {
    isDark() {
      return this.$store.state.themeStore.isDark
    },

    activatorBackgroundColour() {
      return this.isDark ? 'rgba(200, 200, 200, 0.8)' : 'rgba(100, 100, 100, 0.4)'
    },

    buttonBackgroundColour() {
      return this.isDark ? 'rgba(100, 100, 100, 0.8)' : 'rgba(220, 220, 220, 0.8)'
    },

    themeColour() {
      return this.isDark ? 'black' : 'white'
    }
  },

  created: function () {
    this.selection = this.current
  },

  methods: {
    setSelection({ name, icon, colour, value }) {
      this.selection = {
        name,
        icon: icon || this.icon,
        colour: colour || '',
        value: value || name
      }
      this.$emit('selected', this.selection)
    }
  }
}
</script>

<style lang="css" scoped>
.c-btn-simple i:hover {
  transform: scale(1.25);
}
</style>
