// import client from '@client'
import client from '@/services/mock'

export default class ContactService {
  constructor() {
    this.type = ''
  }

  getContacts = () => {}

  fetchContacts = async () => {
    const contacts = await client.fetchContacts()
    return contacts
  }
}
