<template>
  <v-card
    class="c-card d-flex flex-column"
    justify-self="center"
  >
    <!-- header -->
    <v-list-item>
      <!-- media icon -->
      <v-list-item-avatar>
        <v-icon color="accent" x-large>
          {{ getMediaIcon(item.mediaType) }}
        </v-icon>
      </v-list-item-avatar>
      <!-- title -->
      <v-list-item-content>
        <v-card-title class="pa-0">
          {{ item.title }}
        </v-card-title>
      </v-list-item-content>
    </v-list-item>

    <!-- thumbnail image -->
    <div class="c-thumbnail-border">
      <v-img
        :aspect-ratio="thumbnailAspectRatio"
        :src="item.thumbnail"
        class="c-clickable ma-4"
        contain
        @click="$emit('view:item')"
      />
    </div>

    <!-- topic -->
    <v-card-text class="py-0 text-subtitle-1">
      <span>{{ topic }}&nbsp;</span>
    </v-card-text>

    <!-- size and duration -->
    <v-card-text class="d-flex py-0 text-subtitle-1">
      <div>
        {{ sizeInMB ? `${sizeInMB}MB` : '&nbsp;' }}
      </div>
      <v-spacer />
      <div>
        {{ durationInMinutes ? `${durationInMinutes} Min` : '&nbsp;' }}
      </div>
    </v-card-text>

    <!-- action menu -->
    <ContentCardActions
      :item="item"
      @view:item="$emit('view:item')"
      @view:info="revealBack = true"
      @remove:favourite="$emit('remove:favourite')"
      @share="$emit('share')"
    />

    <!-- flip card -->
    <v-scroll-x-reverse-transition>
      <ContentCardBack v-model="revealBack" :item="item" @view:parent="$emit('view:parent')" />
    </v-scroll-x-reverse-transition>
  </v-card>
</template>

<script>
import ContentCardActions from '@/components/content/ContentCardActions'
import ContentCardBack from '@/components/content/ContentCardBack'
import Players from '@/players/playerRegistry.json'

export default {
  name: 'ContentCard',

  components: {
    ContentCardActions,
    ContentCardBack
  },

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {
      thumbnailAspectRatio: 4 / 3,
      maxLines: 3,
      isFavourite: false,
      revealBack: false,
      showViewer: false
    }
  },

  computed: {
    durationInMinutes() {
      return this.item.durationInMinutes || ''
    },

    locale() {
      return this.$store.state.i18nStore.locale
    },

    defaultLocale() {
      return this.$store.state.i18nStore.defaultLocale
    },

    sizeInMB() {
      return this.item.sizeInMB || ''
    },

    topic() {
      return this.item.topic?.[this.locale] || this.item.topic?.[this.defaultLocale] || ''
    },

    thumbnailHeight() {
      const heights = {
        xs: 100,
        sm: 125,
        md: 150,
        lg: 175,
        xl: 200
      }

      return heights[this.$vuetify.breakpoint.name] || 100
    }
  },

  created: function () {
    this.isFavourite = this.favourite
  },

  methods: {
    /*
     * manage display
     */
    getMediaIcon(mediaType) {
      return Players.find((player) => player.mediaType === mediaType)?.icon || 'mdi-file'
    }
  }
}
</script>

<style lang="css" scoped>
.c-card {
  margin: auto;
  height: 100%;
  min-width: 280px;
  max-width: min(500px, 100%);
  border-radius: 16px;
}

.c-thumbnail-border {
  background-color: black;
  margin: 0px 16px;
}

.c-clickable {
  cursor: pointer;
}
</style>
