import { render, staticRenderFns } from "./LayoutSelector.vue?vue&type=template&id=2bed7f72&scoped=true&"
import script from "./LayoutSelector.vue?vue&type=script&lang=js&"
export * from "./LayoutSelector.vue?vue&type=script&lang=js&"
import style0 from "./LayoutSelector.vue?vue&type=style&index=0&id=2bed7f72&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bed7f72",
  null
  
)

export default component.exports