<template>
  <AdImage
    v-if="isSingle"
    :ad="singleAd"
    @close="$emit('close')"
    @select="$emit('select', $event)"
  />
  <v-carousel
    v-else
    v-model="slide"
    cycle
    :height="hero ? 600 : 200"
    :hide-delimiters="isSingle"
    hide-delimiter-background
    interval="6000"
    :show-arrows="!isSingle"
    show-arrows-on-hover
    :touch="{
      left: () => slide--,
      right: () => slide++
    }"
  >
    <v-carousel-item
      v-for="ad in ads"
      :key="ad.id"
      class="c-item"
    >
      <AdImage
        :ad="ad"
        @close="$emit('close')"
        @select="$emit('select', $event)"
      />
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import AdImage from '@/components/ad/AdImage'

export default {
  name: 'AdCarousel',

  components: {
    AdImage
  },

  props: {
    ads: {
      type: Array,
      required: false,
      default: () => []
    },

    hero: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data: function () {
    return {
      slide: 0
    }
  },

  computed: {
    isSingle() {
      return this.ads.length < 2
    },

    singleAd() {
      return this.ads[0]
    },

    src() {
      return this.ads[0].adItem.cta?.href
    }
  },

  methods: {}
}
</script>

<style lang="css" scoped>
.c-item {
  position: relative;
  padding: 0px;
  margin: 0px;
}
</style>
