<template>
  <div ref="swipers" class="c-swipers">
    <div ref="slides" class="swiper c-slides">
      <div ref="slideWrapper" class="swiper-wrapper c-slides-wrapper">
        <div v-for="(slide, index) in slides" :key="`slide-${index}`" class="swiper-slide c-slide">
          <slot name="slide" :src="slide" />
        </div>
      </div>
      <!-- navigation buttons -->
      <div
        v-show="hasMultipleSlides"
        ref="slidePrev"
        class="swiper-button-prev c-slides-button-prev"
      />
      <div
        v-show="hasMultipleSlides"
        ref="slideNext"
        class="swiper-button-next c-slides-button-next"
      />
    </div>
    <div
      v-show="hasThumbs"
      ref="thumbs"
      class="swiper c-thumbs"
      :class="{ 'c-thumbs--visible': showThumbs }"
    >
      <div class="swiper-wrapper c-thumbs-wrapper">
        <div
          v-for="(thumb, index) in thumbs"
          :key="`thumb-${index}`"
          class="swiper-slide c-thumb"
          :class="{'c-thumb--manual': manualSync}"
        >
          <slot name="thumb" :src="thumb" />
        </div>
      </div>
      <!-- navigation buttons -->
      <div ref="thumbPrev" class="swiper-button-prev c-thumbs-button-prev" />
      <div ref="thumbNext" class="swiper-button-next c-thumbs-button-next" />
    </div>
  </div>
</template>

<script>
import Swiper, { FreeMode, Keyboard, Lazy, Mousewheel, Navigation, Thumbs } from 'swiper'
import 'swiper/css/bundle'
// import 'swiper/swiper.min.css'
// import 'swiper/modules/free-mode/free-mode.min.css'
// import 'swiper/modules/keyboard/keyboard.min.css'
// import 'swiper/modules/lazy/lazy.min.css'
// import 'swiper/modules/mousewheel/mousewheel.min.css'
// import 'swiper/modules/navigation/navigation.min.css'
// import 'swiper/modules/thumbs/thumbs.min.css'

Swiper.use([FreeMode, Lazy, Mousewheel, Keyboard, Navigation, Thumbs])

export default {
  name: 'SlideSwiper',

  components: {},

  props: {
    slides: {
      type: Array,
      required: true
    },

    thumbs: {
      type: Array,
      required: false,
      default: () => []
    },

    landscape: {
      type: Boolean,
      required: false,
      default: true
    },

    width: {
      type: Number,
      required: false,
      default: 1024
    },

    height: {
      type: Number,
      required: false,
      default: 768
    }
  },

  data: function () {
    return {
      onResizeDebounced: null,
      swiperHeight: 0,
      swiperWidth: 0,
      isLandscape: this.landscape,
      tapTimer: null,

      // main slide data
      slideSwiper: null,
      slideOptions: {
        modules: [FreeMode, Keyboard, Lazy, Mousewheel, Navigation, Thumbs],
        // core settings
        cssMode: true,
        preloadImages: false,
        slidesPerGroup: 1,
        slidesPerView: 1,
        spaceBetween: 0,
        // module settings
        freeMode: {
          enabled: true,
          sticky: true
        },
        keyboard: true,
        lazy: {
          loadPrevNext: true
        },
        mousewheel: true,
        navigation: {
          prevEl: null,
          nextEl: null
        },
        thumbs: {
          swiper: undefined
        }
      },

      // thumbnail data
      thumbSwiper: undefined,
      showThumbs: false,
      thumbActiveClass: 'c-thumb-active',
      thumbOptions: {
        modules: [FreeMode, Keyboard, Mousewheel, Navigation],
        cssMode: false,
        freeMode: {
          enabled: true,
          minimumVelocity: 0.3
        },
        // grabCursor: true,
        keyboard: true,
        mousewheel: true,
        navigation: {
          prevEl: null,
          nextEl: null
        },
        // rewind: true,
        roundLengths: true,
        slidesPerGroup: 1,
        slidesPerView: 1,
        spaceBetween: 10,
        threshold: 10
      }
    }
  },

  computed: {
    hasMultipleSlides() {
      return this.slides.length > 1
    },

    hasThumbs() {
      return this.thumbs.length > 1
    },

    manualSync() {
      return this.hasThumbs && this.slides.length !== this.thumbs.length
    },

    thumbIndex() {
      const thumbNames = this.thumbs.map((thumb) => thumb.split('/').pop().split('.').shift())
      const slideNames = this.slides.map((slide) => slide.split('/').pop().split('.').shift())
      const indexArray = thumbNames.map((thumb) => slideNames.indexOf(thumb))
      return indexArray
    },

    thumbsPerView() {
      const aspectRatio = this.isLandscape ? 4 / 3 : 3 / 4
      const thumbHeight = this.swiperHeight * 0.2
      const thumbWidth = thumbHeight * aspectRatio
      const maxThumbs = thumbWidth
        ? Math.floor(this.swiperWidth / (thumbWidth + this.thumbOptions.spaceBetween))
        : 1
      return Math.min(maxThumbs, this.thumbs.length)
    }
  },

  watch: {
    slides: {
      immediate: false,
      handler: function (_newSlidess, _oldSlides) {
        console.debug('[SlideSwiper]: watch: updating slides...=', this.slides.length)
        this.$nextTick(() => this.updateSlides())
      }
    },

    thumbs: {
      immediate: false,
      handler: function (_newThumbs, _oldThumbs) {
        console.debug('[SlideSwiper]: watch: updating thumbs...=', this.thumbs.length)
        this.$nextTick(() => this.updateThumbs('...in handler'))
      }
    }
  },

  created: function () {
    this.onResizeDebounced = this.debounce(this.onResize, 50, false)
  },

  mounted: function () {
    // used to calculate swiper options
    this.swiperWidth = this.$refs.swipers.offsetWidth
    this.swiperHeight = this.$refs.swipers.offsetHeight

    // always create a thumb swiper in case there are thumbs after resizing
    this.thumbSwiper = this.createThumbSwiper()

    // sync the thumb swiper only if the number of slides and thumbs match
    this.slideSwiper = this.manualSync
      ? this.createSlideSwiper()
      : this.createSlideSwiper(this.thumbSwiper)

    // this.$refs.slideWrapper.addEventListener('touchstart', this.onTap)
    // this.slideSwiper.on('doubleClick', this.toggleThumbs) // handles doubleTap too
    this.slideSwiper.on('doubleTap', this.toggleThumbs) // handles doubleClick too

    if (this.manualSync) {
      this.syncSwipers()
    }

    window.addEventListener('resize', this.onResizeDebounced)
  },

  beforeDestroy: function () {
    window.removeEventListener('resize', this.onResizeDebounced)

    // this.$refs.slideWrapper.removeEventListener('touchstart', this.onTap)
    // this.slideSwiper.off('doubleClick', this.toggleThumbs)
    this.slideSwiper?.off('doubleTap', this.toggleThumbs)

    if (this.manualSync) {
      this.slideSwiper?.off('slideChange', this.onSlideChange)
      this.thumbSwiper?.off('click', this.onThumbClick)
    }

    if (this.slideSwiper) this.destroySwiper(this.slideSwiper)
    if (this.thumbSwiper) this.destroySwiper(this.thumbSwiper)
  },

  methods: {
    createSlideSwiper(thumbSwiper = null) {
      this.slideOptions.thumbs = thumbSwiper ? { swiper: thumbSwiper } : null
      this.slideOptions.navigation.prevEl = this.$refs.slidePrev
      this.slideOptions.navigation.nextEl = this.$refs.slideNext

      return new Swiper(this.$refs.slides, this.slideOptions)
    },

    createThumbSwiper() {
      this.thumbOptions.slidesPerView = this.thumbsPerView
      this.thumbOptions.slidesPerGroup = this.thumbsPerView > 1 ? this.thumbsPerView - 1 : 1
      this.thumbOptions.navigation.prevEl = this.$refs.thumbPrev
      this.thumbOptions.navigation.nextEl = this.$refs.thumbNext
      return new Swiper(this.$refs.thumbs, this.thumbOptions)
    },

    debounce2(fn, delay) {
      let timer
      return function () {
        clearTimeout(timer)
        timer = setTimeout(fn, delay)
      }
    },

    debounce(func, wait, immediate = false) {
      // 'private' variable for instance
      // The returned function will be able to reference this due to closure.
      // Each call to the returned function will share this common timer.
      var timeout

      // Calling debounce returns a new anonymous function
      return function () {
        // reference the context and args for the setTimeout function
        var context = this
        var args = arguments

        // Should the function be called now? If immediate is true
        //   and not already in a timeout then the answer is: Yes
        var callNow = immediate && !timeout

        // This is the basic debounce behaviour where you can call this
        //   function several times, but it will only execute once
        //   [before or after imposing a delay].
        //   Each time the returned function is called, the timer starts over.
        clearTimeout(timeout)

        // Set the new timeout
        timeout = setTimeout(function () {
          // Inside the timeout function, clear the timeout variable
          // which will let the next execution run when in 'immediate' mode
          timeout = null

          // Check if the function already ran with the immediate flag
          if (!immediate) {
            // Call the original function with apply
            // apply lets you define the 'this' object as well as the arguments
            //    (both captured before setTimeout)
            func.apply(context, args)
          }
        }, wait)

        // Immediate mode and no wait timer? Execute the function..
        if (callNow) func.apply(context, args)
      }
    },

    onResize() {
      this.swiperWidth = this.$refs.swipers.offsetWidth
      this.swiperHeight = this.$refs.swipers.offsetHeight
      this.updateThumbs()
    },

    updateSwiper(swiperInstance) {
      swiperInstance.update() // updates size/number of slides & adds/removes classes

      if (swiperInstance.params.navigation) {
        swiperInstance.navigation.update() // updates enabled/disabled state
      }
      if (swiperInstance.params.pagination) {
        swiperInstance.pagination.update() // updates enabled/disabled state
      }
      if (swiperInstance.params.thumbs?.swiper) {
        swiperInstance.thumbs.update()
      }
    },

    destroySwiper(swiperInstance) {
      const destroyInstance = true
      const cleanStyles = true

      if (!swiperInstance) return

      if (swiperInstance.params.navigation) {
        swiperInstance.navigation.destroy()
      }
      if (swiperInstance.params.pagination) {
        swiperInstance.pagination.destroy()
      }
      swiperInstance.destroy(destroyInstance, cleanStyles)
    },

    syncSwipers() {
      // set first thumb as active
      this.thumbSwiper.slides[0].classList.add(this.thumbActiveClass)
      // update thumb swiper if slide changes (via navigation, pagination, scroll or swipe)
      this.slideSwiper.on('slideChange', this.onSlideChange)
      // update slide swiper if thumb is clicked
      this.thumbSwiper.on('click', this.onThumbClick)
      // this.thumbSwiper.onAny((e) => console.warn('thumbEvent=', e))
    },

    onThumbClick() {
      this.slideSwiper.slideTo(this.thumbIndex[this.thumbSwiper.clickedIndex])
      // for some reason, the custom thumb active class is magically set via click
      // this.thumbSwiper.slides.removeClass(this.thumbActiveClass)
      // this.thumbSwiper.clickedSlide.classList.add(this.thumbActiveClass)
    },

    onSlideChange() {
      // if a thumb exists for the current active slide, advance to it
      // (redundant if thumb was clicked...but c'est la vie)
      const thumbRealIndex = this.thumbIndex.indexOf(this.slideSwiper.realIndex)
      if (thumbRealIndex > -1) {
        this.thumbSwiper.slides.removeClass(this.thumbActiveClass)
        this.thumbSwiper.slides[thumbRealIndex].classList.add(this.thumbActiveClass)
        this.thumbSwiper.slideTo(thumbRealIndex)
      }
    },

    updateSlides() {
      if (this.slideSwiper) {
        this.updateSwiper(this.slideSwiper)
      }
    },

    updateThumbs() {
      if (this.thumbSwiper) {
        this.thumbSwiper.params.slidesPerView = this.thumbsPerView
        this.thumbSwiper.params.slidesPerGroup = this.thumbsPerView > 1 ? this.thumbsPerView - 1 : 1
        this.updateSwiper(this.thumbSwiper)
      }
    },

    toggleThumbs(_swiper, _event) {
      if (this.thumbs.length > 1) {
        this.showThumbs = !this.showThumbs
      }
    },

    onTap(event) {
      if (this.tapTimer) {
        clearTimeout(this.tapTimer)
        this.tapTimer = null
        event.preventDefault()
        this.toggleThumbs()
      } else {
        this.tapTimer = setTimeout(() => {
          this.tapTimer = null
        }, 300)
      }
    }
  }
}
</script>
<style lang="css" scoped>
.c-swipers {
  width: 100%;
  height: 100%;
}

/* common swiper classes */
.swiper {
  --swiper-navigation-color: var(--v-icon-base);
  --swiper-navigation-size: 44px;
  height: 100%;
  width: 100%;
}
.swiper-wrapper {
  box-sizing: border-box;
}
.swiper-slide {
  box-sizing: border-box;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-navigation {
  --swiper-navigation-color: 'white';
  --swiper-navigation-size: '44px';
}

/* containers */
.swiper.c-thumbs {
  background-color: var(--v-background-base);
  bottom: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 0;
  overflow: hidden;
  margin: 0 auto;
  transition: all 0.5s ease;
  z-index: 999;
}
.swiper.c-thumbs.c-thumbs--visible {
  height: max(20%, 120px);
}
.swiper-wrapper.c-thumbs-wrapper {
  padding: 8px 8px;
}
.swiper-slide.c-slide,
.swiper-slide.c-thumb {
  overflow: auto;
}

/* navigation */
.swiper-button-prev,
.swiper-button-next {
  outline: none;
  visibility: hidden;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 24px 24px 24px 24px;
}
.swiper-button-prev:hover::after,
.swiper-button-next:hover::after {
  background-color: rgba(0, 0, 0, 0.4);
}

.swiper.c-slides:hover .swiper-button-prev,
.swiper.c-slides:hover .swiper-button-next {
  visibility: visible !important;
}
.swiper.c-thumbs:hover .swiper-button-prev,
.swiper.c-thumbs:hover .swiper-button-next {
  visibility: visible !important;
}

/* thumbnails */
.swiper-slide.c-thumb {
  cursor: pointer;
  opacity: 0.5;
}
.swiper-slide.c-thumb.c-thumb-active.c-thumb--manual,
.swiper-slide.c-thumb.swiper-slide-thumb-active {
  opacity: 1;
}
</style>
