import Vue from 'vue'
import Vuex from 'vuex'

import appConfig from '@/config/appConfig.js'

Vue.use(Vuex)

const initialState = {
  overlapMode: appConfig.defaultCalendarOverlapMode
}

export default {
  namespaced: true,

  state: () => initialState,

  getters: {},

  mutations: {
    /* common mutations */

    syncState(state, newState) {
      // spread operator breaks reactivity: state = {...state, ...newState}
      Object.keys(newState).forEach((key) => {
        // ignore any deprecated keys
        if (key in state) state[key] = newState[key]
      })
    },

    /* specific mutations */

    setOverlapMode(state, overlapMode) {
      state.overlapMode = overlapMode
    }
  },

  actions: {
    /* common actions */

    initStore({ state, commit }, { sessionState }) {
      commit('syncState', { ...state, ...sessionState })
    },

    migrateStore({ state, commit }, { sessionState }) {
      commit('syncState', { ...state, ...sessionState })
    },

    /* specific actions */

    updateOverlapMode({ commit }, { overlapMode }) {
      commit('setOverlapMode', overlapMode)
    }
  }
}
