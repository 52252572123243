// import Vue from 'vue'
import AuthService from '@/services/authService'
import ProfileService from '@/services/profileService'
import Vue from 'vue'

const authService = new AuthService()
const profileService = new ProfileService()

const initialize = async () => {
  try {
    await authService.initialize()
  } catch (error) {
    console.error('[authPlugin]: Initialization failed!', error)
    Vue.prototype.$error(error)
  }
}

export class AuthPlugin {
  static install(Vue, _options) {
    initialize()
    Vue.prototype.$auth = authService
    Vue.prototype.$profile = profileService
  }
}
