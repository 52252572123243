<template>
  <div ref="video" class="c-video-player c-video-wrapper">
    <video
      controlsList="nodownload noremoteplayback"
      controls
      preload="metadata"
      :poster="poster"
    >
      <source :src="url" type="video/mp4">
      {{ $t('notice.browser.videoNotSupported') }}
    </video>
  </div>
</template>

<script>
export default {
  name: 'VideoPlayer',

  components: {},

  props: {
    content: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {
      poster: this.content.thumbnail,
      url: this.content.contentURL,
      isFullscreen: false
    }
  },

  mounted: function () {
    this.$refs.video.addEventListener('fullscreenchange', this.onFullscreen)
    this.$refs.video.addEventListener('webkitfullscreenchange', this.onFullscreen)
  },

  beforeDestroy: function () {
    this.$refs.video.removeEventListener('fullscreenchange', this.onFullscreen)
    this.$refs.video.removeEventListener('webkitfullscreenchange', this.onFullscreen)
  },

  methods: {
    onFullscreen() {
      this.isFullscreen = !this.isFullscreen
    }
  }
}
</script>

<style lang="css" scoped>
.c-video-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
}

video {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
}
</style>
