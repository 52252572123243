<template>
  <v-toolbar
    class="c-content-toolbar mb-0"
    color="secondary"
    :dense="dense"
    fixed
    flat
    tile
    width="100%"
  >
    <!-- search -->
    <v-text-field
      v-model="searchInput"
      class="c-search"
      clearable
      dense
      hide-details
      outlined
      :placeholder="$t('ui.search')"
      prepend-inner-icon="mdi-magnify"
      single-line
      @paste="onPaste"
      @input="debounceSearch"
      @click.stop=""
    />

    <!-- title -->
    <div v-if="title" class="c-title text-h4">
      {{ title }}
    </div>
    <v-spacer v-else />

    <!-- control buttons -->
    <div class="d-flex align-center justify-end">
      <!-- expand/collapse for tree view sections -->
      <v-btn
        v-show="isTree && !hideControls"
        class="c-btn"
        icon
        @click.stop="toggleTree"
      >
        <v-icon large>
          {{ isTreeExpanded ? 'mdi-arrow-collapse-vertical' : 'mdi-arrow-expand-vertical' }}
        </v-icon>
      </v-btn>

      <!-- sort -->
      <v-menu
        v-if="!hideControls && sorts.length > 0"
        bottom
        offset-y
        :open-on-click="true"
        :close-on-click="true"
        :close-on-content-click="true"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            class="c-btn"
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon large>
              mdi-sort
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item-group
            v-model="currentSortOptionIndex"
            color="accent"
            mandatory
          >
            <v-list-item
              v-for="s in sorts"
              :key="s.value"
              @click="onSort(s.value)"
            >
              <v-list-item-title>{{ s.text }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>

      <!-- filters -->
      <v-menu
        v-if="hasFilters && !hideControls"
        v-model="showFilters"
        content-class="c-filter-menu"
        bottom
        offset-y
        :open-on-click="true"
        :close-on-click="true"
        :close-on-content-click="false"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            class="c-btn"
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon :class="{ 'accent--text': anyFiltersActive }" large>
              mdi-filter
            </v-icon>
          </v-btn>
        </template>
        <v-list class="c-filter-list">
          <v-list-item
            v-for="filter in filters"
            :key="filter.field"
          >
            <v-select
              v-model="selectedFilters[filter.field]"
              class="c-selector mx-2 my-2"
              chips
              clearable
              color="accent"
              deletable-chips
              :dense="dense"
              hide-details
              item-color="accent"
              :items="extractFilterValues(items, filter)"
              :label="filter.label"
              :menu-props="{
                contentClass: 'c-selector-menu',
                offsetY: true,
                closeOnClick: true,
                closeOnContentClick: false
              }"
              multiple
              outlined
              @change="emitResults"
            />
          </v-list-item>
        </v-list>
      </v-menu>

      <!-- layout -->
      <div v-if="hasLayouts && dial">
        <LayoutSelector
          v-model="displayLayout"
          :layouts="layouts"
          @status="hideControls = $event"
          @update:layout="$emit('update:layout', $event)"
        />
      </div>
      <div v-if="hasLayouts && !dial">
        <v-btn
          class="c-btn"
          icon
          @click.stop="changeLayout(otherLayout)"
        >
          <v-icon large>
            {{ layoutIcon[otherLayout] }}
          </v-icon>
        </v-btn>
      </div>
    </div>
  </v-toolbar>
</template>

<script>
import sortMixin from '@/mixins/sortMixin'
import LayoutSelector from '@/components/base/LayoutSelector'

export default {
  name: 'LayoutToolbar',

  components: {
    LayoutSelector
  },

  mixins: [sortMixin],

  model: {
    prop: 'layout',
    event: 'update:layout'
  },

  props: {
    dial: {
      type: Boolean,
      required: false,
      default: true
    },

    expand: {
      type: Boolean,
      required: false,
      default: true
    },

    filters: {
      type: Array,
      required: false,
      default: () => []
    },

    items: {
      type: Array,
      required: true
    },

    layout: {
      type: String,
      required: false,
      default: 'grid'
    },

    layouts: {
      type: Array,
      required: false,
      default: () => ['grid', 'tree']
    },

    reset: {
      type: Number,
      required: false,
      default: 0
    },

    sort: {
      type: String,
      required: false,
      default: 'sortByPublishDateDesc'
    },

    sorts: {
      type: Array,
      required: false,
      default: () => []
    },

    title: {
      type: String,
      required: false,
      default: ''
    }
  },

  data: function () {
    return {
      // layout
      displayLayout: this.layout,
      hideControls: false,
      isTreeExpanded: this.expand,
      // search
      debounceTimer: null,
      searchInput: null,
      searchTerms: '',
      // filters
      showFilters: false,
      selectedFilters: {},
      // sorting
      currentSortOptionIndex: 0,
      currentSortOptionName: ''
    }
  },

  computed: {
    /* state variables */

    defaultLocale() {
      return this.$store.state.i18nStore.defaultLocale
    },

    dense() {
      return this.$vuetify.breakpoint.mobile
    },

    locale() {
      return this.$store.state.i18nStore.locale
    },

    /* layout */

    hasLayouts() {
      return this.layouts.length > 1
    },

    isCarousel() {
      return this.layout === 'carousel'
    },

    isGrid() {
      return this.layout === 'grid'
    },

    isTree() {
      return this.layout === 'tree'
    },

    layoutIcon() {
      return {
        carousel: 'mdi-view-column',
        grid: 'mdi-apps',
        table: 'mdi-table',
        tree: 'mdi-file-tree'
      }
    },

    otherLayout() {
      // used to toggle layouts between two values vs using a speeddial
      return this.layouts.find((layout) => layout !== this.displayLayout)
    },

    /* filtering */

    anyFiltersActive() {
      let active = false
      for (const key in this.selectedFilters) {
        // filters are arrays of strings or single strings
        if (this.selectedFilters[key].length > 0) {
          active = true
        }
      }
      return active
    },

    hasFilters() {
      return this.filters.length > 0
    },

    /* search */

    searchTermFilters() {
      return this.searchTerms.length > 0 ? this.searchTerms.toLowerCase().split(' ') : []
    },

    /* generate result set */

    resultSet() {
      let items = this.items

      // apply all active filters
      for (const f of this.filters) {
        const field = f.field
        const isMultiValued = f.isMultiValued
        const requiresTranslation = f.requiresTranslation
        const values = this.selectedFilters[f.field]

        items = items.filter((item) => {
          if (isMultiValued) {
            return item[field]?.length > 0 && values.length > 0
              ? this.intersects(
                  item[field].map((s) =>
                    requiresTranslation ? s[this.locale] || s[this.defaultLocale] : s
                  ),
                  values
                )
              : true
          } else {
            return item[field] && values.length > 0
              ? values.includes(
                  requiresTranslation
                    ? item[field][this.locale] || item[field][this.defaultLocale]
                    : item[field]
                )
              : true
          }
        })
      }

      // find items matching search terms
      items = items.filter((item) => {
        return this.searchTerms.length > 0
          ? this.searchTermFilters.every(
              (searchTerm) =>
                item.title.toLowerCase().includes(searchTerm) ||
                item.subtitle?.toLowerCase().includes(searchTerm) ||
                item.tags?.join('-').includes(searchTerm)
            )
          : true
      })

      // sort the list
      items = items.sort(this.$_sortMixin_getSortFunction(this.currentSortOptionName))

      return items
    }
  },

  watch: {
    items: {
      immediate: false,
      handler: function (_newVal, _oldVal) {
        this.emitResults()
      }
    },

    reset: {
      immediate: false, // initial values captured via data()
      handler: function (_newVal, _oldVal) {
        this.searchInput = null
        this.searchTerm = ''
        for (const filter of this.filters) {
          this.selectedFilters[filter.field] = filter.isMultiValued ? [] : ''
        }
      }
    }
  },

  created: function () {
    // initialize filters
    const selectedFilters = {}
    for (const filter of this.filters) {
      selectedFilters[filter.field] = filter.isMultiValued ? [] : ''
    }
    this.selectedFilters = { ...selectedFilters } // add keys reactively

    // initialize sort options
    this.currentSortOptionIndex = this.getSortOptionIndex(this.sort)
    this.currentSortOptionName = this.sorts[this.currentSortOptionIndex]?.value || ''
  },

  methods: {
    /* generate resultSet */

    intersects(a, b) {
      const baseSet = new Set(b)
      const intersection = a.filter((el) => baseSet.has(el))
      return intersection.length > 0
    },

    emitResults() {
      // reactively recalculates resultSet and then emits
      this.$emit('results', this.resultSet)
      this.$emit('count', this.resultSet.length)
    },

    /* manage sort */

    getSortOptionIndex(sortName) {
      const index = this.sorts.findIndex((option) => option.value === sortName)
      return index >= 0 ? index : 0
    },

    onSort(sortOptionName) {
      this.currentSortOptionName = sortOptionName
      this.$emit('update:sort', sortOptionName)
      this.emitResults()
    },

    /* manage search */

    clearSearch() {
      this.searchInput = null // tiggers @input hence debounceSearch()
    },

    onPaste(e) {
      this.searchTerms = e.clipboardData.getData('text') || ''
      this.emitResults()
    },

    debounceSearch(searchInput) {
      // called whenever input changes, including @click:clear
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        this.searchTerms = searchInput || ''
        this.emitResults()
      }, 300)
    },

    /* manage filters */

    clearFilters() {
      for (const filter of this.filters) {
        this.selectedFilters[filter.field] = filter.isMultiValued ? [] : ''
      }
      this.emitResults()
    },

    extractFilterValues(items, filter) {
      const values = items.map((item) => item[filter.field])
      const valueArray = filter.isMultiValued ? values.flat() : values
      const valueSet = new Set(valueArray)
      return [...valueSet]
        .map((value) =>
          value
            ? filter.requiresTranslation
              ? value[this.locale] || value[this.defaultLocale]
              : value
            : ''
        )
        .filter(Boolean)
        .sort()
        .map((value) => {
          return {
            value: value,
            text: value
          }
        })
    },

    /* manage layout */

    changeLayout(layout) {
      this.displayLayout = layout
      this.$emit('update:layout', layout)
    },

    /* manage tree */

    toggleTree() {
      if (this.isTreeExpanded) {
        this.isTreeExpanded = false
        this.$emit('collapse')
      } else {
        this.isTreeExpanded = true
        this.$emit('expand')
      }
    }
  }
}
</script>

<style lang="css" scoped>
.c-search,
.c-sort,
.c-filter {
  max-width: 300px;
}

.c-overscroll {
  overscroll-behavior: contain; /* all this seems to do is add a "pause" before the scroll chaining occurs */
  -ms-scroll-chaining: contain;
}
</style>

<style lang="css">
.c-content-toolbar .v-toolbar__content {
  justify-content: space-between;
}

/* global styles because v-select menu-prop content-class not working */
.c-filter-menu,
.c-selector-menu {
  min-width: 60px !important;
  max-width: max(30%, 300px);
  left: unset !important;
  right: 16px !important;
}
.c-selector-menu .v-select-list {
  overscroll-behavior: contain;
  -ms-scroll-chaining: contain;
}
.c-selector-menu .v-list-item__action,
.c-selector-menu .v-list-item__action:first-child {
  margin-right: 0 !important;
}
.c-selector-menu .v-input--selection-controls__input i {
  font-size: 1rem;
}
.c-selector-menu .v-list-item__title {
  font-size: 1rem;
}
</style>
