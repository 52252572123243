<template>
  <v-toolbar dense floating rounded="pill">
    <!-- tool: draw(scribble, line, square, circle) -->
    <DrawTool
      v-model="selectedControls.shape"
      :options="shapes"
      :active="!isTextMode"
      @selected="updateShape($event.value)"
    />

    <!-- option: widths (s, m, l, xl) -->
    <DrawTool
      v-model="selectedControls.width"
      :options="widths"
      @selected="updateOptions({ width: $event.value })"
    />

    <!-- option: colour(blue, green, orange, red, yellow, violet) -->
    <DrawTool
      v-model="selectedControls.colour"
      :options="colours"
      icon="mdi-palette"
      @selected="updateOptions({ colour: $event.value })"
    />

    <!-- option: font(regular, bold, italic) -->
    <DrawTool
      v-if="showAdvancedTools"
      v-model="selectedControls.font"
      :options="fonts"
      :active="isTextMode"
      @selected="updateTextMode({ font: $event.value })"
    />

    <!-- option: eraser (true/false) -->
    <v-btn
      v-ripple="{ class: 'primary--text' }"
      active-class="c-btn--active"
      class="c-btn"
      fab
      :input-value="isEraseMode"
      small
      text
      @click="updateEraseMode()"
    >
      <v-icon large>
        mdi-eraser
      </v-icon>
    </v-btn>

    <!-- action: clear -->
    <v-btn
      v-ripple="{ class: 'primary--text' }"
      class="c-btn c-btn--stateless"
      fab
      small
      text
      @click="$emit('clear')"
    >
      <v-icon large>
        mdi-backspace
      </v-icon>
    </v-btn>

    <!-- action: undo -->
    <v-btn
      v-if="$vuetify.breakpoint.smAndUp"
      v-ripple="{ class: 'primary--text' }"
      class="c-btn c-btn--stateless"
      fab
      small
      text
      @click="$emit('undo')"
    >
      <v-icon large>
        mdi-undo-variant
      </v-icon>
    </v-btn>

    <!-- action: redo -->
    <v-btn
      v-if="$vuetify.breakpoint.smAndUp"
      v-ripple="{ class: 'primary--text' }"
      class="c-btn c-btn--stateless"
      fab
      small
      text
      @click="$emit('redo')"
    >
      <v-icon large>
        mdi-redo-variant
      </v-icon>
    </v-btn>

    <!-- action: download -->
    <v-btn
      v-ripple="{ class: 'primary--text' }"
      class="c-btn c-btn--stateless"
      fab
      small
      text
      @click="$emit('download')"
    >
      <v-icon large>
        mdi-file-download
      </v-icon>
    </v-btn>

    <!-- action: capture -->
    <v-btn
      v-if="showAdvancedTools"
      v-ripple="{ class: 'primary--text' }"
      class="c-btn c-btn--stateless"
      fab
      small
      text
      @click="$emit('capture')"
    >
      <v-icon large>
        mdi-camera
      </v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
import { defaultControls, colours, fonts, shapes, widths } from './defaultControls'
import DrawTool from '@/components/draw/DrawTool'

export default {
  name: 'DrawControls',

  components: {
    DrawTool
  },

  model: {
    prop: 'controls',
    event: 'updated:controls'
  },

  props: {
    controls: {
      type: Object,
      required: false,
      default: () => defaultControls
    }
  },

  data: function () {
    return {
      isEraseMode: false,
      isTextMode: false,

      // options
      colours: colours,
      fonts: fonts,
      shapes: shapes,
      widths: widths,

      // current values
      selectedControls: {
        colour: this.controls.colour,
        font: this.controls.font,
        shape: this.controls.shape,
        width: this.controls.width
      }
    }
  },

  computed: {
    isDark() {
      return this.$store.state.themeStore.isDark
    },

    showAdvancedTools() {
      return this.$feature('advancedDrawTools')
    }
  },

  created: function () {},

  methods: {
    updateEraseMode() {
      this.isTextMode = false
      this.$emit('text', this.isTextMode)
      this.isEraseMode = !this.isEraseMode
      this.$emit('erase', this.isEraseMode)
      this.$emit('updated:controls', this.selectedControls)
    },

    updateTextMode(option) {
      this.isTextMode = true
      this.isEraseMode = false
      this.$emit('text', this.isTextMode)
      this.$emit('erase', this.isEraseMode)
      this.$emit('update:option', { font: option.font })
      this.$emit('updated:controls', this.selectedControls)
    },

    updateOptions(option) {
      for (const key in option) {
        this.$emit('update:option', { [key]: option[key] })
        this.$emit('updated:controls', this.selectedControls)
      }
    },

    updateShape(shape) {
      this.isTextMode = false
      this.$emit('update:shape', shape)
      this.$emit('updated:controls', this.selectedControls)
    }
  }
}
</script>

<style lang="css" scoped></style>
