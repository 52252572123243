<template>
  <v-app-bar
    ref="bar"
    app
    :style="leftStyle"
    :clipped-left="clipped"
    :clipped-right="clipped"
    color="primary"
    :height="64"
    hide-on-scroll
    scroll-target="#main"
  >
    <!-- left nav controller -->
    <v-btn icon @click="$emit('toggle')">
      <v-icon color="icon" x-large>
        {{ menu ? 'mdi-menu-open' : 'mdi-menu' }}
      </v-icon>
    </v-btn>

    <!-- title -->
    <v-toolbar-title class="c-title">
      {{ pageTitle }} {{ pageSubtitle }}
    </v-toolbar-title>

    <v-spacer />

    <!-- control buttons -->
    <div class="c-controls d-flex justify-space-between align-center">
      <AppOnlineStatus v-if="isPWA" />
      <AppNotifications v-if="hasNotifications" class="ml-4" />
      <AccountManager />
    </div>
  </v-app-bar>
</template>

<script>
// components
import AccountManager from '@/components/account/AccountManager'
import AppOnlineStatus from '@/components/app/AppOnlineStatus'
import AppNotifications from '@/components/app/AppNotifications.vue'

export default {
  name: 'AppTopBar',

  components: {
    AccountManager,
    AppOnlineStatus,
    AppNotifications
  },

  props: {
    menu: {
      type: Boolean,
      required: false,
      default: true
    },

    title: {
      type: String,
      required: false,
      default: ''
    },

    subtitle: {
      type: String,
      required: false,
      default: ''
    }
  },

  data: function () {
    return {
      clipped: false // false ensures app bar is not covered by the nav bar
    }
  },

  computed: {
    isPWA() {
      return this.$feature('pwa')
    },

    hasNotifications() {
      return this.$feature('notifications')
    },

    leftStyle() {
      return {
        left: `${this.$appConfig.leftMargin}px !important`
      }
    },

    hasSubtitle() {
      return !!this.$route.meta?.hasCount
    },

    pageSubtitle() {
      return this.hasSubtitle && this.subtitle ? `(${this.subtitle})` : ''
    },

    pageTitle() {
      return this.title
    }
  }
}
</script>

<style lang="css" scoped>
.c-title {
  font-size: 1.75rem;
}

.c-menu-button:focus::before {
  opacity: 0 !important;
}
</style>
