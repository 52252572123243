import { render, staticRenderFns } from "./AdCopy.vue?vue&type=template&id=3ace7979&scoped=true&"
import script from "./AdCopy.vue?vue&type=script&lang=js&"
export * from "./AdCopy.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ace7979",
  null
  
)

export default component.exports