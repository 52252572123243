import LoggerService from '@/services/loggerService.js'
import errorMixin from '@/mixins/errorMixin.js'

const logger = new LoggerService(process.env.NODE_ENV)

const formatName = function (vm) {
  if (!vm) {
    return '<Unknown>'
  }
  if (vm.$root === vm) {
    return '<Root>'
  }
  const options =
    typeof vm === 'function' && vm.cid != null
      ? vm.options
      : vm._isVue
      ? vm.$options || vm.constructor.options
      : vm
  var name = options.name || options._componentTag
  var file = options.__file
  if (!name && file) {
    var match = file.match(/([^/\\]+)\.vue$/)
    name = match && match[1]
  }

  return name ? '<' + name + '>' : '<Anonymous>' + file
}

const errorHandler = (err, vm, info) => {
  const message = `Vue: ${formatName(vm)}: ${err?.name} (in ${info})`
  const stack = err?.stack
  logger.logError({ message, stack })
}

const warnHandler = (msg, vm, trace) => {
  const message = `Vue: ${formatName(vm)}: ${msg}`
  logger.logWarning({ message, trace })
}

const onError = () => {
  if (typeof window !== 'undefined') {
    window.onerror = function (msg, url, lineNo, colNo, errObject) {
      if (msg === 'ResizeObserver loop limit exceeded') {
        // console.warn('Ignored: ResizeObserver loop limit exceeded')
        return false
      } else {
        const message = `Browser error. ${msg} at [${url}:${lineNo}:${colNo}].`
        const stack = errObject?.stack || ''
        logger.logError({ message, stack })
      }
    }
  }
}

export class ErrorHandlerPlugin {
  static install(Vue, _options) {
    // setup Vue error handling
    Vue.config.errorHandler = errorHandler
    Vue.config.warnHandler = warnHandler
    Vue.mixin(errorMixin)
    Vue.prototype.$logger = logger
    // setup browser error handling
    onError()
  }
}
