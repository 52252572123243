import { ConnectionStatus, DirectLine } from 'botframework-directlinejs'

// global.XMLHttpRequest = require('xhr2')
// global.WebSocket = require('ws')

// Option 1: use the directline REST API
//    https://docs.microsoft.com/en-us/azure/bot-service/rest-api/bot-framework-rest-direct-line-3-0-api-reference?view=azure-bot-service-4.0
// Option 2: use the directline JavaScript API
//    https://github.com/microsoft/BotFramework-DirectLineJS/blob/master/src/directLine.ts

/*
  General Flow:
  1)get a direct line token (and optionally a speech services token)
  2)keep the token alive?
  3)start a conversation (optional - implicitly started on first activity)
  4)post, listen and reply "activities" (e.g. 'event', 'message', 'typing', 'command'/'commandResult')
  5)resume a conversation (if "dropped" - i.e. token expires due to closing the session)
  6)end the conversation
*/

export default class ChatbotService {
  /**
   * @constructor
   */
  constructor() {
    this.directLine = null
    this.locale = 'en'
    this.watermark = null
  }

  /* token methods */

  async getDirectLineToken(dlToken = null) {
    try {
      // POST /v3/directline/tokens/generate
      // POST /v3/directline/tokens/refresh
      const url = `/api/token/directline/${dlToken ? 'refresh' : 'generate'}`
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: dlToken ? JSON.stringify({ token: dlToken }) : JSON.stringify({})
      }

      const response = await fetch(url, requestOptions)
      if (response.ok) {
        const json = await response.json()
        return dlToken ? json.conversation : json.token
      } else {
        throw new Error(response.statusText)
      }
    } catch (error) {
      const action = dlToken ? 'refreshing' : 'retrieving'
      const errorMessage = `[chatbotService]: Error ${action} direct line token: ${error}`
      console.error(errorMessage)
      throw errorMessage
    }
  }

  async getSpeechServicesToken() {
    try {
      const response = await fetch('/api/token/speechservices')
      if (response.ok) {
        const { region, token } = await response.json()
        return { region, token }
      } else {
        throw new Error(response.statusText)
      }
    } catch (error) {
      const errorMessage = `[chatbotService]: Error retrieving speech services token: ${error}`
      console.error(errorMessage)
      throw errorMessage
    }
  }

  /* connection methods */

  async getDirectLine({ token, locale, conversation = null, watermark = null }) {
    try {
      const dlToken = token || conversation?.token
      this.locale = locale // save the locale to resume the conversation

      if (dlToken) {
        const directLine = new DirectLine({
          token: dlToken,
          conversationId: conversation?.conversationId || null,
          conversationStartProperties: {
            locale: locale
          },
          referenceGrammarId: conversation?.referenceGrammarId || null,
          streamUrl: conversation?.streamUrl || null,
          tokenRefreshSubscription: null,
          watermark: watermark,
          webSocket: !watermark // webSockets don't support watermarks
        })
        this.directLine = directLine
        return directLine
      } else {
        const errorMessage = 'Missing directLine token.'
        throw new Error(errorMessage)
      }
    } catch (error) {
      const errorMessage = `[chatbotService]: Unable to create DirectLine object. ${error}`
      console.error(errorMessage)
      throw errorMessage
    }
  }

  monitorConnection() {
    this.directLine.connectionStatus$.subscribe((status) => {
      switch (status) {
        case ConnectionStatus.Uninitialized:
        case ConnectionStatus.Connecting:
        case ConnectionStatus.FailedToConnect:
        case ConnectionStatus.Ended:
          console.debug('[chatbotService]: Connection status=', status)
          break

        case ConnectionStatus.Online:
          console.debug(`[chatbotService]: Connection online! (status=${status})`)
          // put token and conversationId into session storage
          window.sessionStorage.setItem('chatbot:token', this.directLine.token)
          window.sessionStorage.setItem('chatbot:conversationId', this.directLine.conversationId)
          break

        case ConnectionStatus.ExpiredToken:
          console.debug(`[chatbotService]: Connection token expired! (status=${status})`)
          this.resumeConversation({ watermark: this.watermark })
          break
      }
    })
  }

  // note: when using a token, the DirectLine object will automatically refresh it
  keepConnectionAlive(token) {
    // every 25 minutes, refresh the direct line token
    setInterval(() => {
      const conversation = this.getDirectLineToken(token)
      this.directLine.reconnect(conversation)
    }, 1500000)
  }

  /* conversation methods */

  async startConversation({ token, locale }) {
    // note: directLineJS will implicitly start the conversation on the first activity
    try {
      // POST /v3/directline/conversations
      const response = await fetch('/api/conversations', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({
          token: token
          // user: { id: user.id, name: user.name }
        })
      })
      if (response.ok) {
        // create a new direct line object for this conversation
        const conversation = await response.json()
        this.directLine = this.getDirectLine({ conversation, locale })
        return this.directLine
      } else {
        throw new Error(response.statusText)
      }
    } catch (error) {
      const errorMessage = `[chatbotService]: Unable to start a conversation. ${error}`
      console.error(errorMessage)
      throw errorMessage
    }
  }

  async getConversation({ token, conversationId, watermark }) {
    try {
      // GET https://directline.botframework.com/v3/directline/conversations/{conversationId}?watermark={watermark_value}
      // Authorization: Bearer SECRET_OR_TOKEN
      const response = await fetch(`/api/conversations/${conversationId}?watermark=${watermark}`, {
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer' + token,
          'Content-Type': 'application/json'
        },
        method: 'GET',
        withCredentials: true,
        credentials: 'include'
      })
      if (response.ok) {
        const conversation = await response.json()
        return conversation
      } else {
        throw new Error(response.statusText)
      }
    } catch (error) {
      const errorMessage = `[chatbotService]: Unable to retrieve the conversation. ${error}`
      console.error(errorMessage)
      throw errorMessage
    }
  }

  async resumeConversation({ watermark }) {
    // 1) https://docs.microsoft.com/en-us/azure/bot-service/rest-api/bot-framework-rest-direct-line-3-0-reconnect-to-conversation?view=azure-bot-service-4.0
    // 2) https://docs.microsoft.com/en-us/azure/bot-service/rest-api/bot-framework-rest-direct-line-3-0-receive-activities?view=azure-bot-service-4.0#connect-via-websocket

    // get previous conversation's token and conversationId from session storage
    const dlToken = window.sessionStorage.getItem('chatbot:token') || null
    const conversationId = window.sessionStorage.getItem('chatbot:conversationId') || null

    // retrieve the conversation
    const conversation = this.getConversation(dlToken, conversationId, watermark)

    // construct a new DirectLine object and return it
    this.directLine = this.getDirectLine({ conversation, locale: this.locale, watermark })
    return this.directLine
  }

  /* activity methods */

  listen() {
    // GET /v3/directline/conversations/{conversationId}/activities
    const { activities, watermark } = this.directLine.activity$
      .filter((activity) => activity.type === 'message' && activity.from.id === this.botId)
      .subscribe((message) => {
        console.debug('[chatbotService]: received message: ', message)
      })

    this.watermark = watermark // used for replaying unseen messages during a reconnect

    return { activities, watermark }
  }

  post(message) {
    // POST /v3/directline/conversations/{conversationId}/activities
    this.directLine
      .postActivity({
        // from: { id: user.id, name: user.name } // only used if specified on startConversation,
        type: 'message',
        text: message
      })
      .subscribe(
        (id) => console.debug('[chatbotService]: Posted activity, assigned ID ', id),
        (error) => console.debug('[chatbotService]: Error posting activity', error)
      )
  }
}
