<template>
  <div v-if="showAds">
    <div class="c-ads">
      <AdStack
        v-if="stack"
        :ads="adsSubarray"
        :header="header"
        @close="onCloseAds()"
        @select="onSelectAd($event)"
      />
      <AdCarousel
        v-if="carousel"
        :ads="adsSubarray"
        :hero="hero"
        @close="onCloseAds()"
        @select="onSelectAd($event)"
      />
    </div>
    <v-dialog
      v-model="showModal"
      content-class="c-modal"
      overlay-opacity="0.75"
      transition="dialog-top-transition"
      width="75%"
      @keydown.esc="showModal = false"
    >
      <v-card class="c-modal-content">
        <iframe id="popup" :src="modalSrc" width="100%" height="100%" />
      </v-card>
      <v-btn class="c-btn-close__modal" icon @click="showModal = false">
        <v-icon x-large>
          mdi-close
        </v-icon>
      </v-btn>
    </v-dialog>
  </div>
</template>

<script>
import AdCarousel from '@/components/ad/AdCarousel'
import AdStack from '@/components/ad/AdStack'

export default {
  name: 'AdBlock',

  components: {
    AdCarousel,
    AdStack
  },

  model: {
    prop: 'show',
    event: 'update:show'
  },

  props: {
    ads: {
      type: Array,
      required: false,
      default: () => []
    },

    max: {
      type: Number,
      required: false,
      default: 3
    },

    carousel: {
      type: Boolean,
      required: false,
      default: false
    },

    header: {
      type: Boolean,
      required: false,
      default: true
    },

    hero: {
      type: Boolean,
      required: false,
      default: true
    },

    stack: {
      type: Boolean,
      required: false,
      default: false
    },

    show: {
      type: Boolean,
      required: true
    }
  },

  data: function () {
    return {
      ad: null,
      modalSrc: '',
      showModal: false
    }
  },

  computed: {
    adsSubarray() {
      return this.max < this.ads.length ? this.getRandomSubarray(this.ads, this.max) : this.ads
    },

    showAds() {
      return this.show && this.ads.length > 0
    }
  },

  methods: {
    onSelectAd(ad) {
      this.ad = ad
      this.modalSrc = ad.adItem.cta?.href || ''
      console.warn('[AdBlock]: select ad=', ad)
      if (ad.adItem.cta?.href) {
        ad.adItem.cta.popup
          ? (this.showModal = true)
          : window.open(ad.adItem.cta.href, 'LumeniiAdWindow', 'popup')
      } else if (ad.adItem.cta?.link) {
        this.$router.push(ad.adItem.cta.link)
      }
    },

    onCloseAds() {
      this.$emit('update:show', false)
    },

    getRandomSubarray(array, size) {
      const results = []
      const sampled = {}
      while (results.length < size && results.length < array.length) {
        const index = Math.trunc(Math.random() * array.length)
        if (!sampled[index]) {
          results.push(array[index])
          sampled[index] = true
        }
      }
      return results
    }
  }
}
</script>

<style lang="css" scoped>
.c-btn-close__modal {
  position: absolute;
  top: 8px;
  right: 8px;
}
</style>

<style lang="css">
.c-modal {
  background: var(--v-background-base);
  height: 75%;
  overscroll-behavior: contain;
  overflow-y: auto; /* enables overscroll setting */
}
.c-modal-content {
  width: 100%;
  height: 100%;
}
</style>
