<template>
  <div class="c-article-viewer d-flex flex-column align-center justify-start">
    <div class="c-fullwidth">
      <AdBlock
        v-if="hasTopAds"
        v-model="showTopAds"
        class="c-banner"
        :class="{ 'c-no-sides': !showSideAds }"
        :ads="showHeroAds ? heroAds : bannerAds"
        carousel
        :hero="showHeroAds"
      />
      <div class="d-flex align-stretch justify-center">
        <AdBlock
          v-model="showSideAds"
          class="c-side c-left-side"
          :ads="sideAds.slice().reverse()"
          stack
        />
        <div class="c-iframe d-flex align-center justify-center">
          <iframe
            ref="iframe"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            :src="src"
            :title="title"
            frameborder="0"
            width="100%"
            height="100%"
          />
        </div>
        <AdBlock
          v-model="showSideAds"
          class="c-side c-right-side"
          :ads="sideAds"
          stack
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import playerMixin from '@/mixins/playerMixin'
import AdBlock from '@/components/ad/AdBlock'

export default {
  name: 'ArticleViewer',

  components: {
    AdBlock
  },

  mixins: [playerMixin],

  props: {
    content: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {
      docHeight: 0,
      showHeroAds: false,
      showTopAds: false,
      showSideAds: false,
      src: this.content.contentURL,
      title: this.content.title
    }
  },

  computed: {
    ...mapGetters('adStore', ['getAds']),

    locale() {
      return this.$store.state.i18nStore.locale
    },

    ads() {
      return this.$store.state.adStore.ads
    },

    bannerAds() {
      return this.ads.length > 0 ? this.getAds('banner') : []
    },

    hasBannerAds() {
      return this.bannerAds.length > 0
    },

    heroAds() {
      return this.ads.length > 0 ? this.getAds('hero') : []
    },

    hasHeroAds() {
      return this.heroAds.length > 0
    },

    hasTopAds() {
      return this.hasHeroAds || this.hasBannerAds
    },

    sideAds() {
      return this.ads.length > 0 ? this.getAds('side') : []
    },

    hasSideAds() {
      return this.sideAds.length > 0
    }
  },

  watch: {
    ads: {
      immediate: true,
      handler: function (newAds, _oldAds) {
        if (newAds.length > 0) {
          // turn on the ad spots
          this.showTopAds = this.hasTopAds
          this.showSideAds = this.hasSideAds
          // support hero->banner transition
          this.showHeroAds = this.hasHeroAds
          if (this.hasHeroAds && this.hasBannerAds) {
            this.startAdTransition()
          }
        }
      }
    }
  },

  mounted: function () {
    this.getArticleHeight()
    this.$refs.iframe.addEventListener('load', this.getArticleHeight)
    this.showHeroAds = this.hasHeroAds
  },

  beforeDestroy: function () {
    this.$refs.iframe.removeEventListener('load', this.getArticleHeight)
  },

  methods: {
    getArticleHeight() {
      this.docHeight = this.contentWindow?.document?.body?.offsetHeight
      console.debug('docHeight=', this.docHeight)
    },

    startAdTransition() {
      setTimeout(() => {
        if (this.showHeroAds && this.hasBannerAds) {
          this.showHeroAds = false
        }
      }, 5000)
    }
  }
}
</script>

<style lang="css" scoped>
.c-fullwidth {
  width: 100%;
  max-width: 900px;
}
.c-banner {
  width: 100%;
}
.c-banner.c-no-sides {
  margin: auto;
}
.c-side {
  display: none;
  background-color: var(--v-background-base);
}
.c-iframe {
  min-height: calc(100vh - var(--c-player-bar-height, 56px));
  width: 100%;
}

@media only screen and (min-width: 900px) {
  .c-fullwidth {
    max-width: 1250px;
  }
  .c-banner.c-no-sides,
  .c-iframe {
    width: 75%;
  }
  .c-right-side {
    display: block;
    width: 25%;
  }
}

@media only screen and (min-width: 1500px) {
  .c-fullwidth {
    max-width: 1500px;
  }
  .c-banner.c-no-sides,
  .c-iframe {
    max-width: 900px;
    width: 60%;
  }
  .c-side {
    display: block;
    width: 20%;
  }
}
</style>
