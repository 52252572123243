<template>
  <v-card v-if="revealBack" class="c-card--back c-card--reveal transition-fast-in-fast-out">
    <!-- header -->
    <v-list-item>
      <!-- title -->
      <v-list-item-content class="align-self-start py-0">
        <v-card-title
          class="px-0 text-xs-caption text-sm-subtitle-2 text-md-subtitle-1 text-lg-h6 text-xl-h6"
        >
          {{ $t('post.aboutAuthor') }}
        </v-card-title>
      </v-list-item-content>
      <!-- close button -->
      <v-btn
        class="align-self-start mt-3"
        icon
        large
        color="teal accent-4"
        @click.stop="$emit('click:reveal', false)"
      >
        <v-icon> mdi-close </v-icon>
      </v-btn>
    </v-list-item>
    <v-card-text v-if="author">
      <PersonAvatar :person="author" />
      <ClampedPortableText class="mt-4" :localized-blocks="author.bio" />
    </v-card-text>
    <v-card-text v-if="sponsor">
      <CompanyAvatar :company="sponsor" />
      <ClampedPortableText class="mt-4" :localized-blocks="sponsor.bio" />
    </v-card-text>
  </v-card>
</template>

<script>
import ClampedPortableText from '@/components/base/ClampedPortableText'
import CompanyAvatar from '@/components/base/CompanyAvatar'
import PersonAvatar from '@/components/base/PersonAvatar'

export default {
  name: 'PostCardBack',

  components: {
    ClampedPortableText,
    CompanyAvatar,
    PersonAvatar
  },

  model: {
    prop: 'reveal',
    event: 'click:reveal'
  },

  props: {
    reveal: {
      type: Boolean,
      required: true
    },

    author: {
      type: Object,
      required: false,
      default: () => undefined
    },

    sponsor: {
      type: Object,
      required: false,
      default: () => undefined
    }
  },

  data: function () {
    return {
      revealBack: this.reveal
    }
  },

  watch: {
    reveal: {
      immediate: false, // initial value captured via data()
      handler: function (newVal, _oldVal) {
        this.revealBack = newVal
      }
    }
  }
}
</script>

<style lang="css" scoped>
.c-card--back {
  height: 100%;
  overflow-y: auto;
}
.c-card--reveal {
  top: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}

.v-card__text,
.v-card__title {
  word-break: normal;
  color: var(--text-primary);
}
</style>
