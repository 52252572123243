<template>
  <v-card class="c-card mx-auto">
    <!-- favourite -->
    <ClientFavourite
      :favourite="favourite"
      @add:favourite="addToFavourites(company.id)"
      @remove:favourite="removeFromFavourites(company.id)"
    />
    <v-card-title>
      <v-avatar class="ma-auto" color="indigo" fab :size="logoSize">
        <v-img v-if="company.logo" :src="company.logo" />
        <v-icon v-else>
          mdi-domain
        </v-icon>
      </v-avatar>
    </v-card-title>
    <v-list>
      <!-- name and rank -->
      <v-list-item>
        <v-list-item-icon align-self="center">
          <v-icon color="primary" large>
            mdi-domain
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="text-h4">
            {{ company.name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ company.industry }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <!-- call and text -->
      <v-list-item>
        <v-list-item-icon align-self="center">
          <v-icon color="accent" x-large @click="callCompany(company)">
            mdi-phone
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title
            class="text-sm-body-1 text-sm-subtitle-2 text-md-subtitle-1 text-lg-h6 text-xl-h5"
          >
            {{ company.phone }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Web site -->
      <v-list-item>
        <v-list-item-icon align-self="center">
          <v-icon color="primary" x-large>
            mdi-web
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title
            class="text-sm-body-1 text-sm-subtitle-2 text-md-subtitle-1 text-lg-h6 text-xl-h5"
          >
            <a :href="company.webSite" target="_blank">{{ company.webSite }}</a>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- address -->
      <v-list-item>
        <v-list-item-icon align-self="center">
          <v-icon color="accent" x-large @click="showMap(company)">
            mdi-map-marker
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title
            class="text-sm-body-1 text-sm-subtitle-2 text-md-subtitle-1 text-lg-h6 text-xl-h5"
          >
            {{ company.address }}
          </v-list-item-title>
          <v-list-item-subtitle
            class="text-sm-body-1 text-sm-subtitle-2 text-md-subtitle-1 text-lg-h6 text-xl-h5"
          >
            {{ company.city }} {{ company.province }}
          </v-list-item-subtitle>
          <v-list-item-subtitle
            class="text-sm-body-1 text-sm-subtitle-2 text-md-subtitle-1 text-lg-h6 text-xl-h5"
          >
            {{ company.country }} {{ company.postalCode }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import ClientFavourite from '@/components/client/ClientFavourite'
import { mapActions } from 'vuex'

export default {
  name: 'CompanyCard',

  components: {
    ClientFavourite
  },

  props: {
    company: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {
      id: this.company.id,
      isFavourite: false
    }
  },

  computed: {
    logoSize() {
      let size = '100px'
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          size = '100px'
          break
        case 'sm':
          size = '100px'
          break
        case 'md':
          size = '100px'
          break
        case 'lg':
          size = '125px'
          break
        case 'xl':
          size = '150px'
          break
      }
      return size
    },

    favourite() {
      return this.$store.state.userStore.companies.indexOf(this.id) > -1
    }
  },

  created: function () {
    this.isFavourite = this.favourite
  },

  methods: {
    ...mapActions('userStore', ['addToCompanies', 'removeFromCompanies']),
    /*
     * manage favourites
     */
    toggleFavourite() {
      this.isFavourite = !this.isFavourite
      this.isFavourite ? this.addToFavourites() : this.removeFromFavourites()
    },

    addToFavourites() {
      this.addToCompanies({ id: this.id })
      this.$emit('add:favourite')
    },

    removeFromFavourites() {
      this.removeFromCompanies({ id: this.id })
      this.$emit('remove:favourite')
    },

    /*
     * manage interactions
     */
    callCompany(_company) {},

    showMap(_company) {}
  }
}
</script>

<style lang="css" scoped>
.c-card {
  height: 100%;
  max-width: min(500px, 100%);
  border-radius: 16px;
}
</style>
