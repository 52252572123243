import { STORAGE_KEY } from '@/store'
import * as Storage from '@/store/storage.js'

const skipActions = ['syncState', 'setLoading']
const skipModules = ['adStore']

// function that creates a store plugin
export default function createStoragePlugin() {
  return (store) => {
    // called when the store is initialized
    store.subscribe((mutation, state) => {
      // called after every mutation
      // (the mutation comes in the format of `{ type, payload }`)
      const parts = mutation.type.split('/')
      const [module, action] = parts.length > 1 ? parts : ['root', parts[0]]

      // avoid persisting state:
      //   1)redundantly during session initialization ("syncState")
      //   2)for "loading" states ("setLoading")
      // FIXME: what about the migration scenario?
      if (skipActions.includes(action)) return
      if (skipModules.includes(module)) return

      // console.debug(`[store]: ${module}:${action} = ${mutation.payload}`)
      module === 'root'
        ? Storage.setItem(`${STORAGE_KEY}:version`, state.version)
        : Storage.setItem(`${STORAGE_KEY}:${module}`, JSON.stringify(state[module]))
    })
  }
}
