<template>
  <v-card class="py-2">
    <v-list three-line subheader>
      <v-card-actions class="c-actions px-0">
        <v-btn icon x-large @click.stop="showComments = !showComments">
          <v-icon large>
            mdi-comment-text-outline
          </v-icon>
        </v-btn>
        <span class="ml-2">{{ comments.length }} {{ $t('post.commentCount') }}</span>
        <v-btn class="ml-8" icon x-large @click.stop="">
          <v-icon large>
            mdi-thumb-up
          </v-icon>
        </v-btn>
        <span class="ml-2">{{ comments.length }} {{ $t('post.likeCount') }}</span>
        <v-spacer />
      </v-card-actions>
      <v-alert
        v-if="comments.length <= 0"
        :value="true"
        color="primary"
        icon="mdi-information"
        class="mx-3"
      >
        {{ $t('post.commentFirst') }}
      </v-alert>
      <v-list-group class="c-comment-group" append-icon="" no-action :value="showComments">
        <template v-for="(comment, i) in comments">
          <v-divider :key="i" inset />

          <v-list-item :key="comment.id">
            <v-list-item-avatar>
              <v-icon color="primary" large>
                mdi-account-circle-outline
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                {{ comment.username }}
              </v-list-item-title>

              <v-list-item-subtitle>
                {{ comment.content }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list-group>
    </v-list>
    <v-list class="py-0">
      <v-subheader>{{ $t('post.commentText') }}</v-subheader>
    </v-list>
    <v-form ref="commentForm" v-model="validComment" validation @submit.prevent="submitComment()">
      <v-container class="py-0" fluid>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="newComment.username"
              autocomplete="username"
              filled
              prepend-icon="mdi-account"
              :rules="nameRules"
              :counter="25"
              :label="$t('post.comment.username')"
              required
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="newComment.email"
              filled
              prepend-icon="mdi-at"
              :rules="emailRules"
              :label="$t('post.comment.email')"
              required
            />
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="newComment.content"
              filled
              prepend-icon="mdi-comment-text"
              :rules="commentRules"
              :label="$t('post.comment.text')"
              :counter="200"
              required
            />
            <div class="text-right">
              <v-btn
                type="submit"
                rounded
                depressed
                class="white--text"
                color="green lighten-1"
                :disabled="!validComment"
              >
                {{ $t('post.submitComment') }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>
import { appName } from '@/config/appConfig'
import { mapActions } from 'vuex'

export default {
  name: 'PostComments',

  components: {},

  props: {
    post: {
      type: Object,
      required: true
    },

    comments: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  data: function () {
    return {
      showComments: false,
      // comment form
      newComment: {
        username: '',
        email: '',
        content: ''
      },
      validComment: false,
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => v.length <= 25 || 'Name must be less than 25 characters'
      ],
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+/.test(v) || 'E-mail must be valid'
      ],
      commentRules: [
        (v) => !!v || 'Comment is required',
        (v) => v.length <= 200 || 'Comment must be less than 200 characters'
      ]
    }
  },

  computed: {},

  methods: {
    ...mapActions('postStore', ['postComment']),

    async submitComment() {
      try {
        if (this.$refs.commentForm.validate()) {
          await this.postComment({
            postId: this.post.id,
            username: this.newComment.username,
            email: this.newComment.email,
            content: this.newComment.content,
            approved: this.post.isModerated || true
          })

          this.$refs.commentForm.reset()
          this.newComment.username = ''
          this.newComment.email = ''
          this.newComment.content = ''
        }
      } catch (error) {
        console.error('[PostComments]:', error)
      }
    }
  },

  metaInfo() {
    return {
      title: this.post.title,
      titleTemplate: '%s | ' + appName
    }
  }
}
</script>

<style lang="css">
/* for some reason, can only be set globally */
.c-comment-group.v-list-group > .v-list-group__header {
  display: none !important;
}
</style>

<style lang="css" scoped>
.c-actions {
  font-size: 1.25rem;
}
.v-subheader {
  padding: 0px;
}
</style>
