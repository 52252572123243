<template>
  <v-card class="c-card mx-auto" justify-self="center">
    <!-- favourite -->
    <ClientFavourite
      :favourite="favourite"
      @add:favourite="addToFavourites(contact.id)"
      @remove:favourite="removeFromFavourites(contact.id)"
    />
    <!-- avatar -->
    <v-card-title>
      <v-avatar class="ma-auto" fab :size="avatarSize" color="indigo">
        <v-img v-if="contact.avatar" :src="contact.avatar" />
        <span v-else class="white--text text-h5">
          {{ contact.firstName[0] }}{{ contact.lastName[0] }}
        </span>
      </v-avatar>
    </v-card-title>
    <v-list>
      <!-- name and rank -->
      <v-list-item>
        <v-list-item-icon align-self="center">
          <a :href="contact.linkedin" target="_blank">
            <v-icon color="#0072b1" x-large>mdi-linkedin</v-icon>
          </a>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-subtitle class="text-h6">
            {{ contact.title }}
          </v-list-item-subtitle>
          <v-list-item-title class="text-h5">
            {{ contact.firstName }} {{ contact.lastName }}
          </v-list-item-title>
          <v-list-item-subtitle class="text-h6">
            {{ contact.jobTitle }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <!-- call and text -->
      <v-list-item>
        <v-list-item-icon align-self="center">
          <v-icon color="accent" x-large @click="callContact(contact)">
            mdi-phone
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title
            class="text-sm-body-1 text-sm-subtitle-2 text-md-subtitle-1 text-lg-h6 text-xl-h5"
          >
            {{ contact.phone }}
          </v-list-item-title>
        </v-list-item-content>

        <v-list-item-icon align-self="center">
          <v-icon color="accent" x-large @click="textContact(contact)">
            mdi-message-text
          </v-icon>
        </v-list-item-icon>
      </v-list-item>

      <!-- email and zoom -->
      <v-list-item>
        <v-list-item-icon align-self="center">
          <v-icon color="accent" x-large @click="emailContact(contact)">
            mdi-email
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title
            class="text-sm-body-1 text-sm-subtitle-2 text-md-subtitle-1 text-lg-h6 text-xl-h5"
          >
            {{ contact.email }}
          </v-list-item-title>
        </v-list-item-content>

        <v-list-item-icon align-self="center">
          <v-icon color="accent" x-large @click="zoomContact(contact)">
            mdi-video
          </v-icon>
        </v-list-item-icon>
      </v-list-item>

      <!-- address -->
      <v-list-item>
        <v-list-item-icon align-self="center">
          <v-icon color="accent" x-large @click="showMap(contact)">
            mdi-map-marker
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title
            class="text-sm-body-1 text-sm-subtitle-2 text-md-subtitle-1 text-lg-h6 text-xl-h5"
          >
            {{ contact.address }}
          </v-list-item-title>
          <v-list-item-subtitle
            class="text-sm-body-1 text-sm-subtitle-2 text-md-subtitle-1 text-lg-h6 text-xl-h5"
          >
            {{ contact.city }} {{ contact.province }}
          </v-list-item-subtitle>
          <v-list-item-subtitle
            class="text-sm-body-1 text-sm-subtitle-2 text-md-subtitle-1 text-lg-h6 text-xl-h5"
          >
            {{ contact.country }} {{ contact.postalCode }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import ClientFavourite from '@/components/client/ClientFavourite'
import { mapActions } from 'vuex'

export default {
  name: 'ContactCard',

  components: {
    ClientFavourite
  },

  props: {
    contact: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {
      id: this.contact.id,
      isFavourite: false
    }
  },

  computed: {
    avatarSize() {
      const sizes = {
        xs: 100,
        sm: 100,
        md: 100,
        lg: 125,
        xl: 150
      }
      return sizes[this.$vuetify.breakpoint.name] + 'px' || '100px'
    },

    favourite() {
      return this.$store.state.userStore.contacts.indexOf(this.id) > -1
    }
  },

  created: function () {
    this.isFavourite = this.favourite
  },

  methods: {
    ...mapActions('userStore', ['addToContacts', 'removeFromContacts']),

    /*
     * manage favourites
     */
    toggleFavourite() {
      this.isFavourite = !this.isFavourite
      this.isFavourite ? this.addToFavourites() : this.removeFromFavourites()
    },

    addToFavourites() {
      this.addToContacts({ id: this.id })
      this.$emit('add:favourite')
    },

    removeFromFavourites() {
      this.removeFromContacts({ id: this.id })
      this.$emit('remove:favourite')
    },

    /*
     * manage interactions
     */
    bookContact(_contact) {},

    emailContact(_contact) {},

    callContact(_contact) {},

    textContact(_contact) {},

    zoomContact(_contact) {},

    showMap(_contact) {}
  }
}
</script>

<style scoped>
.c-card {
  height: 100%;
  max-width: min(500px, 100%);
  border-radius: 16px;
}
</style>
