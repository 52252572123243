import layoutMixin from '@/mixins/layoutMixin.js'

// layouts
import AppLayout from '@/layouts/AppLayout'
import PageLayout from '@/layouts/PageLayout'

export class LayoutPlugin {
  static install(Vue, _options) {
    Vue.component('AppLayout', AppLayout)
    Vue.component('PageLayout', PageLayout)
    Vue.mixin(layoutMixin)
  }
}
