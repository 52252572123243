const enableAll = process.env.VUE_APP_ENABLE_ALL_FEATURES === 'true'

export const features = {
  pwa: false,
  accountProfile: false,
  accountSettings: false,
  notifications: false,
  advancedDrawTools: false,
  drawMode: false
}

export const getFeature = (name) => {
  return enableAll || features[name]
}
