import { VTooltip, VPopover, VClosePopover } from 'v-tooltip'
export class TooltipPlugin {
  static install(Vue, _options) {
    Vue.use(VTooltip)

    // eslint-disable-next-line
    Vue.component('v-popover', VPopover)
    Vue.directive('v-tooltip', VTooltip)
    Vue.directive('v-close-popover', VClosePopover)
  }
}
