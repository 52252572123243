<template>
  <v-card class="pl-0 d-flex justify-left flex-wrap">
    <v-avatar v-if="person.avatar" class="ml-0 mr-4 c-person-avatar">
      <v-img :lazy-src="require('@/assets/images/icons/avatar.png')" :src="person.avatar" />
    </v-avatar>
    <v-avatar v-else class="ml-0 mr-4 c-person-avatar" color="purple">
      <span class="white--text text-h5">
        {{ personInitials }}
      </span>
    </v-avatar>
    <v-card-title class="pl-0 py-0 c-person-name">
      {{ personName }}
    </v-card-title>
  </v-card>
</template>

<script>
export default {
  name: 'PersonAvatar',

  components: {},

  props: {
    person: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {}
  },

  computed: {
    personInitials() {
      return (this.person.firstName?.[0] || '?') + (this.person.lastName?.[0] || '?')
    },

    personName() {
      return (
        this.person.displayName ||
        (this.person.honourific
          ? `${this.person.honourific} ${this.person.fullName} ${this.person.lastName}`
          : `${this.person.fullName} ${this.person.lastName}`)
      )
    }
  }
}
</script>

<style lang="css" scoped>
.c-person-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 72px !important;
  width: 72px !important;
  min-width: 72px !important;
}
.c-person-name {
  font-size: 1.25rem;
}
</style>
