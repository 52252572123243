<template>
  <div>
    <div ref="text" :class="{'c-clamp': isClamped}">
      <PortableText
        :blocks="blockArray"
        :serializers="blockOptions.serializers"
        :project-id="blockOptions.projectId"
        :dataset="blockOptions.dataset"
      />
    </div>
    <v-btn v-if="isExtendable" small text @click.stop="isClamped = !isClamped">
      {{ isClamped ? $t('ui.more') : $t('ui.less') }}
    </v-btn>
  </div>
</template>

<script>
import blocksToHTML from '@sanity/block-content-to-html'
import PortableText from 'sanity-blocks-vue-component'

export default {
  name: 'ClampedPortableText',

  components: {
    PortableText
  },

  props: {
    blocks: {
      type: Array,
      required: false,
      default: () => []
    },

    localizedBlocks: {
      type: Object, // block array is stored inside a localized object
      required: false,
      default: null
    }
  },

  data: function () {
    return {
      isExtendable: false,
      isClamped: true
    }
  },

  computed: {
    /* state */

    defaultLocale() {
      return this.$store.state.i18nStore.defaultLocale
    },

    locale() {
      return this.$store.state.i18nStore.locale
    },

    tenant() {
      return this.$auth.tenant
    },

    /* portable text processing */

    blockOptions() {
      return {
        serializers: this.serializers,
        projectId: this.tenant.cms.projectId,
        dataset: this.tenant.cms.dataset,
        useCdn: true
      }
    },

    serializers() {
      return {
        types: {}
      }
    },

    toHTML() {
      return blocksToHTML({ blocks: this.blockArray })
    },

    toText() {
      const text = []
      this.blockArray[0].children?.forEach((child) => text.push(child.text))
      return text.join('')
    },

    /* portable text blocks */

    blockArray() {
      return this.localizedBlocks ? this.translatedBlocks : this.blocks || []
    },

    translatedBlocks() {
      return this.localizedBlocks[this.locale] || this.localizedBlocks[this.defaultLocale] || []
    }
  },

  mounted: function () {
    this.$nextTick(() => {
      this.isExtendable = this.checkOverflow(this.$refs.text)
      return true
    })

    window.addEventListener('resize', this.onResize)
  },

  beforeDestroy: function () {
    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    checkOverflow(el) {
      const overflow = el.style.overflow

      // if visible, set overflow style to hidden
      if (!overflow || overflow === 'visible') el.style.overflow = 'hidden'

      // check if element is overflowing
      const isExtendable = el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight

      // restore original overflow style setting
      el.style.overflow = overflow

      return isExtendable
    },

    onResize() {
      this.isExtendable = this.checkOverflow(this.$refs.text)
    }
  }
}
</script>

<style lang="css" scoped>
.c-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.c-clamp > p {
  margin-bottom: 0;
}
</style>

