<template>
  <v-main
    id="main"
    class="c-main"
    :style="mainStyle"
  >
    <v-container fluid class="c-container">
      <transition name="fade" mode="out-in" @after-leave="afterLeave">
        <keep-alive :include="included">
          <router-view
            :key="$route.path"
            @count="$emit('count', $event)"
            @title="$emit('title', $event)"
          />
        </keep-alive>
      </transition>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: 'AppMain',

  components: {},

  data: function () {
    return {
      included: [
        'about',
        'channel',
        'channels',
        'clients',
        'companies',
        'contacts',
        'collection',
        'help',
        'library',
        'persons',
        'posts'
      ]
    }
  },

  computed: {
    isReallyMobile() {
      return this.$_layoutMixin_isReallyMobile
    },

    mainStyle() {
      const bottomHeight = this.isReallyMobile
        ? 'var(--c-bottom-bar-height, 56px)'
        : 'var(--c-footer-height, 0px)'
      return {
        minHeight: `calc(100vh - ${bottomHeight}`,
        paddingLeft: `${this.$appConfig.leftMargin}px !important`
      }
    }
  },

  methods: {
    afterLeave() {
      this.$root.$emit('triggerScroll')
    }
  }
}
</script>

<style lang="css" scoped>
.c-container {
  padding: 0;
  height: 100%;
}

.c-main {
  padding: 0;
  height: 100%;
  background-color: var(--v-sheet-base);
}

/* fade transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* slide transition */
.slide-leave-active,
.slide-enter-active {
  transition: 0.1s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(-100%, 0);
}
</style>
