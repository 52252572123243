import { LogLevel, PublicClientApplication } from '@azure/msal-browser'

// Active Directory settings
const adAuthority = process.env.VUE_APP_MSAL_AD_AUTHORITY
const adTenant = process.env.VUE_APP_MSAL_AD_TENANT
const clientId = process.env.VUE_APP_MSAL_CLIENT_ID

const policies = {
  b2cSignUpAndSignIn: 'B2C_1_SignUpAndSignIn',
  b2cSignUp: 'B2C_1_SignUp',
  b2cSignIn: 'B2C_1_SignIn',
  b2cResetPassword: 'B2C_1_ResetPassword',
  b2cEditProfile: 'B2C_1_EditProfile'
}

export const authorities = {
  b2cSignUpAndSignIn: `${adAuthority}/${adTenant}/${policies.b2cSignUpAndSignIn}`,
  b2cSignUp: `${adAuthority}/${adTenant}/${policies.b2cSignUp}`,
  b2cSignIn: `${adAuthority}/${adTenant}/${policies.b2cSignIn}`,
  b2cResetPassword: `${adAuthority}/${adTenant}/${policies.b2cResetPassword}`,
  b2cEditProfile: `${adAuthority}/${adTenant}/${policies.b2cEditProfile}`
}

function loggerCallback(logLevel, message, containsPII) {
  if (containsPII) {
    return
  }
  switch (logLevel) {
    case LogLevel.Error:
      console.error(message)
      return
    case LogLevel.Info:
      console.info(message)
      return
    case LogLevel.Verbose:
      console.debug(message)
      return
    case LogLevel.Warning:
      console.warn(message)
  }
}

/*
 * https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/modules/_src_config_configuration_.html
 */
const msalConfig = {
  auth: {
    clientId: clientId,
    authority: authorities.b2cSignUpAndSignIn,
    knownAuthorities: [
      authorities.b2cSignUpAndSignIn,
      authorities.b2cSignUp,
      authorities.b2cSignIn,
      authorities.b2cResetPassword,
      authorities.b2cEditProfile
    ],
    cloudDiscoveryMetadata: '',
    redirectUri: `${window.location.origin}/`, // after login, navigate back to root of SPA
    postLogoutRedirectUri: `${window.location.origin}/logout`,
    navigateToLoginRequestUrl: false, // do not redirect back to the url that issued loginRedirect
    clientCapabilities: ['CP1']
  },
  cache: {
    cacheLocation: 'localStorage', // enables session to be reused across tabs
    storeAuthStateInCookie: false
  },
  system: {
    loggerOptions: {
      loggerCallback: loggerCallback,
      piiLoggingEnabled: true,
      logLevel: LogLevel.Warning
    },
    windowHashTimeout: 60000,
    iframeHashTimeout: 6000,
    loadFrameTimeout: 0,
    asyncPopups: false
  }
}

// MSAL Reference
// https://azuread.github.io/microsoft-authentication-library-for-js/ref/modules/_azure_msal_browser.html
export const msalAgent = new PublicClientApplication(msalConfig)
export const authStyle = process.env.VUE_APP_AUTH_STYLE || 'popup'
