export default {
  computed: {
    $_layoutMixin_isDark() {
      return this.$store.state.themeStore.isDark
    },

    $_layoutMixin_isMobile() {
      return this.$vuetify.breakpoint.mobile
    },

    $_layoutMixin_isReallyMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        window.navigator.userAgent
      )
    },

    $_layoutMixin_isAndroid() {
      return /Android/i.test(window.navigator.userAgent)
    },

    $_layoutMixin_isiOS() {
      return /iPhone|iPad|iPod/i.test(window.navigator.userAgent)
    },

    $_layoutMixin_browserName() {
      const test = (regexp) => regexp.test(window.navigator.userAgent)
      switch (true) {
        case test(/edg/i):
          return 'Edge'
        case test(/trident/i):
          return 'IE'
        case test(/firefox|fxios/i):
          return 'Firefox'
        case test(/opr\//i):
          return 'Opera'
        case test(/ucbrowser/i):
          return 'UC Browser'
        case test(/samsungbrowser/i):
          return 'Samsung Browser'
        case test(/chrome|chromium|crios/i):
          return 'Chrome'
        case test(/safari/i):
          return 'Safari'
        default:
          return 'Other'
      }
    }
  },

  methods: {
    $_layoutMixin_calculateScrollbarSize(element) {
      // console.debug('window.innerWidth=', window.innerWidth)
      // console.debug('document.body.clientWidth=', document.body.clientWidth)
      const el = element || this.$el
      const borderWidth =
        parseInt(getComputedStyle(el).borderLeftWidth, 10) +
        parseInt(getComputedStyle(el).borderRightWidth, 10)
      const borderHeight =
        parseInt(getComputedStyle(el).borderTopWidth, 10) +
        parseInt(getComputedStyle(el).borderBottomWidth, 10)

      // offset dimensions include border and scrollbar (whereas client dimensions do not)
      const scrollbarWidth = el.offsetWidth - el.clientWidth - borderWidth
      const scrollbarHeight = el.offsetHeight - el.clientHeight - borderHeight

      return {
        width: scrollbarWidth,
        height: scrollbarHeight
      }
    }
  }
}
