import store from '@/store'

export const fetchAds = async ({ locale, keywords, zone }) => {
  const tenantKey = store.state.tenantStore.tenantKey

  // create the query string
  const params = {
    locale,
    keywords: (keywords && keywords.join(',')) || '',
    zone
  }
  const esc = encodeURIComponent
  const query = Object.keys(params)
    .filter((k) => !!params[k])
    .map((k) => `${k}=${esc(params[k])}`)
    .join('&')

  const queryString = query.length > 0 ? `?${query}` : ''

  try {
    const response = await fetch(`/api/tenant/${tenantKey}/ads${queryString}`)

    if (response.ok) {
      return response.json()
    } else {
      throw new Error(response.statusText)
    }
  } catch (error) {
    console.error('[adService]:', error)
    return []
  }
}
