import tools from '@/services/tools'
export default class DrawService {
  constructor(context) {
    // initialize canvas
    this.mainContext = context.mainContext
    this.tempContext = context.tempContext

    // initialize canvas offsets
    this.offsetX = context.offsetX
    this.offsetY = context.offsetY

    // initialize draw handler
    this.activeHandler = tools[context.activeShape].handler
    this.activeShape = context.activeShape
    this.isEraseMode = context.isEraseMode ?? false
    this.isTextMode = context.isTextMode ?? false
    this.options = context.options
    this.setActiveShape(this.activeShape)

    // redraw (for undo, redo and resize)
    this.canvasHeight = this.mainContext.canvas.height
    this.canvasWidth = this.mainContext.canvas.width
    this.position = 0
    this.historyLog = []
  }

  // context controls

  setActiveShape(shape) {
    this.activeShape = shape
    this.activeHandler = tools[this.activeShape].handler
    this.options = { ...this.options, ...{ shape: this.activeShape } }

    this.isTextMode = false

    this.activeHandler.configure({
      mainContext: this.mainContext,
      tempContext: this.tempContext,
      offsetX: this.offsetX,
      offsetY: this.offsetY,
      options: this.options,
      isEraseMode: this.isEraseMode
    })
  }

  setDimensions(width, height) {
    this.canvasWidth = width
    this.canvasHeight = height
  }

  setEraseMode(isEraseMode) {
    this.isEraseMode = isEraseMode
    if (this.isEraseMode) this.isTextMode = false
    this.activeHandler.setEraseMode(isEraseMode)
  }

  setTextMode(isTextMode, parentElement) {
    this.isTextMode = isTextMode

    isTextMode
      ? (this.activeHandler = tools[tools.text.name].handler)
      : (this.activeHandler = tools[this.activeShape].handler)

    this.activeHandler.configure({
      mainContext: this.mainContext,
      tempContext: this.tempContext,
      offsetX: this.offsetX,
      offsetY: this.offsetY,
      options: this.options,
      isEraseMode: this.isEraseMode,
      parentElement: parentElement
    })
  }

  updateOptions(updatedOptions = {}) {
    this.options = { ...this.options, ...updatedOptions }
    this.activeHandler.updateOptions(this.options)
  }

  // actions

  clear(isSilent = true) {
    this.mainContext.clearRect(0, 0, this.mainContext.canvas.width, this.mainContext.canvas.height)
    if (!isSilent) {
      this.historyLog.length = this.position
      if (this.historyLog[this.position - 1].tool === 'clear') {
        // two sequential clear requests wipe out all history
        this.position = 0
        this.historyLog.length = this.position
      } else {
        // add the clear action to the history log
        this.historyLog.push({
          tool: 'clear'
        })
        this.position += 1
      }
    }
  }

  // redraw management

  redo() {
    if (this.position < this.historyLog.length) {
      this.position += 1
      this.redraw(this.position)
    }
  }

  undo() {
    if (this.position > 0) {
      this.position -= 1
      this.redraw(this.position)
    }
  }

  redraw(position = this.historyLog.length) {
    this.clear(true)
    for (let i = 0; i < position; ++i) {
      // extract history log entry
      const action = this.historyLog[i]
      if (action.tool === 'clear') {
        this.clear(true)
      } else {
        const tool = action.tool
        const handler = tools[tool].handler

        // render
        const ctx = Object.assign(this.mainContext, action.attributes)
        const fill = action.isEraseMode || false
        const segments = action.segments
        const text = action.text || ''

        handler.redraw({ ctx, fill, segments, text })
      }
    }
  }

  // drawing events

  mousedown(e) {
    this.isDrawing = true
    this.historyLog.length = this.position
    this.activeHandler.mousedown(e)
  }

  mousemove(e) {
    if (this.isDrawing) {
      this.activeHandler.mousemove(e)
    }
  }

  mouseup(e) {
    if (this.isDrawing) {
      const logRecord = this.activeHandler.mouseup(e)

      this.historyLog.push(logRecord)
      this.position = this.historyLog.length

      this.isDrawing = false
    }
  }
}
