<template>
  <div>
    <div
      ref="contentPlayer"
      class="c-content-player d-flex justify-center"
      :class="{'c-fullscreen': isFullscreen }"
    >
      <component
        :is="player"
        class="c-content-player__component"
        :class="[{ 'c-content-player__ads': showSideAds }, useMarginForAds ? 'c-margin' : 'c-padding']"
        :content="item"
        :fullscreen="isFullscreen"
        @context="$emit('context', $event)"
        @error:handled="$emit('error')"
        @error:loading="onError"
      />
      <AdBlock
        v-model="showSideAds"
        class="c-side"
        :ads="sideAds"
        stack
      />
    </div>
    <DrawCanvas
      class="c-content-player__canvas"
      :activated="isCanvasActivated"
      :type="item.mediaType"
      @capture="capture"
      @download="download"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AdBlock from '@/components/ad/AdBlock'
import DrawCanvas from '@/components/draw/DrawCanvas'
import Players from '@/players/playerRegistry.json'
import { toPng } from 'html-to-image'

export default {
  name: 'ContentPlayer',

  components: {
    AdBlock,
    DrawCanvas
  },

  props: {
    item: {
      type: Object,
      required: true
    },

    canvas: {
      type: Boolean,
      required: false,
      default: false
    },

    fullscreen: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: function () {
    return {
      // canvas
      canvasHeight: 0,
      canvasWidth: 0,
      isCanvasActivated: false,
      wasCanvasActivated: false,
      offsetX: 0,
      offsetY: 0,

      // content
      context: {},
      showSideAds: false,

      // preview
      previewSrc: '',
      showPreview: false
    }
  },

  computed: {
    ...mapGetters('adStore', ['getAds']),

    isAdFree() {
      return (
        this.item.adFree === true ||
        this.item.mediaType === 'article' || // articles have their own ad layout
        this.item.contentTypeKeyword === 'detail-aid' ||
        this.item.contentTypeKeyword === 'learning-aid' ||
        this.item.contentTypeKeyword === 'cme'
      )
    },

    isFullscreen() {
      return this.fullscreen
    },

    player() {
      return this.getPlayer(this.item.mediaType)
    },

    useMarginForAds() {
      return Players.find((player) => player.mediaType === this.item.mediaType)?.useMarginForAds
    },

    sideAds() {
      // note: accesses adStore.ads to retain reactivity
      const ads = this.$store.state.adStore.ads
      return ads.length > 0 ? this.getAds('side') : []
    },

    hasSideAds() {
      return !this.isAdFree && this.sideAds.length > 0
    }
  },

  watch: {
    canvas: {
      handler: function (newValue, _oldValue) {
        this.isCanvasActivated = newValue
      }
    },

    fullscreen: {
      immediate: false,
      handler: function (newValue, _oldValue) {
        const isFullscreen = newValue
        if (isFullscreen) {
          this.wasCanvasActivated = this.isCanvasActivated
          this.isCanvasActivated = false
        } else {
          this.isCanvasActivated = this.wasCanvasActivated
        }
      }
    },

    sideAds: {
      immediate: true,
      handler: function (newSideAds, _oldSideAds) {
        this.showSideAds = !this.isAdFree && newSideAds.length > 0
      }
    }
  },

  created: function () {
    this.showSideAds = !this.isAdFree && this.sideAds.length > 0
  },

  mounted: function () {
    console.debug('[ContentPlayer]: params=', JSON.stringify(this.item.params || {}))
    this.isCanvasActivated = this.canvas
  },

  methods: {
    async onError() {
      await this.showAlert()
      this.$emit('error')
    },

    getPlayer(mediaType) {
      const player = Players.find((player) => player.mediaType === mediaType)?.player || 'WebViewer'
      return () => import(/* webpackChunkName: "[request]" */ `@/players/${player}/index.js`)
    },

    async showAlert() {
      const result = await this.$alert({
        icon: 'error',
        title: this.$t(`error.LoadingError.title`),
        text: this.$t(`error.LoadingError.message`),
        confirmButtonText: this.$t(`ui.close`)
      })
      if (result.isConfirmed) {
        return true
      } else {
        return false
      }
    },

    async capture() {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      const video = document.createElement('video')

      /* capture screen */
      try {
        // start RTC video stream
        const captureStream = await navigator.mediaDevices.getDisplayMedia()
        video.srcObject = captureStream

        // capture an data image of the video frame
        context.drawImage(video, 0, 0, window.width, window.height)
        const dataURI = canvas.toDataURL('image/png')
        console.log('dataURI=', dataURI)

        // create a blob from the data image
        const dataBlob = await (await fetch(dataURI)).blob()
        console.log('blob=', dataBlob)

        // close the RTC video stream
        captureStream.getTracks().forEach((track) => track.stop())

        // paste the blob into the clipboard and download it
        navigator.clipboard.write([new window.ClipboardItem({ 'image/png': dataBlob })])
        window.saveAs(dataBlob, 'lumenii.png')

        // render the img in a preview popup
        this.previewSrc = dataURI
        this.showPreview = true
      } catch (error) {
        console.error('[DrawCanvas]: Error capturing screen.', error)
      }
    },

    async download() {
      // generate a DOM image
      try {
        const dataURI = await toPng(this.$refs.contentPlayer)
        this.previewSrc = dataURI
      } catch (error) {
        console.error('[ContentPlayer]: Oops, something went wrong!', error)
      }

      // render the img in a preview popup
      this.showPreview = true

      // download image
      const link = document.createElement('a')
      link.download = 'lumenii.png'
      link.href = this.previewSrc
      link.click()
    }
  }
}
</script>

<style lang="css" scoped>
/* Layers:
 *  -1: Drawing Canvas (inactive)
 *   0: Player Viewport
 *   1: Drawing Canvas (active)
 *   2: Content Details Drawer
 *   3: Header Bar
 *   3: Controls
 */

.c-content-player {
  min-height: calc(100vh - var(--c-player-bar-height, 56px));
  overflow: auto;
  width: 100%;
}

.c-content-player .c-content-player__component {
  background-color: var(--v-sheet-base);
  min-height: calc(100vh - var(--c-player-bar-height, 56px));
  width: 100%;
  height: 100%;
  z-index: 0;
}

.c-content-player.c-fullscreen .c-content-player__component {
  min-height: 100vh;
  background-color: black;
}

.c-preview {
  position: absolute;
  top: 10%;
  left: 10%;
  border: red solid 2px;
  width: min(80%, 400px);
  height: min(80%, 300px);
}

.c-side {
  display: none;
  position: fixed;
  right: 0px;
  background-color: var(--v-background-base);
  width: 100%;
  max-width: 300px;
  min-height: calc(100vh - var(--c-player-bar-height, 56px));
}

@media only screen and (min-width: 1000px) {
  .c-content-player .c-content-player__ads.c-margin {
    margin-right: 300px; /* used for position: absolute */
  }

  .c-content-player .c-content-player__ads.c-padding {
    padding-right: 300px; /* used for position: relative, static */
  }

  .c-side {
    display: block;
  }
}
</style>
