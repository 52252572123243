<template>
  <v-bottom-navigation
    v-model="currentItem"
    app
    background-color="background"
    color="accent"
    grow
    height="56"
    :input-value="show"
  >
    <v-btn
      v-for="item in baseMenuItems"
      :key="item.key"
      :to="to(item)"
      :value="item.key"
      @click="selectBaseItem(item)"
    >
      <v-icon x-large>
        {{ item.icon }}
      </v-icon>
      <span v-if="item.translated">{{ item.title[locale] || item.title['en'] }}</span>
      <span v-else>{{ $t(`${item.title}`) }}</span>
    </v-btn>

    <v-btn v-if="hasExtensions" :input-value="extensionItem">
      <v-speed-dial
        v-model="showExtensionMenu"
        direction="top"
        open-on-hover
        transition="slide-y-reverse-transition"
      >
        <template #activator>
          <!-- using .stop on the activator button keeps the v-speed-dial open -->
          <v-btn class="" block plain @click="showExtensionMenu = !showExtensionMenu">
            <v-icon :color="extensionItem ? 'accent' : ''" x-large>
              mdi-dots-vertical
            </v-icon>
          </v-btn>
        </template>
        <v-btn
          v-for="item in extensionMenuItems"
          :key="item.key"
          class="mx-0 my-2"
          :to="to(item)"
          :value="item.key"
          @click="selectExtensionItem(item)"
        >
          <v-icon
            :class="[extensionItem === item.key ? 'c-btn--active' : 'c-btn--inactive']"
            x-large
          >
            {{ item.icon }}
          </v-icon>
          <span v-if="item.translated">{{ item.title[locale] || item.title['en'] }}</span>
          <span v-else>{{ $t(`${item.title}`) }}</span>
        </v-btn>
      </v-speed-dial>
      <span>{{ $t('ui.more') }}</span>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
export default {
  name: 'AppBottomBar',

  components: {},

  props: {
    show: {
      type: Boolean,
      default: true
    }
  },

  data: function () {
    return {
      currentItem: null,
      extensionItem: null,
      baseItem: null,

      // speed dial
      showExtensionMenu: false
    }
  },

  computed: {
    allItems() {
      const priorityItems = this.menuItems.filter((item) => item.priority)
      const secondaryItems = this.menuItems.filter((item) => !item.priority)
      return [...priorityItems, ...secondaryItems]
    },

    baseMenuItems() {
      return this.allItems.slice(0, this.itemCapacity)
    },

    menuItems() {
      return this.$store.state.menuStore.menuItems
    },

    currentRouteName() {
      return this.$route.name
    },

    extensionMenuItems() {
      return this.allItems.slice(this.itemCapacity)
    },

    hasExtensions() {
      return this.extensionMenuItems.length > 0
    },

    itemCapacity() {
      const capacity = {
        xs: 3,
        sm: 4,
        md: 8,
        lg: 10,
        xl: 12
      }

      return capacity[this.$vuetify.breakpoint.name] || this.allItems.length
    },

    locale() {
      return this.$store.state.i18nStore.locale
    }
  },

  created: function () {
    this.currentItem = this.currentRouteName
  },

  methods: {
    selectBaseItem(item) {
      this.baseItem = item.key
      this.extensionItem = null
    },

    selectExtensionItem(item) {
      this.baseItem = null
      this.extensionItem = item.key
    },

    toggleExtensionMenu() {
      this.showExtensionMenu = !this.showExtensionMenu
    },

    to(item) {
      return item.to ? item.to : { name: item.route }
    }
  }
}
</script>

<style lang="css" scoped>
.c-btn--active {
  color: var(--v-accent-base) !important;
}
</style>
