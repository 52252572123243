<template>
  <PortableText
    v-if="copy"
    class-name="c-portable"
    :blocks="copy"
    :serializers="blockOptions.serializers"
    :project-id="blockOptions.projectId"
    :dataset="blockOptions.dataset"
  />
</template>

<script>
import blocksToHTML from '@sanity/block-content-to-html'
import PortableText from 'sanity-blocks-vue-component'

// portableText serializers
import AudioBlock from '@/components/post/AudioBlock'
import VideoBlock from '@/components/post/VideoBlock'
import YouTubeBlock from '@/components/post/YouTubeBlock'

export default {
  name: 'AdCopy',

  components: {
    PortableText
  },

  props: {
    copy: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  data: function () {
    return {}
  },

  computed: {
    blockOptions() {
      return {
        serializers: this.serializers,
        projectId: this.tenant.cms.projectId,
        dataset: this.tenant.cms.dataset,
        useCdn: true
      }
    },

    serializers() {
      return {
        types: {
          audio: AudioBlock,
          video: VideoBlock,
          youtube: YouTubeBlock
        }
      }
    },

    tenant() {
      return this.$auth.tenant
    }
  },

  methods: {
    toHTML(copyBlocks) {
      return blocksToHTML({
        blocks: copyBlocks,
        serializers: {
          types: {
            audio: AudioBlock,
            video: VideoBlock,
            youtube: YouTubeBlock
          }
        },
        projectId: this.tenant.cms.projectId,
        dataset: this.tenant.cms.dataset,
        useCdn: true
      })
    }
  }
}
</script>

<style lang="css" scoped></style>
