<template>
  <div class="c-web-viewer c-frame-wrapper d-flex align-center justify-center">
    <iframe
      ref="iframe"
      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
      :src="src"
      :title="title"
      frameborder="0"
      width="100%"
      height="100%"
    />
  </div>
</template>

<script>
import playerMixin from '@/mixins/playerMixin'

export default {
  name: 'WebViewer',

  components: {},

  mixins: [playerMixin],

  props: {
    content: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {
      src: this.content.contentURL,
      title: this.content.title || 'Untitled'
    }
  }
}
</script>

<style lang="css" scoped>
.c-frame-wrapper {
  position: relative;
  height: 0;
  padding-top: 75%;
  overflow: hidden;
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
  background-color: #0c0f0e;
}

@media (orientation: landscape) {
  .c-frame-wrapper {
    padding-top: 56.25%; /* 9 / 16 */
  }
}
@media (orientation: portrait) {
  .c-frame-wrapper {
    padding-top: 75%; /* 3 / 4 */
  }
}
</style>
