export const fetchTenants = async () => {
  try {
    const response = await fetch(`/api/tenants`)
    if (response.ok) {
      return response.json()
    } else {
      throw new Error(response.statusText)
    }
  } catch (error) {
    console.error('[tenantService]:', error)
    return []
  }
}

export const fetchTenant = async (tenantKey) => {
  try {
    const response = await fetch(`/api/tenant/${tenantKey}`)
    if (response.ok) {
      return response.json()
    } else {
      throw new Error(response.statusText)
    }
  } catch (error) {
    console.error('[tenantService]:', error)
    return {}
  }
}
