// https://sweetalert2.github.io/
import Swal from 'sweetalert2'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import Vue from 'vue'

const $translate = (msg, ...args) => Vue.prototype.$_i18n.t(msg, ...args)

// global styling classes (g-*) are defined in App.vue

const Alert = Swal.mixin({
  allowEscapeKey: false,
  allowOutsideClick: false,
  background: 'var(--v-background-base)',
  customClass: {
    popup: 'g-alert-popup',
    title: 'g-alert-title',
    footer: 'g-alert-footer',
    htmlContainer: 'g-alert-body',
    actions: 'g-alert-actions',
    confirmButton: 'g-alert-confirm',
    cancelButton: 'g-alert-cancel',
    denyButton: 'g-alert-deny'
  }
})

/*
    // Usage:
    this.$alert({
      icon: 'error' | 'warning' | 'info' | 'success',
      title: <titleText>,
      text: <detailsText>,
      footer: <auxillaryTextOrHTML>
      showConfirmButton: true,
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: <buttonText>,
      denyButtonText: <buttonText>,
      cancelButtonText: <buttonText>
      ...
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('Saved!', '', 'success')
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info')
      } else if (result.dismissed) {
        console.debug('Alert dismissed.')
      }
*/

const Toast = Swal.mixin({
  toast: true,
  background: 'var(--v-background-base)',
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

/*
    // Usage:
    this.$toast({
      icon: 'success',
      title: 'Signed in successfully!'
    })
*/

const exit = () => {
  // close the window (but first, ensure the app owns the window)
  const url = `${window.location.origin}/splash#notice=exit`
  const w = open(url, '_self')
  w.close()
  // return false if window didn't close
  return false
}

const showSystemError = async (error) => {
  const code = error.name === 'Error' ? 'SystemError' : error.name || 'SystemError'
  const title = $translate(`error.${code}.title`)
  const message = $translate(`error.${code}.message`)
  const iconKey = $translate(`error.${code}.icon`)
  const icon = iconKey.indexOf('.') >= 0 ? 'error' : iconKey

  const result = await Alert.fire({
    icon: icon,
    title: title,
    text: message,
    footer: error,
    showConfirmButton: true,
    confirmButtonText: $translate('ui.retry'),
    showDenyButton: true,
    denyButtonText: $translate('ui.exit')
  })

  if (result.isConfirmed) {
    // restart the app in the same browser window
    open(window.location.origin, '_self')
  } else if (result.isDenied) {
    // attempt to close the browser window
    exit()
  }
}

export class AlertPlugin {
  static install(Vue, _options) {
    Vue.use(VueSweetalert2) // this.$swal
    Vue.prototype.$alert = (options) => Alert.fire(options)
    Vue.prototype.$toast = (options) => Toast.fire(options)
    Vue.prototype.$error = (error, vm) => showSystemError(error, vm)
    Vue.prototype.$exit = () => exit()
  }
}
