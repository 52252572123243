<template>
  <v-toolbar
    class="c-toolbar"
    color="primary"
    dark
    :dense="dense"
    flat
    fullscreen
    hide-overlay
    tile
    width="100%"
  >
    <v-spacer />

    <v-toolbar-title class="c-title text-center text-h4">
      {{ title }}
    </v-toolbar-title>

    <v-spacer />

    <v-btn fab icon @click="$emit('toggle:favourite')">
      <v-icon red--text large>
        {{ favourite ? 'mdi-heart' : 'mdi-heart-outline' }}
      </v-icon>
    </v-btn>
    <v-btn class="ml-2" fab icon @click="$emit('share')">
      <v-icon large>
        mdi-share-variant
      </v-icon>
    </v-btn>
    <v-btn class="ml-2" fab icon @click.stop="$emit('close')">
      <v-icon large>
        mdi-close
      </v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
export default {
  name: 'PostHeader',

  components: {},

  props: {
    title: {
      type: String,
      required: true
    },

    favourite: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    dense() {
      return this.$vuetify.breakpoint.mobile
    }
  }
}
</script>

<style lang="css">
.c-toolbar .v-toolbar__content {
  justify-content: space-between;
}
</style>

<style lang="css" scoped>
.c-toolbar {
  position: fixed;
  top: 0;
  z-index: 1;
}
.c-title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.c-actions {
  position: absolute;
  right: 0;
}
</style>
