<template>
  <v-navigation-drawer
    app
    class="c-drawer"
    disable-resize-watcher
    disable-route-watcher
    fixed
    hide-overlay
    left
    :value="open"
    width="calc(max(256px, 20%))"
  >
    <div class="c-container pa-4">
      <h2 class="text-heading-2 text-left mb-4">
        {{ heading }}
      </h2>

      <AdBlock
        v-model="showAds"
        class="c-ads mb-2"
        :ads="drawerAds"
        stack
      />

      <PortableText :blocks="description" />

      <!-- assistance -->
      <v-card v-if="hasGuidance || hasCorpus" class="mx-auto mt-4" tile>
        <v-list rounded>
          <v-subheader>
            {{ $t('content.assistance.title').toUpperCase() }}
          </v-subheader>

          <!-- chatbot -->
          <v-list-item v-if="hasCorpus" class="my-0">
            <v-list-item-avatar>
              <v-img class="c-avatar" :src="avatarImage" contain />
            </v-list-item-avatar>
            <v-list-item-content class="py-0">
              <v-switch
                v-model="showChatbot"
                class="c-switch"
                color="primary"
                dense
                :label="$t('content.assistance.askMelissa')"
                :ripple="false"
              />
            </v-list-item-content>
          </v-list-item>

          <!-- guidance -->
          <v-list-item v-if="hasGuidance" class="my-0">
            <v-list-item-icon class="mr-4">
              <v-icon size="40" large>
                mdi-compass
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content class="py-0">
              <v-switch
                v-model="showGuidance"
                class="c-switch"
                color="primary"
                dense
                :label="$t('content.assistance.guideMe')"
                :ripple="false"
                @change="$bus.$emit('select:guidance', showGuidance)"
              />
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>

      <!-- scenes and animations -->
      <v-card v-if="hasScenes" class="mx-auto mt-4" tile>
        <v-list v-if="hasMoreThanOneScene" rounded>
          <!-- scenes -->
          <v-subheader>
            {{ $t('model.scenes').toUpperCase() }}
          </v-subheader>
          <v-list-item-group v-model="selectedSceneId" active-class="c-list-item--active" mandatory>
            <v-list-item
              v-for="scene in scenes"
              :key="scene.id"
              :value="scene.id"
              @click.stop="selectScene(scene.id)"
            >
              <v-list-item-icon>
                <v-icon>{{ scene.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ scene[locale].name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>

        <!-- animations -->
        <v-list v-if="hasAnimations" rounded>
          <v-subheader>
            {{ $t('model.animations').toUpperCase() }}
          </v-subheader>
          <v-list-item-group
            v-model="selectedAnimationIds"
            active-class="c-list-item--active"
            multiple
            @change="syncAnimations"
          >
            <v-list-item
              v-for="animation in scenes[selectedSceneId][locale].animations"
              :key="animation.id"
              :value="animation.id"
              @click.stop="selectAnimation(animation.id)"
            >
              <v-list-item-content>
                <v-list-item-title>{{ animation.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>

      <!-- chatbot -->
      <div v-if="hasCorpus" class="c-chatbot flex-column align-start justify-start mt-4">
        <ChatbotBase v-show="showChatbot" ref="chatbot" :corpus="corpus" />
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import AdBlock from '@/components/ad/AdBlock'
import ChatbotBase from '@/players/ChatbotPlayer/ChatbotBase'
import PortableText from 'sanity-blocks-vue-component'

export default {
  name: 'PlayerDrawer',

  components: {
    AdBlock,
    ChatbotBase,
    PortableText
  },

  model: {
    prop: 'open',
    event: 'toggled'
  },

  props: {
    item: {
      type: Object,
      required: false,
      default: () => null
    },

    context: {
      type: Object,
      required: false,
      default: () => {}
    },

    open: {
      type: Boolean,
      required: true
    }
  },

  data: function () {
    return {
      avatarImage: require('@/assets/images/avatars/melissa-avatar-with-name.png'),
      showChatbot: true,
      showGuidance: false,
      selectedSceneId: 0,
      selectedAnimationIds: [],
      showAds: true
    }
  },

  computed: {
    ...mapGetters('adStore', ['getAds']),

    params() {
      return this.item?.params || []
    },

    /* visuals */

    isDark() {
      return this.$store.state.themeStore.isDark
    },

    locale() {
      return this.$store.state.i18nStore.locale
    },

    /* ads */

    drawerAds() {
      // note: accesses adStore.ads to retain reactivity
      const ads = this.$store.state.adStore.ads
      return ads.length > 0 ? this.getAds('drawer') : []
    },

    /* corpus */

    corpus() {
      return this.params?.find((param) => param.name === 'corpus')?.value || ''
    },

    hasCorpus() {
      return !!this.corpus
    },

    /* guidance */

    guidance() {
      return (
        this.params
          ?.find((param) => param.name === 'guidance')
          ?.value?.toLowerCase()
          ?.trim() === 'true'
      )
    },

    hasGuidance() {
      return this.guidance
    },

    /* animations & scenes */

    animations() {
      return this.scenes[this.selectedSceneId][this.locale].animations || []
    },

    hasAnimations() {
      return this.hasScenes
        ? !!this.scenes[this.selectedSceneId]?.[this.locale].animations?.length
        : false
    },

    scenes() {
      return this.context?.scenes || []
    },

    hasScenes() {
      return this.scenes.length > 0
    },

    hasMoreThanOneScene() {
      return this.scenes.length > 1
    },

    /* details */

    heading() {
      return this.item?.details?.heading || this.item?.title
    },

    description() {
      return this.item?.details?.description || []
    }
  },

  mounted: function () {
    // listen to the AnimationSelector
    this.$bus.$on('sync:selector', ({ selectedScene, selectedAnimations }) => {
      if (selectedScene) {
        this.selectedSceneId = selectedScene
      }
      if (selectedAnimations) {
        this.selectedAnimationIds = selectedAnimations
      }
    })
  },

  beforeDestroy: function () {
    this.$bus.$off('sync:selector')
  },

  methods: {
    selectScene(sceneId) {
      // clear all aninmations
      this.selectedAnimationIds = []

      // notify other components (i.e. UnityPlayer)
      this.$bus.$emit('select:scene', sceneId)

      // notify other components (i.e. AnimationSelector)
      this.$bus.$emit('sync:details', {
        selectedScene: sceneId,
        selectedAnimations: []
      })
    },

    selectAnimation(animationId) {
      // notify the UnityPlayer)
      this.$bus.$emit('select:animation', animationId)
    },

    syncAnimations(selectedAnimationIds) {
      // notify the AnimationSelector menu
      this.$bus.$emit('sync:details', {
        selectedAnimations: selectedAnimationIds
      })
    },

    clearChatHistory() {
      this.$refs.chatbot.clearChatHistory()
    }
  }
}
</script>

<style lang="css" scoped>
.c-drawer {
  background-color: var(--v-background-base);
  border-right: solid 1px;
  top: var(--c-player-bar-height, 56px) !important;
  padding-bottom: var(--c-player-bar-height, 56px);
  z-index: 2;
}

.c-container p {
  font-size: 1.25rem;
}

.c-ads {
  border: 1px solid;
}

.c-chatbot {
  width: 100%;
  height: 100%;
}
.c-chatbot .c-avatar {
  width: 128px;
  height: 128px;
  margin: auto;
}

.v-list-item {
  margin-bottom: 16px;
}
.c-list-item--active {
  background-color: var(--v-accent-base);
}
</style>
