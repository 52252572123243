// scrollBehavior:
// - only available in html5 history mode
// - defaults to no scroll behaviour
// - return false to prevent scroll

// a place to save the scroll position of special routes
const originPosition = { x: 0, y: 0 }
const scrollPosition = {}

export const scrollBehaviour = function (to, from, savedPosition) {
  /*
   * If the resolved position is falsy or an empty object,
   * the route will retain its current scroll position
   *
   * Note: savedPosition is only available for popstate navigations
   * (i.e. browser forward/back buttons)
   *
   */
  const position = {
    ...originPosition,
    ...savedPosition
  }

  // const element = document.getElementById(scrollableElementId)

  if (savedPosition) {
    console.debug('[scrollBehaviour]: savedPosition=', savedPosition)
  }

  // scroll to anchor by returning the selector
  if (to.hash) {
    // skip login redirect scenario
    if (to.hash.startsWith('#state')) {
      return false
    }

    console.debug('[scrollBehaviour]: to.hash=', to.hash)

    // verify the hash
    if (document.querySelector(to.hash)) {
      position.selector = to.hash
      position.behavior = 'smooth'
    }
  }

  // scroll to top if the route metadata (including child routes) says so
  if (to.matched.some((route) => route.meta?.scrollToTop)) {
    position.x = 0
    position.y = 0
  }

  // scroll to top if on same route
  if (to.name === from.name) {
    position.x = 0
    position.y = 0
  }

  if (to.name === 'iplayer') {
    console.warn(`[scrollBehaviour]: Routing from "${from.name}" to "${to.name}".`)

    // save position in global variable
    const name = from.name
    scrollPosition[name] = {}
    scrollPosition[name].x = from.meta.scrollPosition.x
    scrollPosition[name].y = from.meta.scrollPosition.y

    console.log(
      `[scrollBehaviour]: Saving scrollPosition[${name}]=(${scrollPosition[name].x}, ${scrollPosition[name].y})`
    )
  }

  if (from.name === 'iplayer') {
    console.warn(`[scrollBehaviour]: Routing from "${from.name}" to "${to.name}".`)

    // restore position from global variable (see above)
    // position.x = scrollPosition[to.name].x
    // position.y = scrollPosition[to.name].y
    // restore position from route meta data (see router.afterEach)
    position.x = to.meta?.scrollPosition?.x || 0
    position.y = to.meta?.scrollPosition?.y || 0

    console.log(
      `[scrollBehaviour]: Restoring scrollPosition[${to.name}]=(${position.x}, ${position.y})`
    )
  }

  return new Promise((resolve) => {
    // wait for the out transition to complete (if necessary)
    // (triggerScroll is emitted by AppMain component)
    // (this = $router; this.app.$root = App.vue)
    this.app.$nextTick(() => resolve(position))
    this.app.$root.$once('triggerScroll', () => {
      // this.app.$nextTick(() => resolve(position))
      // resolve(document.getElementById('app').scrollIntoView({ behavior: 'smooth' }))
    })
  })
}
