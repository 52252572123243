/*
import {
  compressToEncodedURIComponent as lzStringCompress,
  decompressFromEncodedURIComponent as lzStringDecompress
} from 'lz-string'
*/

const crypto = require('crypto')

export default class CryptoService {
  constructor() {
    this.algorithm = 'aes-256-ctr'
    this.secretKey = process.env.VUE_APP_SECRET_KEY || 'vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3'
    this.iv = crypto.randomBytes(16)
  }

  encrypt(text) {
    const cipher = crypto.createCipheriv(this.algorithm, this.secretKey, this.iv)
    const encrypted = Buffer.concat([cipher.update(text), cipher.final()])
    const hash = this.iv.toString('hex') + '.' + encrypted.toString('hex')
    return hash
  }

  decrypt(hash) {
    const splitHash = hash.split('.')
    const iv = splitHash[0]
    const content = splitHash[1]
    const decipher = crypto.createDecipheriv(this.algorithm, this.secretKey, Buffer.from(iv, 'hex'))
    const decrpyted = Buffer.concat([
      decipher.update(Buffer.from(content, 'hex')),
      decipher.final()
    ])
    return decrpyted.toString()
  }

  compress(str) {
    // return lzStringCompress(str)
    return str
  }

  decompress(str) {
    // return lzStringDecompress(str)
    return str
  }
}
