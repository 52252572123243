import client from '@/services/real'
export default class UserService {
  constructor() {
    this.service = 'userService'
  }

  fetchUserTenants = async (userId) => {
    const tenants = await client.fetchUserTenants(userId)
    return tenants
  }

  fetchUser = async (userId) => {
    const user = await client.fetchUser(userId)
    return user.userId !== undefined ? user : null
  }

  updateUser = async (user) => {
    const response = await client.updateUser(user)
    return response
  }

  addUser = async (user) => {
    const response = await client.addUser(user)
    return response
  }
}
