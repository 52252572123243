import Vue from 'vue'
import VueRouter from 'vue-router'

import { appRoutes, menuRoutes, errorRoutes } from '@/router/routes'
import { scrollBehaviour } from './scrollBehaviour.js'

Vue.use(VueRouter)

if ('scrollRestoration' in history) {
  history.scrollRestoration = 'manual'
}

const routes = [...appRoutes, ...menuRoutes, ...errorRoutes]
// const isActive = router.app.$store.state.menuStore.menuRoutes.includes(to.name)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: scrollBehaviour,
  routes: routes
})

const routeToLogin = ({ isAuthenticated, isAuthorized, to, next }) => {
  isAuthenticated && isAuthorized
    ? next()
    : next({
        name: 'login',
        query: {
          redirect: to.fullPath
        }
      })
}

const routeToErrorPage = (error) => {
  router.push({
    name: 'error',
    query: {
      code: 'SystemError',
      message: error.message
    }
  })
}

router.onError((error) => {
  console.error('[router.onError]:', error)
})

router.beforeResolve((to, from, next) => {
  // TODO: restrict routes configured for this tenant
  // const isActive = router.app.$store.state.menuStore.menuRoutes.includes(to.name)
  const isActive = true
  isActive ? next() : next({ name: 'error404' })
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((route) => route.meta.requiresAuthentication)

  if (requiresAuth) {
    const authService = router.app.$auth
    if (authService.isAuthenticated() && authService.isAuthorized()) {
      // already authenticated, so proceed
      next()
    } else {
      // in case of a redirect configuration and the inital app load,
      // wait for auth service to be ready
      if (authService.isRedirectLogin() && from === VueRouter.START_LOCATION) {
        authService
          .waitUntilReady()
          .then(
            routeToLogin({
              isAuthenticated: authService.isAuthenticated(),
              isAuthorized: authService.isAuthorized(),
              to,
              from,
              next
            })
          )
          .catch((error) => {
            console.error('[router:beforeEach]: System error.', error)
            routeToErrorPage(error)
            next()
          })
      } else {
        routeToLogin({
          isAuthenticated: authService.isAuthenticated(),
          isAuthorized: authService.isAuthorized(),
          to,
          from,
          next
        })
      }
    }
  } else {
    // no authentication required, so proceed
    next()
  }
})

// called just before the "to" DOM renders
router.afterEach((to, from) => {
  if (to.name === 'iplayer') {
    const position = {
      x: window.pageXOffset,
      y: window.pageYOffset
    }
    console.log(`[router:afterEach]: scroll position=${position.x}, ${position.y})`)
    from.meta.scrollPosition = position
  }
})

export default router
