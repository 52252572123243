import { render, staticRenderFns } from "./PosterViewer.vue?vue&type=template&id=24581fd9&scoped=true&"
import script from "./PosterViewer.vue?vue&type=script&lang=js&"
export * from "./PosterViewer.vue?vue&type=script&lang=js&"
import style0 from "./PosterViewer.vue?vue&type=style&index=0&id=24581fd9&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24581fd9",
  null
  
)

export default component.exports