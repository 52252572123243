<template>
  <v-toolbar
    dense
    floating
    rounded="pill"
  >
    <!-- zoom controls -->
    <v-btn-toggle color="primary" rounded>
      <v-btn
        v-ripple="{ class: 'primary--small text' }"
        class="c-btn c-btn--stateless"
        fab
        small
        text
        @click.stop="increaseZoom"
      >
        <v-icon large>
          mdi-plus
        </v-icon>
      </v-btn>
      <v-btn
        v-ripple="{ class: 'primary--small text' }"
        class="c-btn c-btn--stateless"
        fab
        small
        text
        @click.stop="decreaseZoom"
      >
        <v-icon large>
          mdi-minus
        </v-icon>
      </v-btn>
    </v-btn-toggle>

    <!-- interactive modes -->
    <v-btn-toggle v-model="modeIndex" class="mx-2" color="primary" mandatory rounded>
      <v-btn
        v-ripple="{ class: 'primary--small text' }"
        class="c-btn"
        active-class="c-btn--active"
        fab
        :input-value="isMovable"
        small
        text
        @click.stop="toggleMoveMode"
      >
        <v-icon large>
          mdi-cursor-move
        </v-icon>
      </v-btn>
      <v-btn
        v-ripple="{ class: 'primary--small text' }"
        class="c-btn"
        active-class="c-btn--active"
        fab
        :input-value="isRotatable"
        small
        text
        @click.stop="toggleRotateMode"
      >
        <v-icon large>
          mdi-sync
        </v-icon>
      </v-btn>
      <v-btn
        v-ripple="{ class: 'primary--small text' }"
        class="c-btn"
        active-class="c-btn--active"
        fab
        :input-value="isScalable"
        small
        text
        @click.stop="toggleScaleMode"
      >
        <v-icon large>
          mdi-arrow-expand
        </v-icon>
      </v-btn>
    </v-btn-toggle>

    <!-- reset button -->
    <v-btn
      v-ripple="{ class: 'primary--small text' }"
      class="c-btn c-btn--stateless"
      fab
      small
      text
      @click.stop="reset"
    >
      <v-icon large>
        mdi-crosshairs-gps
      </v-icon>
    </v-btn>

    <!-- model augmentations -->
    <v-btn
      v-ripple="{ class: 'primary--small text' }"
      class="c-btn"
      active-class="c-btn--active"
      fab
      :input-value="isRotating"
      small
      text
      @click.stop="toggleRotation"
    >
      <v-icon large>
        mdi-fan
      </v-icon>
    </v-btn>
    <v-btn
      v-if="hasAnnotations"
      v-ripple="{ class: 'primary--small text' }"
      class="c-btn c-btn--stateful"
      active-class="c-btn--active"
      depressed
      fab
      :input-value="showAnnotations"
      small
      text
      @click.stop="toggleAnnotations"
    >
      <v-icon large>
        mdi-tag
      </v-icon>
    </v-btn>
    <AnimationSelector
      v-if="hasAnimations"
      :animations="animations"
      @select:animation="selectAnimation"
    />
    <SceneSelector
      v-if="hasMoreThanOneScene"
      :selected="scene"
      :scenes="scenes"
      @select:scene="selectScene"
    />
  </v-toolbar>
</template>

<script>
import { EventNames } from '../constants/ControlConstants'
import AnimationSelector from './AnimationSelector'
import SceneSelector from './SceneSelector'

const MIN_ZOOM = -5
const MAX_ZOOM = +5

const MODE_MOVE = 'move'
const MODE_ROTATE = 'rotate'
const MODE_SCALE = 'scale'

export default {
  name: 'ModelControls',

  components: {
    AnimationSelector,
    SceneSelector
  },

  props: {
    rerender: {
      type: Number,
      required: false,
      default: 0
    },

    scenes: {
      type: Array,
      required: false,
      default: () => []
    },

    scene: {
      type: Object,
      required: false,
      default: () => {}
    }
  },

  data: function () {
    return {
      // zoom settings
      size: 0, // max +/- 5 in increments of 5
      zoomInActive: true,
      zoomOutActive: true,
      // mode settings
      mode: MODE_ROTATE, // "move", "rotate" or "scale"
      modeIndex: 1,
      isMovable: false,
      isRotatable: true,
      isScalable: false,
      // augmentation settings
      isRotating: false,
      showAnnotations: true
    }
  },

  computed: {
    animations() {
      return this.scene.id ? this.scene[this.currentLocale]?.animations || [] : []
    },

    hasAnimations() {
      return this.animations.length > 0
    },

    hasAnnotations() {
      return this.scene.id ? this.scene[this.currentLocale]?.annotations || false : false
    },

    hasMoreThanOneScene() {
      return this.scenes.length > 1
    },

    currentLocale() {
      return this.$store.state.i18nStore.locale
    }
  },

  watch: {
    rerender: {
      handler(newValue, _oldValue) {
        if (newValue) {
          this.resetControls()
        }
      }
    }
  },

  created: function () {},

  methods: {
    // zoom controls
    increaseZoom() {
      this.size = this.size < MAX_ZOOM ? this.size++ : this.size
      this.zoomInActive = this.size < MAX_ZOOM
      this.zoomOutActive = true

      if (this.size <= MAX_ZOOM) this.raiseEvent(EventNames.ZOOM_IN, this.size)
    },

    decreaseZoom() {
      this.size = this.size > MIN_ZOOM ? this.size-- : this.size
      this.zoomOutActive = this.size > MIN_ZOOM
      this.zoomInActive = true

      if (this.size >= MIN_ZOOM) this.raiseEvent(EventNames.ZOOM_OUT, this.size)
    },

    // interactive modes
    toggleMoveMode() {
      if (this.mode !== MODE_MOVE) {
        this.toggleModeButtons(MODE_MOVE)
        this.mode = MODE_MOVE
        this.raiseEvent(EventNames.MODE_MOVE, this.isMovable ? 1 : 0)
      }
    },

    toggleRotateMode() {
      if (this.mode !== MODE_ROTATE) {
        this.toggleModeButtons(MODE_ROTATE)
        this.mode = MODE_ROTATE
        this.raiseEvent(EventNames.MODE_ROTATE, this.isRotatable ? 1 : 0)
      }
    },

    toggleScaleMode() {
      if (this.mode !== MODE_SCALE) {
        this.toggleModeButtons(MODE_SCALE)
        this.mode = MODE_SCALE
        this.raiseEvent(EventNames.MODE_SCALE, this.isScalable ? 1 : 0)
      }
    },

    toggleModeButtons(newMode) {
      newMode === MODE_MOVE
        ? (this.isMovable = true)
        : (this.isRotatable = false) && (this.isScalable = false)

      newMode === MODE_ROTATE
        ? (this.isRotatable = true)
        : (this.isMovable = false) && (this.isScalable = false)

      newMode === MODE_SCALE
        ? (this.isScalable = true)
        : (this.isMovable = false) && (this.isRotatable = false)
    },

    resetControls() {
      // reset zoom
      this.size = 0
      this.zoomInActive = true
      this.zoomOutActive = true
      // reset mode
      this.mode = MODE_ROTATE
      this.modeIndex = 1
      this.isMovable = false
      this.isRotatable = true
      this.isScalable = false
      // reset automations / augmentations
      this.isRotating = false
      this.showAnnotations = true
    },

    resetModel() {
      this.raiseEvent(EventNames.RESET, 0)
    },

    reset() {
      this.resetModel()
      this.resetControls()
    },

    // automations
    toggleRotation() {
      this.isRotating = !this.isRotating
      this.raiseEvent(EventNames.ROTATE, this.isRotating ? 1 : 0)
    },

    // augmentations: annotations and animations
    toggleAnnotations() {
      this.showAnnotations = !this.showAnnotations
      this.raiseEvent(EventNames.SHOW_ANNOTATIONS, '*')
    },

    selectAnimation(animationId) {
      this.raiseEvent(EventNames.TOGGLE_ANIMATION, animationId)
    },

    selectScene(sceneId) {
      this.raiseEvent(EventNames.LOAD_SCENE, sceneId)
    },

    raiseEvent(eventName, param) {
      const message = { eventName, param }
      this.$emit('message', message)
    }
  }
}
</script>

<style lang="css" scoped>
.c-selected-item {
  background-color: var(--v-accent-base);
}
</style>
