<template>
  <ImageViewer class="c-poster-viewer" :content="content">
    <v-img
      class="c-img--scrollable"
      :src="src"
      contain
    />
  </ImageViewer>
</template>

<script>
import ImageViewer from '@/players/ImageViewer'

export default {
  name: 'PosterViewer',

  components: {
    ImageViewer
  },

  props: {
    content: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {
      src: this.content.contentURL
    }
  }
}
</script>

<style lang="css" scoped>
.c-img--scrollable {
  min-height: calc(100vh - var(--c-player-bar-height, 56px));
}
</style>
