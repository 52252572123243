import Vue from 'vue'
import Vuex from 'vuex'

import appConfig from '@/config/appConfig.js'
import locales from '@/locales'
import { loadMessages, extractLanguage } from '@/locales/utils.js'

Vue.use(Vuex)

const initialState = {
  defaultLocale: appConfig.defaultLocale,
  locale: appConfig.defaultLocale
}

export default {
  namespaced: true,

  state: () => initialState,

  getters: {
    getLocaleName() {
      return (locale) => locales[locale].name
    },

    $t() {
      // $t() was added to Vuex prototype via localePlugin
      return (keyword) => Vuex.Store.prototype.$t(keyword)
    }
  },

  mutations: {
    /* common mutations */

    syncState(state, newState) {
      // spread operator breaks reactivity: state = {...state, ...newState}
      Object.keys(newState).forEach((key) => {
        // ignore any deprecated keys
        if (key in state) state[key] = newState[key]
      })
    },

    /* specific mutations */

    setLocale(state, locale) {
      state.locale = locale
    }
  },

  actions: {
    /* common actions */

    initStore({ state, commit }, { sessionState }) {
      commit('syncState', { ...state, ...sessionState })
    },

    migrateStore({ state, commit }, { sessionState }) {
      commit('syncState', { ...state, ...sessionState })
    },

    /* specific actions */

    // see https://kazupon.github.io/vue-i18n/guide/lazy-loading.html
    async updateLocale({ commit }, { locale, vm }) {
      try {
        const messages = await loadMessages(locale)
        vm.$i18n.locale = locale
        vm.$i18n.setLocaleMessage(locale, messages)
        vm.$root.$i18n.locale = locale
        vm.$root.$vuetify.lang.current = extractLanguage(locale)
        // TODO: allow localized paths, apis and html
        // vm.$router.push(this.switchLocalePath(locale))
        // vm.$axios.defaults.headers.common['Accept-Language'] = locale
        // window.document.querySelector('html').setAttribute('lang', locale)
        commit('setLocale', locale)
      } catch (err) {
        vm.$logger.logError(err)
      }
    }
  }
}
