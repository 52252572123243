<template>
  <div>
    <!-- control panel -->
    <v-card
      v-if="controls"
      class="pa-0"
      color="primary"
    >
      <v-card-actions class="py-2">
        <v-btn fab @click="expand ? collapseAll : expandAll">
          <v-icon large>
            {{ expand ? 'mdi-arrow-collapse-vertical' : 'mdi-arrow-expand-vertical' }}
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- content panels -->
    <v-expansion-panels
      v-model="panels"
      accordian
      flat
      hover
      multiple
      tile
    >
      <slot />
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  name: 'SectionPanels',

  components: {},

  props: {
    controls: {
      type: Boolean,
      required: false,
      default: false
    },

    depth: {
      type: Number,
      required: true
    },

    expand: {
      type: Boolean,
      required: false,
      default: false
    },

    initial: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  data: function () {
    return {
      panels: [...this.initial]
    }
  },

  watch: {
    depth: {
      immediate: false,
      handler: function (_newVal, _oldVal) {
        this.panels = this.expand ? this.expandAll() : [...this.initial]
      }
    },

    expand: {
      immediate: false,
      handler: function (_newVal, _oldVal) {
        this.panels = this.expand ? this.expandAll() : this.collapseAll()
      }
    }
  },

  created: function () {
    this.panels = this.expand
      ? this.expandAll()
      : this.initial.length > 0
      ? [...this.initial]
      : this.collapseAll()
  },

  methods: {
    expandAll() {
      return [...Array(this.depth).keys()].map((k, i) => i)
    },

    collapseAll() {
      return []
    }
  }
}
</script>

<style lang="css" scoped></style>
