<template>
  <div class="c-body">
    <v-app id="app" class="c-fullscreen">
      <CircularProgress
        v-if="loading"
        :logo="logo"
        :message="$t('message.initializing')"
      />
      <component :is="layout" v-else />
    </v-app>
    <div v-if="portlet" class="c-popup c-fullheight">
      <MeetingWindow
        ref="meetingWindow"
        v-model="portlet"
        :jwt="jwt"
        :room="room"
        :subject="subject"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import CircularProgress from '@/components/base/CircularProgress'
import MeetingWindow from '@/components/meetings/MeetingWindow'

import OrgService from '@/services/orgService'
import localhostConfig from '@/config/localhostConfig.json'

const defaultLayout = 'PageLayout' // avoids premature rendering of app layout elements

export default {
  name: 'App',

  components: {
    CircularProgress,
    MeetingWindow
  },

  data: function () {
    return {
      // app data
      loading: true, // avoids a forced reflow
      tenant: null,
      orgConfig: null,
      useLocal: false,

      // meeting data
      portlet: false,
      jwt: null,
      room: '',
      subject: ''
    }
  },

  computed: {
    /* app settings */

    isDark() {
      return this.$store.state.themeStore.isDark
    },

    layout() {
      return this.$route.meta.layout || defaultLayout
    },

    online() {
      return this.$store.state.online
    },

    /* tenant/org settings */

    tenantKey() {
      return this.$store.state.tenantStore.tenantKey
    },

    portalKey() {
      return this.$store.state.tenantStore.portalKey
    },

    orgKey() {
      return this.$store.state.tenantStore.orgKey
    },

    logo() {
      // since org is being loaded, assume a naming convention
      return `/img/logos/${this.tenantKey}-logo.png`
    },

    /* content settings */
    collections() {
      return this.$store.state.contentStore.collections
    }
  },

  beforeCreate: async function () {
    // setup all the store modules from local storage
    // (use dispatch since methods are not initialized yet)
    this.$store.dispatch('initStores', { vm: this }, { root: true })
  },

  created: async function () {
    // add event bus listeners
    this.addBusListeners()

    if (this.online) {
      // fetch and configure the current org (for this tenant)
      await this.setupOrg(this.orgKey)

      // prime the content/post/channel caches
      this.primeCaches()

      // note: user is setup after authentication (see Login.vue)
    }
  },

  mounted: function () {
    this.$appConfig.historyStart = window.history.length
    this.addEventListeners()
    this.$nextTick(this.setDimensions)

    console.debug('[App]: Device breakpoints=', this.$vuetify.breakpoint)
  },

  beforeDestroy: function () {
    this.removeEventListeners()
  },

  methods: {
    ...mapActions(['initStore', 'reflectOrg', 'reflectUser']),
    ...mapActions('postStore', ['fetchPosts']),
    ...mapActions('channelStore', ['fetchChannels']),
    ...mapActions('contentStore', ['fetchLibrary', 'fetchCollectionItems']),

    addBusListeners() {
      // exit the app
      this.$bus.$on('exit', () => {
        this.$exit()
      })

      // show the portal window
      this.$bus.$on('meeting:show', (meeting) => {
        this.jwt = meeting.jwt
        this.room = meeting.room
        this.subject = meeting.subject
        this.portlet = true
        console.debug('[App]: meeting:show event received. Meeting=', meeting)
      })

      // reset when the portal window is blocked
      this.$bus.$on('meeting:reset', () => {
        this.jwt = null
        this.room = null
        this.subject = null
        this.portlet = false
      })

      // this event occurs from the Vue instance within the portal window
      this.$bus.$on('meeting:hide', () => {
        self.close()
      })
    },

    addEventListeners() {
      window.addEventListener('popstate', (s) => console.log('[App]: popstate=', s))
      window.addEventListener('resize', this.setDimensions)
      window.addEventListener('orientationchange', this.setDimensions)
      window.addEventListener('online', this.setOnlineStatus)
      window.addEventListener('offline', this.setOnlineStatus)
    },

    removeEventListeners() {
      window.removeEventListener('resize', this.setDimensions)
      window.removeEventListener('orientationchange', this.setDimensions)
      window.removeEventListener('online', this.setOnlineStatus)
      window.removeEventListener('offline', this.setOnlineStatus)
    },

    async primeCaches() {
      const resync = this.online

      this.fetchLibrary({ resync })
      this.collections.forEach((collection) =>
        this.fetchCollectionItems({ key: collection.key, resync })
      )

      this.fetchChannels({ resync })
      this.fetchPosts({ resync })
    },

    async setupOrg(orgKey) {
      this.loading = true

      const orgService = new OrgService()

      try {
        const orgConfig = this.useLocal ? localhostConfig : await orgService.fetchOrgConfig(orgKey)
        this.reflectOrg({ orgConfig, vm: this.$root })
      } catch (error) {
        // rely on localStorage values (as loaded in beforeCreate())
        console.error(`Error: ${error}.`)
      } finally {
        this.loading = false
      }
    },

    documentHeight() {
      return Math.max(
        document.documentElement.clientHeight,
        document.body.scrollHeight,
        document.documentElement.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.offsetHeight
      )
    },

    setDimensions() {
      const root = document.documentElement

      /* retrieve viewport dimensions */
      const viewportWidth = root.clientWidth
      const viewportHeight = root.clientHeight

      /* set viewport dimensions */
      root.style.setProperty('--c-viewport-width', `${viewportWidth}px`)
      root.style.setProperty('--c-viewport-height', `${viewportHeight}px`)
      root.style.setProperty('--vh', `${window.innerHeight / 100}px`)

      /* set scrollbar dimensions */
      const scrollbarSize = this.$_layoutMixin_calculateScrollbarSize(root)
      root.style.setProperty('--c-scrollbar-height', `${scrollbarSize.height}px`)
      root.style.setProperty('--c-scrollbar-width', `${scrollbarSize.width}px`)
    }
  },

  setOnlineStatus() {
    this.$store.commit('setOnlineStatus', window.navigator.onLine)
  }
}
</script>

<style lang="css" scoped>
.c-fullscreen {
  min-height: 100vh;
}

.c-fullheight {
  height: 100%;
}

.c-body {
  position: relative;
}

.c-popup {
  z-index: 1000;
}
</style>

<!-- Global Styles -->
<style lang="css">
:root {
  --g-fullheight: 100%;
}

body,
html {
  padding: 0;
  margin: 0;
  height: 100vh; /* fallback for js load */
  height: var(--g-fullheight);
  width: 100%;
}

body {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  overflow-y: auto !important;
}
</style>

<!-- Overrides -->
<style lang="css">
@import './assets/styles/alerts.css';
@import './assets/styles/buttons.css';
@import './assets/styles/globals.css';
@import './assets/styles/vuetify.css';
</style>
