<template>
  <div class="d-flex align-stretch justify-space-between">
    <div class="pl-3 pr-4 ml-2" />
    <div class="my-auto text-subtitle-2 text-uppercase">
      {{ $t('ui.advertisements') }}
    </div>
    <div>
      <v-btn
        class="mr-2"
        icon
        small
        @click="$emit('close')"
      >
        <v-icon x-small>
          mdi-close
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdHeader',

  components: {},

  data: function () {
    return {}
  }
}
</script>

<style lang="css" scoped></style>
