import ChannelService from '@/services/channelService'

const channelService = new ChannelService()

export default {
  /* common actions */

  initStore({ state, commit }, { sessionState }) {
    commit('syncState', { ...state, ...sessionState })
  },

  migrateStore({ state, commit }, { sessionState }) {
    commit('syncState', { ...state, ...sessionState })
  },

  /* specific actions */
  updateActiveChannel({ commit }, { channel }) {
    commit('setActiveChannel', channel)
  },

  async fetchChannels({ state, commit, getters }, { resync = false }) {
    if (resync || state.channels.length < 1) {
      console.debug(`[channelStore]: Fetching channels from CMS.`)
      commit('setLoading', true)
      const filter = getters.activePortal?.channelFilter
      const channels = await channelService.fetchChannels({ filter })
      commit('setChannels', channels)
      commit('setLoading', false)
    } else {
      console.debug(`[channelStore]: Fetching channels from cache.`)
    }
    return state.channels
  }
}
