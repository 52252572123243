import Vue from 'vue'
import Vuex from 'vuex'

// import client from '@client'
import client from '@/services/mock'

Vue.use(Vuex)

const initialState = {
  notifications: []
}

export default {
  namespaced: true,

  state: () => initialState,

  getters: {},

  mutations: {
    /* common mutations */

    syncState(state, newState) {
      // spread operator breaks reactivity: state = {...state, ...newState}
      Object.keys(newState).forEach((key) => {
        // ignore any deprecated keys
        if (key in state) state[key] = newState[key]
      })
    },

    /* specific mutations */

    setNotifications(state, notifications) {
      state.notifications = notifications
    }
  },

  actions: {
    /* common actions */

    initStore({ state, commit }, { sessionState }) {
      commit('syncState', { ...state, ...sessionState })
    },

    migrateStore({ state, commit }, { sessionState }) {
      commit('syncState', { ...state, ...sessionState })
    },

    /* specific actions */

    fetchNotifications({ commit }) {
      return client.fetchNotifications().then((notifications) => {
        commit('setNotifications', notifications)
        return notifications
      })
    },

    updateNotifications({ commit }, { notifications }) {
      commit('setNotifications', notifications)
    }
  }
}
