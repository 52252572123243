<template>
  <div>
    <canvas
      ref="mainCanvas"
      class="c-canvas"
      :class="{ 'c-canvas--active': activated }"
      @mousedown="onMouseDown"
      @mouseup="onMouseUp"
      @mousemove="onMouseMove"
    />
    <canvas
      ref="tempCanvas"
      class="c-canvas"
      :class="{ 'c-canvas--active': activated }"
      @mousedown="onMouseDown"
      @mouseup="onMouseUp"
      @mousemove="onMouseMove"
    />
    <v-footer
      class="c-footer d-flex"
      app
      color="rgba(0,0,0,0)"
      fixed
      inset
      padless
      tile
    >
      <v-spacer />
      <div class="c-canvas__tools text-center mb-1">
        <DrawControls
          v-if="activated"
          v-model="controls"
          @clear="clear"
          @capture="$emit('capture')"
          @download="$emit('download')"
          @redo="redo"
          @undo="undo"
          @update:shape="setActiveShape($event)"
          @update:option="updateOption($event)"
          @erase="setEraseMode($event)"
          @text="setTextMode($event)"
        />
      </div>
      <v-spacer />
    </v-footer>
  </div>
</template>

<script>
import DrawService from '@/services/drawService'
import DrawControls from '@/components/draw/DrawControls'
import { defaultControls, defaultOptions, defaultTool } from '@/components/draw/defaultControls'

export default {
  name: 'DrawCanvas',

  components: {
    DrawControls
  },

  props: {
    height: {
      type: Number,
      required: false,
      default: 0
    },

    width: {
      type: Number,
      required: false,
      default: 0
    },

    left: {
      type: Number,
      required: false,
      default: 0
    },

    top: {
      type: Number,
      required: false,
      default: 0
    },

    activated: {
      type: Boolean,
      required: false,
      default: true
    },

    delay: {
      type: Number,
      required: false,
      default: 200
    }
  },

  data: function () {
    return {
      // canvas
      mainCanvas: null,
      tempCanvas: null,
      mainContext: null,
      tempContext: null,
      isCanvasReady: false,

      // controls
      activeShape: null,
      controls: defaultControls,
      options: {},

      // drawing
      resizeDelay: this.delay,
      resizeTimeout: null,
      drawService: null
    }
  },

  computed: {
    isDark() {
      return this.$store.state.themeStore.isDark
    },

    playerBarHeight() {
      return 56
    }
  },

  created: function () {
    this.isFavourite = this.favourite

    // set default drawing options
    this.activeShape = defaultTool
    this.options.colour = this.isDark ? defaultOptions.colour.dark : defaultOptions.colour.light
    this.options.font = defaultOptions.font
    this.options.shape = defaultOptions.shape
    this.options.width = defaultOptions.width
  },

  mounted: function () {
    this.mainCanvas = this.$refs.mainCanvas
    this.tempCanvas = this.$refs.tempCanvas
    this.mainContext = this.$refs.mainCanvas.getContext('2d')
    this.tempContext = this.$refs.tempCanvas.getContext('2d')

    this.$nextTick(() => {
      // setup canvas
      this.mainCanvas.height =
        this.height > 0 ? this.height : this.mainCanvas.clientHeight - this.playerBarHeight
      this.mainCanvas.width = this.width > 0 ? this.width : this.mainCanvas.clientWidth
      this.tempCanvas.height = this.mainCanvas.height
      this.tempCanvas.width = this.mainCanvas.width
      console.debug(`[Canvas]: size=${this.mainCanvas.width} x ${this.mainCanvas.height}`)
      console.debug(
        `[Canvas]: scroll=${this.mainCanvas.scrollWidth} x ${this.mainCanvas.scrollHeight}`
      )

      // initialize drawService with canvas
      this.drawService = new DrawService({
        mainContext: this.mainContext,
        tempContext: this.tempContext,
        activeShape: this.activeShape,
        offsetX: this.left,
        offsetY: this.top,
        options: this.options
      })

      // publish 'ready' event
      this.isCanvasReady = true
      this.$emit('ready')
    })

    // window.addEventListener('resize', this.onResizeDebounced)
  },

  beforeDestroy: function () {
    // window.removeEventListener('resize', this.onResizeDebounced)
  },

  methods: {
    // drawing events

    onMouseDown(e) {
      if (this.activated) {
        this.drawService.mousedown(e)
        console.warn(`(x,y)=(${this.x},${this.y})`)
      }
    },

    onMouseMove(e) {
      if (this.activated) {
        this.drawService.mousemove(e)
      }
    },

    onMouseUp(e) {
      if (this.activated) {
        this.drawService.mouseup(e)
      }
    },

    // canvas management
    onResizeDebounced() {
      // clear the timer
      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout)
      }

      if (this.activated) {
        this.resizeTimeout = setTimeout(this.resizeCanvas, this.resizeDelay)
      }
    },

    resizeCanvas() {
      // calculate the scaling factor
      console.debug(
        '[DrawCanvas]: resized canvas dim=',
        this.mainCanvas.width,
        this.mainCanvas.height
      )
      console.debug('[DrawCanvas]: resized window dim=', window.innerWidth, window.innerHeight)
      // const scaleX = window.innerWidth / this.mainCanvas.width
      // const scaleY = window.innerHeight / this.mainCanvas.height
      // console.debug('scale=', scaleX, scaleY)

      // resize both the main and temp canvas (which automatically clears them)
      // this.mainContext.canvas.width = window.innerWidth
      // this.mainContext.canvas.height = window.innerHeight
      // this.tempContext.canvas.width = window.innerWidth
      // this.tempContext.canvas.height = window.innerHeight
      // this.refreshContext()

      // scale the resized canvas
      // this.mainContext.scale(scaleX, scaleY)
      // this.tempContext.scale(scaleX, scaleY)

      const iw = window.innerWidth
      const ih = window.innerHeight
      const cw = this.mainContext.canvas.width
      const ch = this.mainContext.canvas.height
      const fx = Math.max(cw / iw, ch / ih)
      console.debug('[DrawCanvas]: resize ratio=', fx)

      this.mainContext.setTransform(
        /*     scale x */ fx,
        /*      skew x */ 0,
        /*      skew y */ 0,
        /*     scale y */ fx,
        /* translate x */ (cw - fx * iw) / 2,
        /* translate y */ (ch - fx * ih) / 2
      )
      this.tempContext.setTransform(
        /*     scale x */ fx,
        /*      skew x */ 0,
        /*      skew y */ 0,
        /*     scale y */ fx,
        /* translate x */ (cw - fx * iw) / 2,
        /* translate y */ (ch - fx * ih) / 2
      )

      // redraw the picture
      // this.drawService.setDimensions(this.mainContext.canvas.width, this.mainContext.canvas.height)
      this.drawService.redraw()

      // restore the current scale
      // this.mainContext.scale(1 / scaleX, 1 / scaleY)
      // this.tempContext.scale(1 / scaleX, 1 / scaleY)
    },

    showCoordinates(e) {
      this.x = e.offsetX
      this.y = e.offsetY
    },

    // context management

    setActiveShape(shape) {
      this.drawService.setActiveShape(shape)
    },

    setEraseMode(isEraseMode) {
      this.drawService.setEraseMode(isEraseMode)
    },

    setTextMode(isTextMode) {
      this.drawService.setTextMode(isTextMode, this.mainCanvas)
    },

    updateOption(option) {
      this.drawService.updateOptions(option)
    },

    refreshContext() {
      this.drawService.updateOptions()
    },

    // actions

    clear() {
      const isSilent = false
      this.drawService.clear(isSilent)
    },

    redo() {
      this.drawService.redo()
    },

    undo() {
      this.drawService.undo()
    }
  }
}
</script>

<style lang="css" scoped>
.c-canvas__container {
  width: 100%;
  height: 100%;
}

.c-canvas {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 6px;
  height: 100%;
  width: 100%;
  object-fit: contain;
  background-color: transparent;
  min-height: calc(100vh - var(--c-player-bar-height, 56px));
}

.c-canvas--active {
  z-index: 1;
  border: orange solid 6px;
}

.c-footer {
  z-index: 3;
}

.c-input {
  z-index: 3;
  font-weight: normal;
  border-radius: 3px;
  padding: 4px 12px;
  text-decoration: none;
  color: rgb(27, 26, 26);
  display: inline-block;
  box-shadow: rgb(255, 255, 255) 1px 1px 0px 0px inset;
  text-shadow: none;
  background: -webkit-gradient(
    linear,
    0% 0%,
    0% 100%,
    color-stop(0.05, rgb(241, 241, 241)),
    to(rgb(230, 230, 230))
  );
  font-size: 20px;
  border: 1px solid red;
  outline: none;
}
</style>
